import React, { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles'
import {
    AppBar,
    IconButton,
    Toolbar,
    InputBase,
    Box,
    Link,
    Typography,
    useMediaQuery,
    useTheme,
    Button,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { alpha } from '@mui/material/styles'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 10,
    paddingTop: 5,
    background: 'transparent',
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    width: `calc(100% - ${0}px)`,
    boxShadow: 'none',
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

const LoginButton = styled(Button)(({ theme }) => ({
    background: 'transparent',
    color: theme.palette.text.selected,
    borderRadius: 50,
    border: '1px solid ' + theme.palette.text.selected,
    padding: '5px 40px',
    height: 40,
    marginLeft: 5,
    marginRight: 5,
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: alpha(theme.palette.text.selected, 0.1),
    },
}))

const PrimaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    marginLeft: 5,
    marginRight: 5,
    fontSize: 16,
    fontWeight: 'bold',
}))

const TransparentButton = styled(Button)(({ theme }) => ({
    background: 'transparent',
    color: theme.palette.text.primary,
    borderRadius: 50,
    padding: '5px 20px',
    height: 40,
    marginLeft: 5,
    marginRight: 5,
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: theme.palette.background.secondary,
        color: theme.palette.text.selected,
    },
}))

const SignupButton = styled(Button)(({ theme }) => ({
    background: theme.palette.text.selected,
    color: theme.palette.text.primary,
    borderRadius: 50,
    padding: '5px 40px',
    height: 40,
    marginLeft: 5,
    marginRight: 5,
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: theme.palette.text.selected,
    },
}))

const LogoIconButton = styled((props: any) => <IconButton {...props} />)(
    ({ theme }) => ({
        '&:hover': {
            backgroundColor: 'transparent',
        },
    })
)

interface TopBarViewProps {
    showLogin: boolean
    setShowLogin: React.Dispatch<React.SetStateAction<boolean>>
    setShowRegister: React.Dispatch<React.SetStateAction<boolean>>
}

const TopBarView = ({
    showLogin,
    setShowLogin,
    setShowRegister,
}: TopBarViewProps) => {
    return (
        <TopBar>
            <Toolbar
                style={{ minHeight: 55, paddingLeft: 30, paddingRight: 30 }}
            >
                <LogoIconButton compoent={RouterLink} href="/" disableRipple>
                    <img
                        src={`${process.env.PUBLIC_URL}/logotest03long.png`}
                        height={30}
                    />
                </LogoIconButton>

                <Box sx={{ flexGrow: 1 }} />
                <TransparentButton>About</TransparentButton>
                <TransparentButton>Request Beta</TransparentButton>
                <TransparentButton>Discover Events</TransparentButton>
                <TransparentButton>Create Events</TransparentButton>
                <LoginButton onClick={() => setShowLogin(true)}>
                    Log In
                </LoginButton>
                <SignupButton onClick={() => setShowRegister(true)}>
                    Sign Up
                </SignupButton>
            </Toolbar>
        </TopBar>
    )
}

export default TopBarView
