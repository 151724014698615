import {
    AppBar,
    Box,
    IconButton,
    IconButtonProps,
    LinearProgress,
    styled,
    Toolbar,
    Typography,
} from '@mui/material'
import { useAppState } from '../../../AppStateProvider'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    borderBottom: '1px solid ' + theme.palette.background.primary,
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `calc(100%)`,
    backdropFilter: 'blur(10px)',
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    height: 45,
    // boxShadow: 'none',
    // marginRight: 6,
}))

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 5,
    color: theme.palette.text.selected,
    backgroundColor: 'transparent',
    height: 2,
    '& .MuiLinearProgress-bar': {
        color: theme.palette.text.selected,
        backgroundColor: theme.palette.text.selected,
    },
}))

const TicketsTopBarMobileView = () => {
    const { state, setState } = useAppState()
    return (
        <TopBar>
            {state.showprogress && (
                <StyledLinearProgress
                    variant="determinate"
                    value={state.progress}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transition: state.progresstransition
                                ? 'transform 0.2s linear'
                                : 'none',
                        },
                    }}
                />
            )}
            <Toolbar
                style={{ minHeight: 55, paddingLeft: 16, paddingRight: 16 }}
            >
                {/* <LogoIconButton
                    component={RouterLink}
                    href="/app/home/"
                    disableRipple
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/logotest03long.png`}
                        height={30}
                    />
                </LogoIconButton> */}
                <Typography
                    sx={{
                        fontSize: 26,
                        fontWeight: 700,
                        color: 'text.primary',
                    }}
                >
                    Tickets
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
            </Toolbar>
        </TopBar>
    )
}

export default TicketsTopBarMobileView
