import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppState } from '../../../AppStateProvider'
import { useEffect, useRef, useState } from 'react'
import { DataStatus } from '../../../models/Dataloading'
import { NavigateEventState } from '../../../models/NavigateState'
import { Event } from '../../../models/Event'
import { Box, Typography } from '@mui/material'
import { LoadingBox, PrimaryDivider } from '../../Components/StyledComponents'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import { styled } from '@mui/system'
import { hexToRGBA } from '../../../utils/haxToRGBA'
import {
    StyledTab,
    StyledTabs,
    TabBox,
} from '../../Components/StyledComponents/StyledTabView'
import EventDetailsView, {
    EventDetailsAboutView,
    EventDetailsDateView,
    EventDetailsStatisticsView,
} from '../EventDetailsView'
import EventHostDetailsView from '../EventDetailsView/EventHostDetailsView'
import EventDetailsMapView from '../EventDetailsView/EventDetailsMapView'
import EventDetailsCategoriesView from '../EventDetailsView/EventDetailsCategoriesView'
import EventDetailsTagView from '../EventDetailsView/EventDetailsTagView'
import EventTopBarMobileView from './EventTopBarMobileView'
import zIndex from '@mui/material/styles/zIndex'
import { set } from 'date-fns'
import EventPostsMobileView from './EventPostsMobileView'
import EventDetailsPostsMobileView from './EventDetailsPostsMobileView'
import EventDetailsAttendeesMobileView from './EventDetailsAttendeesMobileView'

const eventphotoheight = 265
const topbarheight = 56
const eventphotoheightdifference = eventphotoheight - topbarheight

const PicturesBox = styled(Box)(({ theme }) => ({
    width: '100%',
    borderRadius: 50,
    backgroundColor: hexToRGBA(theme.palette.background.primary, 0.7),
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignText: 'center',
    display: 'flex',
    padding: '5px 10px',
    cursor: 'pointer',
}))

const EventPageMobileView = () => {
    const { state, setState } = useAppState()
    const { slug } = useParams<{ slug: string }>()
    const navigate = useNavigate()
    const { tabIndex } = useParams<{ tabIndex: string }>()
    const location = useLocation()
    const locationEvent = location.state as NavigateEventState
    const eventslugRef = useRef(slug)
    const [eventState, setEventState] = useState<Event>(locationEvent.event)
    const [eventImage, setEventImage] = useState<string | null>(
        locationEvent.eventImage
    )
    const [eventHostImage, setEventHostImage] = useState<string | null>(null)
    const [value, setValue] = useState('date')
    const [scrollPosition, setScrollPosition] = useState(0)
    const [eventloaded, setEventLoaded] = useState<DataStatus>(
        DataStatus.loading
    )
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        if (detailsRef.current[newValue]) {
            const targetDiv = detailsRef.current[newValue] as HTMLElement
            const yPosition =
                targetDiv.getBoundingClientRect().top +
                window.scrollY -
                topbarheight -
                45
            console.log(yPosition)
            window.scrollTo({
                behavior: 'smooth',
                top: yPosition,
            })
        }
        setValue(newValue)
    }
    const detailsRef = useRef<{ [key: string]: HTMLElement | null }>({})
    useEffect(() => {
        eventslugRef.current = slug
    }, [window.location.pathname])
    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY)
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        const closestDetail = () => {
            var closestId: string | null = null
            let minDistance = Infinity
            Object.entries(detailsRef.current).forEach(([key, value]) => {
                if (value) {
                    const yPosition =
                        value.getBoundingClientRect().top - topbarheight - 45
                    if (Math.abs(yPosition) < minDistance) {
                        minDistance = Math.abs(yPosition)
                        closestId = key
                    }
                }
            })
            if (closestId) {
                return closestId
            } else {
                return value
            }
        }
        setValue(closestDetail())
    }, [scrollPosition])
    return (
        <>
            {tabIndex === 'details' ? (
                <Box
                    sx={{
                        width: '100%',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        height: 'calc(100% - 56px)',
                        mb: '80px',
                    }}
                >
                    <EventTopBarMobileView
                        event={eventState}
                        // show top bar text linearly after photo detials dissapear
                        opacity={Math.min(
                            (scrollPosition - 100) /
                                (eventphotoheightdifference - 100),
                            1
                        )}
                    />
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'sticky',
                                width: '100%',
                                height: eventphotoheight,
                                top: -eventphotoheightdifference,
                                backgroundColor: 'background.primary',
                                zIndex: 2,
                                overflow: 'hidden',
                            }}
                        >
                            {eventImage ? (
                                <img
                                    src={eventImage}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        filter: `blur(${Math.min((scrollPosition / eventphotoheightdifference) * 10, 10)}px)`,
                                        objectFit: 'cover',
                                    }}
                                />
                            ) : (
                                <LoadingBox
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        opacity: 1,
                                        zIndex: 1,
                                        objectFit: 'cover',
                                    }}
                                />
                            )}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    width: 'calc(100% - 48px)',
                                    bottom: 0,
                                    padding: '8px 16px',
                                    opacity:
                                        1 - Math.min(scrollPosition / 100, 1),
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 30,
                                        fontWeight: 800,
                                        textAlign: 'left',
                                        color: 'text.primary',
                                        textShadow:
                                            '0px 0px 5px rgba(0, 0, 0, 0.9)',
                                    }}
                                >
                                    {eventState.title !== ''
                                        ? eventState.title
                                        : 'Event Title'}
                                </Typography>
                                <Box
                                    sx={{
                                        alighItems: 'left',
                                        display: 'flex',
                                        alignItems: 'center',
                                        textShadow:
                                            '0px 0px 5px rgba(0, 0, 0, 0.9)',
                                    }}
                                >
                                    <FavoriteBorderRoundedIcon
                                        sx={{
                                            marginRight: '4px',
                                            fontSize: 20,
                                            color: 'text.primary',
                                            textShadow:
                                                '0px 0px 10px rgba(0, 0, 0, 0.9)',
                                        }}
                                    />
                                    <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        fontSize={16}
                                        sx={{
                                            textShadow:
                                                '0px 0px 5px rgba(0, 0, 0, 0.9)',
                                        }}
                                    >
                                        0 likes
                                    </Typography>
                                    <Box width={20} />
                                    <RepeatRoundedIcon
                                        sx={{
                                            marginRight: '4px',
                                            fontSize: 20,
                                            color: 'text.primary',
                                            textShadow:
                                                '0px 0px 10px rgba(0, 0, 0, 0.9)',
                                        }}
                                    />
                                    <Typography
                                        color="textPrimary"
                                        fontSize={16}
                                        sx={{
                                            textShadow:
                                                '0px 0px 5px rgba(0, 0, 0, 0.9)',
                                        }}
                                    >
                                        0 posts
                                    </Typography>
                                    <Box width={20} />
                                    <PersonOutlineRoundedIcon
                                        sx={{
                                            marginRight: '4px',
                                            fontSize: 20,
                                            color: 'text.primary',
                                            textShadow:
                                                '0px 0px 10px rgba(0, 0, 0, 0.9)',
                                        }}
                                    />

                                    <Typography
                                        variant="body1"
                                        color="textPrimary"
                                        fontSize={16}
                                        sx={{
                                            textShadow:
                                                '0px 0px 5px rgba(0, 0, 0, 0.9)',
                                        }}
                                    >
                                        0 attendees
                                    </Typography>
                                </Box>
                                <Box>
                                    <PicturesBox
                                        sx={{ marginTop: '8px' }}
                                        // onClick={() =>
                                        //     navigateToEventPhotos(
                                        //         event,
                                        //         eventImage,
                                        //         navigate,
                                        //         state,
                                        //         setState,
                                        //         tabIndex!
                                        //     )
                                        // }
                                    >
                                        <Typography
                                            color="textPrimary"
                                            sx={{ fontSize: 14 }}
                                        >
                                            See all 0 photos
                                        </Typography>
                                    </PicturesBox>
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                pt: '8px',
                                pb: '16px',
                                borderRadius: '0px 0px 10px 10px',
                            }}
                        >
                            <Box
                                sx={{
                                    p: '8px 16px',
                                }}
                            >
                                <EventDetailsStatisticsView
                                    event={eventState}
                                />
                            </Box>
                            <TabBox
                                sx={{
                                    position: 'sticky',
                                    top: topbarheight,
                                    backgroundColor: 'background.primary',
                                    zIndex: 2,
                                }}
                            >
                                <StyledTabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                    variant="scrollable"
                                >
                                    <StyledTab label="Date" value="date" />
                                    <StyledTab label="Host" value="host" />
                                    <StyledTab label="About" value="about" />
                                    <StyledTab
                                        label="Location"
                                        value="location"
                                    />
                                    <StyledTab label="Posts" value="posts" />
                                    <StyledTab
                                        label="Categories"
                                        value="categories"
                                    />
                                    <StyledTab label="Tags" value="tags" />
                                    <StyledTab
                                        label="Attendees"
                                        value="attendees"
                                    />
                                </StyledTabs>
                            </TabBox>
                            <Box
                                sx={{
                                    p: '8px 16px',
                                    mt: '8px',
                                }}
                                ref={(el: HTMLElement | null) =>
                                    (detailsRef.current['date'] = el)
                                }
                            >
                                <EventDetailsDateView event={eventState} />
                            </Box>
                            <PrimaryDivider sx={{ margin: '8px 0px' }} />
                            <Box
                                sx={{
                                    p: '8px 16px',
                                }}
                                ref={(el: HTMLElement | null) =>
                                    (detailsRef.current['host'] = el)
                                }
                            >
                                <EventHostDetailsView
                                    host={eventState.primary_host}
                                />
                            </Box>
                            <PrimaryDivider sx={{ margin: '8px 0px' }} />
                            <Box
                                sx={{
                                    p: '8px 16px',
                                }}
                                ref={(el: HTMLElement | null) =>
                                    (detailsRef.current['about'] = el)
                                }
                            >
                                <EventDetailsAboutView event={eventState} />
                            </Box>
                            <PrimaryDivider sx={{ margin: '8px 0px' }} />
                            <Box
                                sx={{
                                    p: '8px 16px',
                                }}
                                ref={(el: HTMLElement | null) =>
                                    (detailsRef.current['location'] = el)
                                }
                            >
                                <EventDetailsMapView event={eventState} />
                            </Box>
                            <PrimaryDivider sx={{ margin: '8px 0px' }} />
                            <Box
                                sx={{
                                    p: '8px 0px',
                                }}
                                ref={(el: HTMLElement | null) =>
                                    (detailsRef.current['posts'] = el)
                                }
                            >
                                <EventDetailsPostsMobileView
                                    event={eventState}
                                    eventImage={eventImage}
                                />
                            </Box>
                            <PrimaryDivider sx={{ margin: '8px 0px' }} />
                            <Box
                                sx={{
                                    p: '8px 16px',
                                }}
                                ref={(el: HTMLElement | null) =>
                                    (detailsRef.current['categories'] = el)
                                }
                            >
                                <EventDetailsCategoriesView
                                    event={eventState}
                                />
                            </Box>
                            <PrimaryDivider sx={{ margin: '8px 0px' }} />
                            <Box
                                sx={{
                                    p: '8px 16px',
                                }}
                                ref={(el: HTMLElement | null) =>
                                    (detailsRef.current['tags'] = el)
                                }
                            >
                                <EventDetailsTagView event={eventState} />
                            </Box>
                            <PrimaryDivider sx={{ margin: '8px 0px' }} />
                            <Box
                                sx={{
                                    p: '8px 16px',
                                }}
                                ref={(el: HTMLElement | null) =>
                                    (detailsRef.current['tags'] = el)
                                }
                            >
                                <EventDetailsAttendeesMobileView event={eventState} 
                                eventImage={eventImage}/>
                            </Box>
                            <PrimaryDivider sx={{ margin: '8px 0px' }} />
                        </Box>
                    </Box>
                </Box>
            ) : (
                tabIndex === 'posts' && (
                    <EventPostsMobileView event_slug={slug!} />
                )
            )}
        </>
    )
}

export default EventPageMobileView
