import React, { useEffect, useState, useRef } from 'react'
import { Event } from '../../models/Event'
import { DataStatus } from '../../models/Dataloading'
import { styled } from '@mui/material/styles'
import {
    Box,
    ButtonBase,
    CardContent,
    CardMedia,
    Typography,
} from '@mui/material'
import {
    favoriteEvent,
    grabEventPhoto,
    unfavoriteEvent,
} from '../../api/RestEvent'
import { grabUserPhoto } from '../../api/RestUser'
import { iso2eventtime } from '../../utils/DateStringConverter'
import { useAppState } from '../../AppStateProvider'
import { Link, useNavigate } from 'react-router-dom'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded'
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import {
    PrimaryTypography,
    SecondaryTypography,
    SelectTypography,
    StyledChipHover,
} from '../Components/StyledComponents'
import AccountAvatarSubView from '../AccountView/AccountAvatarSubView'
import { FavoritedRoundedIcon } from '../Components/StyledComponents/StyledIcons'
import { navigateToEvent } from '../../utils/NavigationFunctions'
import AccountDetailsSubView from '../AccountView/AccountSubViews/AccountDetailsSubView'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
import { hexToRGBA } from '../../utils/haxToRGBA'

interface EventSubViewProps {
    event: Event
}

const EventCard = styled(Box)(({ theme }) => ({
    boxShadow: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    cursor: 'pointer',
}))

const HostBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.host,
    borderRadius: '50px',
    justifyContent: 'center',
    width: 'fit-content',
    padding: '2px 16px',
    paddingLeft: '12px',
}))

const AttendingBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.attending,
    borderRadius: '50px',
    justifyContent: 'center',
    width: 'fit-content',
    padding: '2px 16px',
    paddingLeft: '12px',
}))

const EventLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
        textDecoration: 'none',
        color: 'inherit',
    },
}))

const UserPhotoCircle = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
}))

const LoadingBox = styled(CardMedia)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
}))

const StyledFavoriteBorderRoundedIcon = styled(FavoriteBorderRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const StyledRepeatRoundedIcon = styled(RepeatRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const StyledPersonOutlineRoundedIcon = styled(PersonOutlineRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const StyledCalendarMonthRoundedIcon = styled(CalendarMonthRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.selected,
    })
)

const EventSubPostView = ({ event }: EventSubViewProps) => {
    const { state, setState } = useAppState()
    const navigator = useNavigate()
    const [userimagestatus, setUserImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventimagestatus, setEventImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventState, setEventState] = useState<Event>(event)
    const [eventImage, setEventImage] = React.useState<string | null>(null)
    const [userimage, setUserImage] = React.useState<string | null>(null)
    const [following, setFollowing] = useState<boolean>(
        event.primary_host.following
    )
    const timerRef = useRef<number | null>(null)
    const [openAccountPopover, setOpenAccountPopover] = useState(false)
    const [accountAnchorEl, setAccountAnchorEl] = useState<null | HTMLElement>(
        null
    )
    const handleAccountPoperOpen = (event: any) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        setAccountAnchorEl(event.currentTarget)
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(true)
        }, 400)
    }

    const handleAccountPoperClose = () => {
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(false)
        }, 500)
    }
    useEffect(() => {
        try {
            grabEventPhoto(event.image, state.usertoken)
                .then((response) => {
                    setEventImage(response)
                    setEventImageStatus(DataStatus.success)
                })
                .catch((error) => {
                    console.log(error)
                    setEventImageStatus(DataStatus.failure)
                })
        } catch (error) {
            console.log(error)
            setEventImageStatus(DataStatus.failure)
        }
    }, [])
    useEffect(() => {
        try {
            grabUserPhoto(event.primary_host.image, state.usertoken)
                .then((response) => {
                    setUserImage(response)
                    setUserImageStatus(DataStatus.success)
                })
                .catch((error) => {
                    console.log(error)
                    setUserImageStatus(DataStatus.failure)
                })
        } catch (error) {
            console.log(error)
            setUserImageStatus(DataStatus.failure)
        }
    }, [])

    const handleFavorite = () => {
        if (eventState.favorited) {
            setEventState((prevItems) => ({
                ...prevItems,
                favorited: false,
                favorites_count: prevItems.favorites_count - 1,
            }))
            unfavoriteEvent(eventState.slug, state.usertoken)
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            setEventState((prevItems) => ({
                ...prevItems,
                favorited: true,
                favorites_count: prevItems.favorites_count + 1,
            }))
            favoriteEvent(eventState.slug, state.usertoken)
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
    return (
        <EventCard
            sx={{ width: '100%', maxWidth: '600px' }}
            onClick={function (event) {
                event.stopPropagation()
                navigateToEvent(
                    eventState,
                    eventImage,
                    navigator,
                    state,
                    setState,
                    'details'
                )
            }}
        >
            <Box display="flex">
                <div
                    style={{
                        display: 'flex',
                        borderRadius: '10px',
                        overflow: 'hidden',
                        width: 120,
                        height: 120,
                        aspectRatio: '1/1',
                    }}
                >
                    {eventimagestatus === DataStatus.success && eventImage ? (
                        <img
                            src={eventImage}
                            alt="Event"
                            style={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'cover',
                                borderRadius: '10px',
                            }}
                        />
                    ) : eventimagestatus === DataStatus.loading ? (
                        <LoadingBox />
                    ) : (
                        <LoadingBox />
                    )}
                </div>
                <Box display="flex" alignItems="center">
                    <Box marginLeft={2}>
                        <Box display="flex" alignItems="center">
                            {eventState.is_host ? (
                                <HostBox
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: '4px',
                                        mr: '8px',
                                    }}
                                >
                                    <SecurityRoundedIcon
                                        sx={{
                                            color: 'text.primary',
                                            fontSize: 12,
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                            color: 'text.primary',
                                            ml: '4px',
                                        }}
                                    >
                                        Host
                                    </Typography>
                                </HostBox>
                            ) : (
                                eventState.attending && (
                                    <AttendingBox
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mb: '4px',
                                        }}
                                    >
                                        <PersonRoundedIcon
                                            sx={{
                                                color: 'text.primary',
                                                fontSize: 14,
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                                color: 'text.primary',
                                                ml: '4px',
                                            }}
                                        >
                                            Attending
                                        </Typography>
                                    </AttendingBox>
                                )
                            )}
                        </Box>
                        <Typography
                            sx={{
                                fontSize: 18,
                                fontWeight: 'bold',
                                color: 'text.primary',
                                cursor: 'pointer',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            }}
                        >
                            {eventState.title}
                        </Typography>
                        <Box display="flex" alignItems="center" marginTop="0px">
                            <AccountDetailsSubView
                                user={eventState.primary_host}
                                followButton={false}
                                bio={false}
                                size={35}
                            />
                        </Box>
                        <Box display="flex" marginTop="4px">
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    color: 'text.selected',
                                }}
                            >
                                {iso2eventtime(eventState.event_time)}
                            </Typography>
                        </Box>
                        <Box display="flex">
                            <Box
                                sx={{
                                    display: 'flex',
                                    position: 'relative',
                                    height: '31px',
                                    width: '100%',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        position: 'absolute',
                                        left: '-10px',
                                    }}
                                >
                                    <StyledChipHover
                                        component={ButtonBase}
                                        onClick={handleFavorite}
                                    >
                                        {eventState.favorited ? (
                                            <>
                                                <FavoritedRoundedIcon
                                                    sx={{
                                                        color: (theme) =>
                                                            theme.palette.text
                                                                .favorite,
                                                        marginRight: '8px',
                                                        fontSize: 16,
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        // color: (theme) =>
                                                        //     theme.palette.text
                                                        //         .favorite,
                                                        fontSize: 14,
                                                        color: 'text.primary',
                                                    }}
                                                >
                                                    {eventState.favorites_count}
                                                </Typography>
                                            </>
                                        ) : (
                                            <>
                                                <StyledFavoriteBorderRoundedIcon
                                                    style={{
                                                        marginRight: '8px',
                                                        fontSize: 16,
                                                    }}
                                                />
                                                <PrimaryTypography
                                                    fontSize={14}
                                                >
                                                    {eventState.favorites_count}
                                                </PrimaryTypography>
                                            </>
                                        )}
                                    </StyledChipHover>
                                    <StyledChipHover
                                        component={ButtonBase}
                                        onClick={function (event) {
                                            event.stopPropagation()
                                            navigateToEvent(
                                                eventState,
                                                eventImage,
                                                navigator,
                                                state,
                                                setState,
                                                'posts'
                                            )
                                        }}
                                    >
                                        <StyledRepeatRoundedIcon
                                            style={{
                                                marginRight: 5,
                                                fontSize: 18,
                                            }}
                                        />
                                        <PrimaryTypography fontSize={14}>
                                            {eventState.post_count}
                                        </PrimaryTypography>
                                    </StyledChipHover>
                                    <StyledChipHover
                                        component={ButtonBase}
                                        onClick={function (event) {
                                            event.stopPropagation()
                                            navigateToEvent(
                                                eventState,
                                                eventImage,
                                                navigator,
                                                state,
                                                setState,
                                                'attendees'
                                            )
                                        }}
                                    >
                                        <StyledPersonOutlineRoundedIcon
                                            style={{
                                                marginRight: 5,
                                                fontSize: 16,
                                            }}
                                        />
                                        <PrimaryTypography fontSize={14} noWrap>
                                            {eventState.attendee_count}
                                        </PrimaryTypography>
                                    </StyledChipHover>

                                    {/* {eventState.is_host && (
                                        <StyledChipHover>
                                        <SecurityRoundedIcon
                                            sx={{
                                                color: 'text.host',
                                                fontSize: 18,
                                            }}
                                        />
                                        </StyledChipHover>
                                    )} */}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </EventCard>
    )
}

export default EventSubPostView
