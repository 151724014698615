// DateRangeCalendar.tsx
import React, { useState } from 'react'
import { Box, TextField, Grid, IconButton, Typography } from '@mui/material'
import {
    format,
    addDays,
    isBefore,
    isAfter,
    isSameDay,
    startOfMonth,
    endOfMonth,
} from 'date-fns'
import { styled } from '@mui/material/styles'
import { alpha } from '@mui/system'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import { FilterData, FilterDateChoice } from '../../models/Filter'

const calendardays = [
    {
        day: 'S',
        key: 'Sun',
    },
    {
        day: 'M',
        key: 'Mon',
    },
    {
        day: 'T',
        key: 'Tue',
    },
    {
        day: 'W',
        key: 'Wed',
    },
    {
        day: 'T',
        key: 'Thu',
    },
    {
        day: 'F',
        key: 'Fri',
    },
    {
        day: 'S',
        key: 'Sat',
    },
]

interface CalendarProps {
    currentMonth: Date
    selectedDates: [Date | null, Date | null]
    onSelectDate: (date: Date) => void
}

interface CalendarBoxProps extends React.ComponentProps<typeof Box> {
    selectedDates: [Date | null, Date | null]
    day: Date
    endDay: Date
}

const CalenderBox = styled((props: CalendarBoxProps) => <Box {...props} />)(
    ({ theme, selectedDates, day, endDay }) => ({
        // check if the day is the selected first day, both selections aren't the same date, and the selected day doesn't occur at the end of the week
        ...(selectedDates[0] &&
            selectedDates[1] &&
            isSameDay(day, selectedDates[0]) &&
            selectedDates[0].getDate() !== selectedDates[1].getDate() &&
            selectedDates[0].getDay() !== 6 && {
                backgroundColor: alpha(theme.palette.background.selected, 0.4),
                borderRadius: '50px 0 0 50px',
                color: 'white',
                '&:hover': {
                    backgroundColor: alpha(
                        theme.palette.background.selected,
                        0.4
                    ),
                },
            }),
        // check if the day is the selected second day, both selections aren't the same date, and the selected day doesn't occur at the beginning of the week
        ...(selectedDates[0] &&
            selectedDates[1] &&
            isSameDay(day, selectedDates[1]) &&
            selectedDates[0].getDate() !== selectedDates[1].getDate() &&
            selectedDates[1].getDay() !== 0 && {
                backgroundColor: alpha(theme.palette.background.selected, 0.4),
                borderRadius: '0 50px 50px 0',
                color: 'white',
                '&:hover': {
                    backgroundColor: alpha(
                        theme.palette.background.selected,
                        0.4
                    ),
                },
            }),
        // check if the day is between the selected dates
        ...(selectedDates[0] &&
            selectedDates[1] &&
            isBefore(day, selectedDates[1]) &&
            isAfter(day, selectedDates[0]) && {
                backgroundColor: alpha(theme.palette.background.selected, 0.4),
                // checking if the day is at the edge of the calendar and if so round the edges accordingly. Corner cases also considered
                ...(day.getDate() === 1 &&
                    day.getDay() === 6 && {
                        borderRadius: 50,
                    }),
                ...(day.getDate() === 1 &&
                    day.getDay() !== 6 && {
                        borderRadius: '50px 0 0 50px',
                    }),
                ...(day.getDate() === endDay.getDate() &&
                    day.getDay() === 0 && {
                        borderRadius: 50,
                    }),
                ...(day.getDate() === endDay.getDate() &&
                    day.getDay() !== 0 && {
                        borderRadius: '0 50px 50px 0',
                    }),
                ...(day.getDay() === 0 &&
                    day.getDate() !== 1 &&
                    day.getDate() !== endDay.getDate() && {
                        borderRadius: '50px 0 0 50px',
                    }),
                ...(day.getDay() === 6 &&
                    day.getDate() !== 1 &&
                    day.getDate() !== endDay.getDate() && {
                        borderRadius: '0px 50px 50px 0px',
                    }),
                ...(day.getDay() !== 0 &&
                    day.getDay() !== 6 &&
                    day.getDate() !== 1 &&
                    day.getDate() !== endDay.getDate() && {
                        borderRadius: 0,
                    }),

                color: 'white',
                '&:hover': {
                    backgroundColor: alpha(
                        theme.palette.background.selected,
                        0.4
                    ),
                },
            }),
        padding: 0,
        fontSize: 14,
        width: 40,
        height: 40,
    })
)

interface StyledIconButtonProps
    extends React.ComponentProps<typeof IconButton> {
    selectedDates: [Date | null, Date | null]
    day: Date
    endDay: Date
}

const StyledIconButton = styled((props: StyledIconButtonProps) => (
    <IconButton {...props} />
))(({ theme, selectedDates, day }) => ({
    ...(isSameDay(day, new Date()) && {
        border: '1px solid ' + theme.palette.text.tertiary,
    }),
    ...(selectedDates[0] &&
        isSameDay(day, selectedDates[0]) && {
            backgroundColor: alpha(theme.palette.background.selected, 1),
            color: 'white',
            '&:hover': {
                backgroundColor: alpha(theme.palette.background.selected, 1),
            },
        }),
    ...(selectedDates[1] &&
        isSameDay(day, selectedDates[1]) && {
            backgroundColor: alpha(theme.palette.background.selected, 1),
            color: theme.palette.text.primary,
            '&:hover': {
                backgroundColor: alpha(theme.palette.background.selected, 1),
            },
        }),
    color: theme.palette.text.secondary,
    width: 40,
    height: 40,
    fontSize: 14,
}))

const PrimaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const MonthBox = styled(Box)(({ theme }) => ({
    color: theme.palette.text.tertiary,
    fontSize: 14,
    fontFamily: 'Roboto',
    width: 40,
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
    fontSize: 14,
    '& .MuiInputBase-root': {
        color: theme.palette.text.primary,
    },
    '& .MuiInput-underline:before': {
        borderBottomColor: theme.palette.text.secondary,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.background.selected,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):after': {
        borderBottomColor: theme.palette.text.secondary,
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
            borderColor: theme.palette.text.secondary,
        },
    },
    '& .MuiInputBase-root.Mui-disabled': {
        color: 'green',
    },
    '& .MuiInputLabel-root.Mui-disabled': {
        color: theme.palette.text.secondary,
    },
}))

export const Calendar = ({
    currentMonth,
    selectedDates,
    onSelectDate,
}: CalendarProps) => {
    const startDay = startOfMonth(currentMonth)
    const endDay = endOfMonth(currentMonth)

    const generateDays = () => {
        const days = []
        let day = startDay

        while (isBefore(day, endDay) || isSameDay(day, endDay)) {
            days.push(day)
            day = addDays(day, 1)
        }

        return days
    }
    const days = generateDays()

    const emptyDays = () => {
        let emptyDays = []
        for (let i = 0; i < days[0].getDay(); i++) {
            emptyDays.push(<Grid item xs={1.71}></Grid>)
        }
        return emptyDays
    }

    return (
        <Grid
            container
            spacing={1}
            sx={{ paddingTop: '10px', paddingBottom: '10px' }}
        >
            {calendardays.map(function ({ day, key }, index) {
                return (
                    <Grid item xs={1.71} key={key}>
                        <MonthBox textAlign="center">{day}</MonthBox>
                    </Grid>
                )
            })}
            {emptyDays()}
            {days.map((day) => (
                <Grid item xs={1.71} key={day.toString()}>
                    <CalenderBox
                        selectedDates={selectedDates}
                        day={day}
                        endDay={endDay}
                        onClick={() => onSelectDate(day)}
                    >
                        <StyledIconButton
                            selectedDates={selectedDates}
                            day={day}
                            endDay={endDay}
                            onClick={() => onSelectDate(day)}
                        >
                            {format(day, 'd')}
                        </StyledIconButton>
                    </CalenderBox>
                </Grid>
            ))}
        </Grid>
    )
}

interface DateRangeCalendarProps {
    value: [Date | null, Date | null]
    tempfilterstate: FilterData
    setTempFilterState: React.Dispatch<React.SetStateAction<FilterData>>
}

const DateRangeCalendar = ({
    value,
    tempfilterstate,
    setTempFilterState,
}: DateRangeCalendarProps) => {
    const [selectedDates, setSelectedDates] =
        useState<[Date | null, Date | null]>(value)
    const [currentMonth, setCurrentMonth] = useState(new Date())

    const handleSelectDate = (date: Date) => {
        if (!selectedDates[0] || selectedDates[1]) {
            setSelectedDates([date, null])
        } else if (isBefore(date, selectedDates[0])) {
            setSelectedDates([date, selectedDates[0]])
            setTempFilterState({
                ...tempfilterstate,
                date: {
                    choice: FilterDateChoice.Custom,
                    dates: [date, selectedDates[0]],
                },
            })
        } else {
            setSelectedDates([selectedDates[0], date])
            setTempFilterState({
                ...tempfilterstate,
                date: {
                    choice: FilterDateChoice.Custom,
                    dates: [selectedDates[0], date],
                },
            })
        }
    }

    return (
        <Box sx={{ paddingTop: '10px' }}>
            <StyledTextField
                label="Date Range"
                value={
                    selectedDates[0] && selectedDates[1]
                        ? `${format(selectedDates[0], 'MM/dd/yyyy')} - ${format(selectedDates[1], 'MM/dd/yyyy')}`
                        : ''
                }
                fullWidth
                disabled={true}
            />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '10px',
                    paddingTop: '10px',
                }}
            >
                <PrimaryTypography>
                    {format(currentMonth, 'MMMM yyyy')}
                </PrimaryTypography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                    onClick={() => setCurrentMonth(addDays(currentMonth, -30))}
                >
                    <ChevronLeftRoundedIcon />
                </IconButton>
                <IconButton
                    onClick={() => setCurrentMonth(addDays(currentMonth, 30))}
                >
                    <ChevronRightRoundedIcon />
                </IconButton>
            </Box>
            <Calendar
                currentMonth={currentMonth}
                selectedDates={selectedDates}
                onSelectDate={handleSelectDate}
            />
        </Box>
    )
}

export default DateRangeCalendar
