import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppState } from '../../../AppStateProvider'
import { NavigateAccountFFState } from '../../../models/NavigateState'
import {
    Box,
    ClickAwayListener,
    Container,
    Grow,
    List,
    Tab,
    Tabs,
    styled,
} from '@mui/material'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { User } from '../../../models/User'
import { getFollowing } from '../../../api/RestUser'
import AccountDetailsSubView from '../AccountDesciptionSubView'
import { DataStatus } from '../../../models/Dataloading'
import {
    PrimaryTypography,
    StyledCircularProgress,
    StyledIconButton,
} from '../../Components/StyledComponents'
import { hexToRGBA } from '../../../utils/haxToRGBA'
import { navigateToAccount } from '../../../utils/NavigationFunctions'
import {
    StyledTab,
    StyledTabs,
    TabBox,
} from '../../Components/StyledComponents/StyledTabView'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

const StyledCloseRoundedIcon = styled(CloseRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))
const RootView = styled(Box)(({ theme }) => ({
    height: '100%',
    width: '100%',
    position: 'fixed',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
    zIndex: theme.zIndex.drawer + 1,
    // backdropFilter: 'blur(10px)',
    background: hexToRGBA('black', 0.7),
}))

const ContainerBox = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.primary,
    borderRadius: 20,
    padding: '20px 20px',
    minHeight: '60%',
}))

const AccountFFView = () => {
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    const location = useLocation()
    const [pageLoaded, setPageLoaded] = useState(false)
    const { username } = useParams<{ username: string }>()
    const { tabIndex } = useParams<{ tabIndex: string }>()
    const { ffTabIndex } = useParams<{ ffTabIndex: string }>()
    const initialAccountFFState = location.state as NavigateAccountFFState
    const [value, setValue] = useState(ffTabIndex)
    const parentElement = document.getElementById('root')

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
        navigate(`/app/account/${username}/${tabIndex}/ff/${newValue}`, {
            state: {
                user: initialAccountFFState.user,
                userImage: initialAccountFFState.userImage,
                eventImage: initialAccountFFState.eventImage,
            },
        })
    }

    useEffect(() => {
        if (!pageLoaded) {
            setPageLoaded(true)
        }
    }, [])
    return ReactDOM.createPortal(
        <RootView id="account-ff-view">
            <ClickAwayListener
                onClickAway={() => {
                    navigate(`/app/account/${username}/${tabIndex}`, {
                        state: {
                            user: initialAccountFFState.user,
                            userImage: initialAccountFFState.userImage,
                            eventImage: initialAccountFFState.eventImage,
                        },
                    })
                }}
            >
                <Grow in={pageLoaded}>
                    <ContainerBox maxWidth="sm" disableGutters>
                        <Box sx={{ position: 'relative' }}>
                            <PrimaryTypography
                                sx={{
                                    textAlign: 'center',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                }}
                            >
                                {value!.charAt(0).toUpperCase() +
                                    value!.slice(1)}
                            </PrimaryTypography>
                            <TabBox marginTop={'8px'}>
                                <StyledTabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                >
                                    <StyledTab
                                        label={`${initialAccountFFState.user.follower_size} Followers`}
                                        value="followers"
                                    />
                                    <StyledTab
                                        label={`${initialAccountFFState.user.following_size} Following`}
                                        value="following"
                                    />
                                    <StyledTab
                                        label="Mutuals"
                                        value="mutuals"
                                    />
                                </StyledTabs>
                            </TabBox>
                            {value === 'followers' ? (
                                <AccountFollowersView
                                    user={initialAccountFFState.user}
                                    followers={initialAccountFFState.followers}
                                />
                            ) : (
                                value === 'following' && (
                                    <AccountFollowingView
                                        user={initialAccountFFState.user}
                                        following={
                                            initialAccountFFState.following
                                        }
                                    />
                                )
                            )}
                            <StyledIconButton
                                sx={{
                                    float: 'right',
                                    position: 'absolute',
                                    right: '0px',
                                    top: '-4px',
                                }}
                                onClick={() => {
                                    navigate(
                                        `/app/account/${username}/${tabIndex}`,
                                        {
                                            state: {
                                                user: initialAccountFFState.user,
                                                userImage:
                                                    initialAccountFFState.userImage,
                                                eventImage:
                                                    initialAccountFFState.eventImage,
                                            },
                                        }
                                    )
                                }}
                            >
                                <StyledCloseRoundedIcon sx={{ fontSize: 26 }} />
                            </StyledIconButton>
                        </Box>
                    </ContainerBox>
                </Grow>
            </ClickAwayListener>
        </RootView>,
        parentElement as Element
    )
}

interface AccountFollowersViewProps {
    user: User
    followers: User[] | null
}
interface AccountFollowingViewProps {
    user: User
    following: User[] | null
}

const AccountFollowersView = ({
    user,
    followers,
}: AccountFollowersViewProps) => {
    const { state } = useAppState()
    const [followersState, setFollowersState] = useState<User[] | null>(
        followers
    )
    const [followersLoaded, setFollowersLoaded] = useState<DataStatus>(
        DataStatus.loading
    )
    useEffect(() => {
        if (followersState) {
            setFollowersLoaded(DataStatus.success)
        } else {
            try {
                getFollowing(user.username, state.usertoken)
                    .then((response) => {
                        setFollowersState(response)
                        setFollowersLoaded(DataStatus.success)
                    })
                    .catch((error) => {
                        console.log(error)
                        setFollowersLoaded(DataStatus.failure)
                    })
            } catch (error) {
                console.log(error)
                setFollowersLoaded(DataStatus.failure)
            }
        }
    }, [])
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {followersLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <StyledCircularProgress sx={{ marginTop: '8px' }} />
                </Box>
            ) : followersLoaded == DataStatus.failure ? (
                <ErrorTabView />
            ) : (
                followersLoaded === DataStatus.success &&
                followersState && (
                    <List>
                        {followersState.map((followerUser) => (
                            <AccountDetailsSubView user={followerUser} />
                        ))}
                    </List>
                )
            )}
        </Box>
    )
}

const AccountFollowingView = ({
    user,
    following,
}: AccountFollowingViewProps) => {
    const { state } = useAppState()
    const [followingState, setFollowingState] = useState<User[] | null>(
        following
    )
    const [followingLoaded, setFollowingLoaded] = useState<DataStatus>(
        DataStatus.loading
    )
    useEffect(() => {
        if (followingState) {
            setFollowingLoaded(DataStatus.success)
        } else {
            try {
                getFollowing(user.username, state.usertoken)
                    .then((response) => {
                        setFollowingState(response)
                        setFollowingLoaded(DataStatus.success)
                    })
                    .catch((error) => {
                        console.log(error)
                        setFollowingLoaded(DataStatus.failure)
                    })
            } catch (error) {
                console.log(error)
                setFollowingLoaded(DataStatus.failure)
            }
        }
    }, [])
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {followingLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <StyledCircularProgress sx={{ marginTop: '8px' }} />
                </Box>
            ) : followingLoaded == DataStatus.failure ? (
                <ErrorTabView />
            ) : (
                followingLoaded === DataStatus.success &&
                followingState && (
                    <List>
                        {followingState.map((followingUser) => (
                            <AccountDetailsSubView user={followingUser} />
                        ))}
                    </List>
                )
            )}
        </Box>
    )
}
export default AccountFFView
