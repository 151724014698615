import React, { useEffect, useRef, useState } from 'react'
import {
    Box,
    CardContent,
    CardMedia,
    Popover,
    Popper,
    Typography,
    Button,
    Fade,
    ClickAwayListener,
} from '@mui/material'
import { styled } from '@mui/system'
import { User } from '../../models/User'
import { get } from 'http'
import { followUser, grabUserPhoto, unfollowUser } from '../../api/RestUser'
import { useAppState } from '../../AppStateProvider'
import { DataStatus } from '../../models/Dataloading'
import {
    PrimaryDivider,
    PrimaryTypography,
    SecondaryTypography,
    TertiaryTypography,
    UserPhotoCircle,
} from '../Components/StyledComponents'
import { getEvents, grabEventPhoto } from '../../api/RestEvent'
import { navigateToAccount } from '../../utils/NavigationFunctions'
import { useNavigate } from 'react-router-dom'
import ErrorAccountAvatarView from '../Components/StaticPages/ErrorView/ErrorAccountAvatarView'

const userphotoheight = 60
const eventphotoheight = 80

const AccountCard = styled(Popper)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 10,
        width: 350,
    },
}))

const ContainerPopper = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
    borderRadius: 10,
    width: 350,
    cursor: 'pointer',
    zIndex: 100000000000000000000,
}))

const LoadingBox = styled(CardMedia)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
}))

const FollowButton = styled(Button)(({ theme }) => ({
    borderRadius: '50px',
    padding: '3px 20px',
    fontSize: 14,
    backgroundColor: 'white',
    color: theme.palette.background.secondary,
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: 'white',
    },
}))

const UnfollowButton = styled(Button)(({ theme }) => ({
    borderRadius: '50px',
    padding: '3px 20px',
    fontSize: 14,
    backgroundColor: theme.palette.background.selected,
    color: theme.palette.text.primary,
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: theme.palette.background.selected,
    },
}))

interface AccountPopoverViewProps {
    user: User
    setUser: React.Dispatch<React.SetStateAction<User>>
    openpopover: boolean
    setOpenPopover: (open: boolean) => void
    anchorEl: HTMLElement | null
    setAnchorEl: (element: HTMLElement | null) => void
    timerRef: React.MutableRefObject<number | null>
}

const AccountPopoverView = ({
    user,
    setUser,
    openpopover,
    setOpenPopover,
    anchorEl,
    setAnchorEl,
    timerRef,
}: AccountPopoverViewProps) => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const popperTimeRef = useRef<number | null>(null)
    const [userimage, setUserImage] = useState<string | null>(null)
    const [userimagestatus, setUserImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventimage, setEventImage] = useState<string | null>(null)
    const [eventimagestatus, setEventImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const handleMouseEnter = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        if (popperTimeRef.current) {
            clearTimeout(popperTimeRef.current)
        }
    }
    const handleMouseLeave = () => {
        popperTimeRef.current = window.setTimeout(() => {
            setOpenPopover(false)
        }, 300)
    }
    const handleFollow = () => {
        setUser({
            ...user,
            follower_size: user.follower_size + 1,
            following: true,
        })
        try {
            followUser(user.username, state.usertoken)
                .then((response) => {
                    return
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleUnfollow = () => {
        setUser({
            ...user,
            follower_size: user.follower_size - 1,
            following: false,
        })
        try {
            unfollowUser(user.username, state.usertoken)
                .then((response) => {
                    return
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        try {
            grabUserPhoto(user.image, state.usertoken)
                .then((response) => {
                    setUserImage(response)
                    setUserImageStatus(DataStatus.success)
                })
                .catch((error) => {
                    console.log(error)
                    setUserImageStatus(DataStatus.failure)
                })
        } catch (error) {
            console.log(error)
            setUserImageStatus(DataStatus.failure)
        }
        try {
            getEvents(null, user.username, null, 0, 1, 0, state.usertoken)
                .then((response) => {
                    grabEventPhoto(response.events[0].image, state.usertoken)
                        .then((response) => {
                            setEventImage(response)
                            setEventImageStatus(DataStatus.success)
                        })
                        .catch((error) => {
                            console.log(error)
                            setEventImageStatus(DataStatus.failure)
                        })
                })
                .catch((error) => {
                    console.log(error)
                    setEventImageStatus(DataStatus.failure)
                })
        } catch (error) {
            console.log(error)
            setEventImageStatus(DataStatus.failure)
        }
    }, [])

    return (
        <ClickAwayListener onClickAway={() => setOpenPopover(false)}>
            <AccountCard
                open={openpopover}
                anchorEl={anchorEl}
                sx={{
                    borderRadius: 10,
                }}
                placement="bottom-start"
                transition
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps}>
                        <ContainerPopper
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Box>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '10px 10px 0px 0px',
                                        height: eventphotoheight,
                                        width: '100%',
                                        overflow: 'hidden',
                                        position: 'relative',
                                    }}
                                >
                                    {eventimagestatus === DataStatus.success &&
                                    eventimage ? (
                                        <img
                                            src={eventimage}
                                            alt="Event"
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                                objectFit: 'cover',
                                                borderRadius: 0,
                                            }}
                                        />
                                    ) : (
                                        <LoadingBox />
                                    )}
                                </div>
                                <Box
                                    style={{
                                        position: 'absolute',
                                        top:
                                            eventphotoheight -
                                            userphotoheight / 2,
                                        left: '20px',
                                    }}
                                >
                                    {userimagestatus === DataStatus.success &&
                                    userimage ? (
                                        <UserPhotoCircle
                                            includeBorder={true}
                                            sx={{
                                                height: userphotoheight,
                                                width: userphotoheight,
                                                borderRadius: '50%',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <img
                                                src={userimage}
                                                alt="userimage"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </UserPhotoCircle>
                                    ) : userimagestatus ===
                                      DataStatus.loading ? (
                                        <UserPhotoCircle
                                            includeBorder={true}
                                            sx={{
                                                height: userphotoheight,
                                                width: userphotoheight,
                                                borderRadius: '50%',
                                                overflow: 'hidden',
                                            }}
                                        />
                                    ) : (
                                        (userimagestatus ===
                                            DataStatus.failure ||
                                            !userimage) && (
                                            <ErrorAccountAvatarView
                                                size={userphotoheight}
                                                includeBorder={true}
                                            />
                                        )
                                    )}
                                </Box>
                                <Box
                                    style={{
                                        position: 'absolute',
                                        top: eventphotoheight,
                                        padding: '10px 0px',
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'right',
                                        justifyContent: 'right',
                                    }}
                                >
                                    {user.following ? (
                                        <UnfollowButton
                                            sx={{ marginRight: '20px' }}
                                            onClick={handleUnfollow}
                                        >
                                            Following
                                        </UnfollowButton>
                                    ) : (
                                        <FollowButton
                                            sx={{ marginRight: '20px' }}
                                            onClick={handleFollow}
                                        >
                                            Follow
                                        </FollowButton>
                                    )}
                                </Box>
                                <Box
                                    style={{
                                        padding: '0px 20px',
                                        marginTop: userphotoheight / 2 + 10,
                                        paddingBottom: '15px',
                                    }}
                                >
                                    <PrimaryTypography
                                        mt={1}
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                            '&:hover': {
                                                textDecoration: 'underline',
                                            },
                                        }}
                                        onClick={() =>
                                            navigateToAccount(
                                                user.username,
                                                user,
                                                userimage,
                                                eventimage,
                                                navigate,
                                                state,
                                                setState,
                                                'attending'
                                            )
                                        }
                                    >
                                        {user.name}
                                    </PrimaryTypography>
                                    <TertiaryTypography fontSize={14}>
                                        @{user.username}
                                    </TertiaryTypography>
                                    {user.bio && (
                                        <PrimaryTypography fontSize={14}>
                                            {user.bio}
                                        </PrimaryTypography>
                                    )}
                                    <PrimaryDivider
                                        sx={{ mt: '15px', mb: '15px' }}
                                    />
                                    <Box
                                        justifyContent={'left'}
                                        alignItems={'center'}
                                        display={'flex'}
                                    >
                                        <Box
                                            sx={{
                                                justifyItems: 'center',
                                                paddingRight: '15px',
                                            }}
                                        >
                                            <PrimaryTypography
                                                fontSize={14}
                                                fontWeight={'bold'}
                                                textAlign={'left'}
                                            >
                                                {user.follower_size}
                                            </PrimaryTypography>
                                            <TertiaryTypography
                                                fontSize={12}
                                                textAlign={'center'}
                                                fontWeight={'bold'}
                                            >
                                                Followers
                                            </TertiaryTypography>
                                        </Box>
                                        <Box
                                            sx={{
                                                justifyItems: 'center',
                                                padding: '0px 15px',
                                            }}
                                        >
                                            <PrimaryTypography
                                                fontSize={14}
                                                fontWeight={'bold'}
                                                textAlign={'left'}
                                            >
                                                {user.following_size}
                                            </PrimaryTypography>
                                            <TertiaryTypography
                                                fontSize={12}
                                                textAlign={'center'}
                                                fontWeight={'bold'}
                                            >
                                                Following
                                            </TertiaryTypography>
                                        </Box>
                                        <Box
                                            sx={{
                                                justifyItems: 'center',
                                                padding: '0px 15px',
                                            }}
                                        >
                                            <PrimaryTypography
                                                fontSize={14}
                                                fontWeight={'bold'}
                                                textAlign={'left'}
                                            >
                                                {0}
                                            </PrimaryTypography>
                                            <TertiaryTypography
                                                fontSize={12}
                                                textAlign={'center'}
                                                fontWeight={'bold'}
                                            >
                                                Posts
                                            </TertiaryTypography>
                                        </Box>
                                        <Box
                                            sx={{
                                                justifyItems: 'center',
                                                padding: '0px 15px',
                                            }}
                                        >
                                            <PrimaryTypography
                                                fontSize={14}
                                                fontWeight={'bold'}
                                                textAlign={'left'}
                                            >
                                                {0}
                                            </PrimaryTypography>
                                            <TertiaryTypography
                                                fontSize={12}
                                                textAlign={'center'}
                                                fontWeight={'bold'}
                                            >
                                                Events
                                            </TertiaryTypography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </ContainerPopper>
                    </Fade>
                )}
            </AccountCard>
        </ClickAwayListener>
    )
}

export default AccountPopoverView
