import axios from 'axios'
import { Ticket } from '../models/Ticket'
import { mainUrl } from '../constants'

export interface getTicketsReply {
    tickets: Ticket[]
    ticket_count: number
}

export const getTickets = async (
    state: string | null,
    slug: string | null,
    limit: number,
    offset: number,
    userToken: string
): Promise<getTicketsReply> => {
    try {
        const params: Record<string, any> = {
            limit,
            offset,
        }
        if (state) {
            params.state = state
        }
        if (slug) {
            params.slug = slug
        }
        const response = await axios.get<getTicketsReply>(`${mainUrl}tickets`, {
            params: params,
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        })
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}
