import {
    Box,
    Drawer,
    DrawerProps,
    IconButton,
    Grid,
    Chip,
    ButtonBase,
    Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import {
    PrimaryTypography,
    SecondaryTypography,
    PrimaryDivider,
    EmptyImageBox,
    StyledTextField,
    TertiaryTypography,
    DefaultButton,
    FollowButton,
    SelectButton,
    StyledChip,
} from '../../Components/StyledComponents'
import { CreateEvent } from '../../../models/CreateEvent'
import { FilterCategory } from '../../../models/Filter'
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded'
import CreateEventLocationView from './CreateEventLocationView'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { useNavigate } from 'react-router-dom'
import CreateEventDateView from './CreateEventDateView'
import CreateEventDescriptionView from './CreateEventDescriptionView'

const StyledOpenInFullRoundedIcon = styled(OpenInFullRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.secondary,
    })
)
interface StyledIconButtonProps extends React.ComponentProps<typeof Chip> {
    item: FilterCategory
    selecteditems: string[]
}

const CategroyChip = styled((props: StyledIconButtonProps) => (
    <Chip {...props} />
))(({ theme, item, selecteditems }) => ({
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.tertiary,
    ...(selecteditems.includes(item) && {
        backgroundColor: theme.palette.background.selected,
        color: theme.palette.text.primary,
    }),
}))
const TagsContainer = styled(Box)(({ theme }) => ({
    borderRadius: '10px',
    border: '1px solid ' + theme.palette.border.primary,
}))

interface CreateAccountDetailsViewProps {
    tempEventData: CreateEvent
    setTempEventData: React.Dispatch<React.SetStateAction<CreateEvent>>
}

const CreateEventTextDetails = ({
    tempEventData,
    setTempEventData,
}: CreateAccountDetailsViewProps) => {
    const navigate = useNavigate()
    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempEventData({ ...tempEventData, title: event.target.value })
    }
    const handleDescriptionChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setTempEventData({ ...tempEventData, description: event.target.value })
    }
    const handleCategoryChange = (newValue: FilterCategory) => {
        setTempEventData((prevItems) => {
            if (prevItems.category_list.includes(newValue)) {
                return {
                    ...prevItems,
                    category_list: prevItems.category_list.filter(
                        (item) => item !== newValue
                    ),
                }
            } else {
                return {
                    ...prevItems,
                    category_list: [...prevItems.category_list, newValue],
                }
            }
        })
    }

    return (
        <Box>
            <Typography
                sx={{
                    paddingTop: '4px',
                    fontSize: 12,
                    fontWeight: 'bold',
                    mt: '16px',
                    mb: '4px',
                    color: 'text.secondary',
                }}
            >
                Event Title
            </Typography>
            <Box>
                <StyledTextField
                    variant="outlined"
                    label="Event Title"
                    fullWidth
                    size="small"
                    value={tempEventData.title}
                    onChange={handleTitleChange}
                    sx={{
                        marginTop: '5px',
                        marginBottom: '5px',
                    }}
                />
                <Typography
                    sx={{
                        fontSize: 12,
                        textAlign: 'right',
                        color: 'text.secondary',
                    }}
                >
                    {`${tempEventData.title.length}/50`}
                </Typography>
            </Box>
            <CreateEventDescriptionView
                tempEventData={tempEventData}
                setTempEventData={setTempEventData}
            />
            <PrimaryDivider sx={{ m: '24px 0' }} />
            <CreateEventDateView
                tempEventData={tempEventData}
                setTempEventData={setTempEventData}
            />
            <PrimaryDivider sx={{ m: '24px 0' }} />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Typography
                    sx={{
                        marginTop: '0px',
                        marginBottom: '4px',
                        fontSize: 12,
                        fontWeight: 'bold',
                        float: 'left',
                        color: 'text.secondary',
                    }}
                >
                    Event Location
                </Typography>
                {/* <IconButton
                sx={{
                    float: 'right',
                    marginLeft: 'auto',
                }}
                >
                    <StyledOpenInFullRoundedIcon 
                    sx={{fontSize: 20}}
                    />
                </IconButton> */}
            </Box>
            <CreateEventLocationView
                tempEventData={tempEventData}
                setTempEventData={setTempEventData}
            />
            <PrimaryDivider sx={{ m: '24px 0' }} />
            <Typography
                sx={{
                    mt: '0px',
                    marginBottom: '8px',
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: 'text.secondary',
                }}
            >
                Event Categories
            </Typography>
            <TagsContainer
                sx={{
                    mt: '8px',
                    p: '8px',
                }}
            >
                <Box
                    sx={{
                        flexWrap: 'wrap',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: '8px',
                    }}
                >
                    {Object.values(FilterCategory).map((item, index) => (
                        <ButtonBase
                            sx={{ borderRadius: 50 }}
                            onClick={() => handleCategoryChange(item)}
                        >
                            <CategroyChip
                                key={index}
                                label={item.toString()}
                                item={item}
                                selecteditems={tempEventData.category_list}
                            />
                        </ButtonBase>
                    ))}
                </Box>
            </TagsContainer>
            <Typography
                sx={{
                    mt: '16px',
                    mb: '4px',
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: 'text.secondary',
                }}
            >
                Event Tags
            </Typography>
            <StyledChip
                component={ButtonBase}
                sx={{
                    cursor: 'pointer',
                    mt: '8px',
                }}
                onClick={() =>
                    navigate('/app/createevent/tags', {
                        state: { tempEventData: tempEventData },
                    })
                }
            >
                <AddRoundedIcon
                    sx={{
                        fontSize: 16,
                        color: (theme) => theme.palette.text.primary,
                    }}
                />
                <PrimaryTypography
                    sx={{ fontSize: 14, ml: '4px', fontWeight: 'bold' }}
                >
                    Edit Tags
                </PrimaryTypography>
            </StyledChip>
            {tempEventData.tag_list.length > 0 && (
                <TagsContainer
                    sx={{
                        mt: '8px',
                        p: '8px',
                    }}
                >
                    <Box
                        sx={{
                            flexWrap: 'wrap',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            gap: '8px',
                        }}
                    >
                        {tempEventData.tag_list.map((tag, index) => (
                            <StyledChip key={index}>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        color: 'text.secondary',
                                    }}
                                >
                                    #{tag}
                                </Typography>
                            </StyledChip>
                        ))}
                    </Box>
                </TagsContainer>
            )}
        </Box>
    )
}

export default CreateEventTextDetails
