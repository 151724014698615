import { Box, Typography, styled } from '@mui/material'
import React from 'react'
import ReactDOM from 'react-dom'
import { hexToRGBA } from '../../../../utils/haxToRGBA'
import { StyledCircularProgress } from '../../StyledComponents'

interface LoadingOverlayMobileViewProps {
    title: string
}

const OverlayBox = styled(Box)(({ theme }) => ({
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    left: 0,
    top: 0,
    display: 'flex',
    zIndex: theme.zIndex.drawer + 3,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: hexToRGBA(theme.palette.background.primary, 0.8),
}))

const OverlayContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.background.tertiary,
    borderRadius: '10px',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}))

const LoadingOverlayMobileView = ({ title }: LoadingOverlayMobileViewProps) => {
    const parentElement = document.getElementById('root')
    return ReactDOM.createPortal(
        <OverlayBox>
            <OverlayContainer>
                <StyledCircularProgress />
                <Typography
                    sx={{
                        color: 'text.secondary',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginTop: '16px',
                    }}
                >
                    {title}
                </Typography>
            </OverlayContainer>
        </OverlayBox>,
        parentElement as Element
    )
}

export default LoadingOverlayMobileView
