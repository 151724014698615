import { useNavigate } from 'react-router-dom'
import { Ticket } from '../../models/Ticket'
import { useAppState } from '../../AppStateProvider'
import { useEffect, useRef, useState } from 'react'
import { DataStatus } from '../../models/Dataloading'
import { grabEventPhoto } from '../../api/RestEvent'
import { grabUserPhoto } from '../../api/RestUser'
import { Box, ButtonBase, Typography, styled } from '@mui/material'
import { LoadingBox, StyledChipHover } from '../Components/StyledComponents'
import AccountDetailsSubView from '../AccountView/AccountSubViews/AccountDetailsSubView'
import { iso2eventtime } from '../../utils/DateStringConverter'
import { FavoritedRoundedIcon } from '../Components/StyledComponents/StyledIcons'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded'
import {
    navigateToEvent,
    navigateToGiftTickets,
} from '../../utils/NavigationFunctions'
import CardGiftcardRoundedIcon from '@mui/icons-material/CardGiftcardRounded'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined'

const EventCard = styled('div')(({ theme }) => ({
    boxShadow: 'none',
    backgroundColor: theme.palette.background.secondary,
    border: '1px solid ' + theme.palette.border.primary,
    padding: 10,
    borderRadius: 10,
    position: 'relative',
    '&:hover': {
        backgroundColor: theme.palette.background.secondary,
    },
    cursor: 'pointer',
}))

const DetailsBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderRadius: 50,
}))

interface TicketItemViewProps {
    ticket: Ticket
}
const TicketItemView = ({ ticket }: TicketItemViewProps) => {
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    const [userimagestatus, setUserImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventimagestatus, setEventImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [ticketState, setTicketState] = useState<Ticket>(ticket)
    const [eventImage, setEventImage] = useState<string | null>(null)
    const [userimage, setUserImage] = useState<string | null>(null)
    const [following, setFollowing] = useState<boolean>(
        ticket.event.primary_host.following
    )
    const timerRef = useRef<number | null>(null)
    const [openAccountPopover, setOpenAccountPopover] = useState(false)
    const [accountAnchorEl, setAccountAnchorEl] = useState<null | HTMLElement>(
        null
    )
    const handleAccountPoperOpen = (event: any) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        setAccountAnchorEl(event.currentTarget)
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(true)
        }, 400)
    }

    const handleAccountPoperClose = () => {
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(false)
        }, 500)
    }
    useEffect(() => {
        try {
            grabEventPhoto(ticket.event.image, state.usertoken)
                .then((response) => {
                    setEventImage(response)
                    setEventImageStatus(DataStatus.success)
                })
                .catch((error) => {
                    console.log(error)
                    setEventImageStatus(DataStatus.failure)
                })
        } catch (error) {
            console.log(error)
            setEventImageStatus(DataStatus.failure)
        }
    }, [])
    useEffect(() => {
        try {
            grabUserPhoto(ticket.event.primary_host.image, state.usertoken)
                .then((response) => {
                    setUserImage(response)
                    setUserImageStatus(DataStatus.success)
                })
                .catch((error) => {
                    console.log(error)
                    setUserImageStatus(DataStatus.failure)
                })
        } catch (error) {
            console.log(error)
            setUserImageStatus(DataStatus.failure)
        }
    }, [])

    // const handleFavorite = () => {
    //     if (ticket.event.favorited) {
    //         setEventState((prevItems) => ({
    //             ...prevItems,
    //             favorited: false,
    //             favorites_count: prevItems.favorites_count - 1,
    //         }))
    //         unfavoriteEvent(eventState.slug, state.usertoken)
    //             .then((response) => {
    //                 console.log(response)
    //             })
    //             .catch((error) => {
    //                 console.log(error)
    //             })
    //     } else {
    //         setEventState((prevItems) => ({
    //             ...prevItems,
    //             favorited: true,
    //             favorites_count: prevItems.favorites_count + 1,
    //         }))
    //         favoriteEvent(eventState.slug, state.usertoken)
    //             .then((response) => {
    //                 console.log(response)
    //             })
    //             .catch((error) => {
    //                 console.log(error)
    //             })
    //     }
    // }
    return (
        <EventCard
            sx={{ width: '100%', maxWidth: '500px' }}
            onClick={function (event) {
                event.stopPropagation()
                navigateToEvent(
                    ticketState.event,
                    eventImage,
                    navigate,
                    state,
                    setState,
                    'details'
                )
            }}
        >
            <Box display="flex">
                <div
                    style={{
                        display: 'flex',
                        borderRadius: '10px',
                        overflow: 'hidden',
                        width: 120,
                        height: 120,
                        aspectRatio: '1/1',
                    }}
                >
                    {eventimagestatus === DataStatus.success && eventImage ? (
                        <img
                            src={eventImage}
                            alt="Event"
                            style={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'cover',
                                borderRadius: '10px',
                            }}
                        />
                    ) : eventimagestatus === DataStatus.loading ? (
                        <LoadingBox />
                    ) : (
                        <LoadingBox />
                    )}
                </div>
                <Box display="flex" alignItems="center">
                    <Box marginLeft={2}>
                        <Typography
                            sx={{
                                fontSize: 18,
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                                color: 'text.primary',
                            }}
                        >
                            {ticket.event.title}
                        </Typography>
                        <Box display="flex" alignItems="center" marginTop="4px">
                            <AccountDetailsSubView
                                user={ticket.event.primary_host}
                                followButton={false}
                                bio={false}
                                size={35}
                            />
                        </Box>
                        <Box display="flex" marginTop="4px">
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    color: 'text.selected',
                                }}
                            >
                                {iso2eventtime(ticketState.event.event_time)}
                            </Typography>
                        </Box>
                        <Box display="flex">
                            <Box
                                sx={{
                                    display: 'flex',
                                    position: 'relative',
                                    height: '31px',
                                    width: '100%',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        position: 'absolute',
                                        left: '-10px',
                                    }}
                                >
                                    <StyledChipHover
                                        component={ButtonBase}
                                        sx={{
                                            alignItems: 'center',
                                        }}
                                    >
                                        <LocalActivityOutlinedIcon
                                            sx={{
                                                color: 'text.primary',
                                                mr: '8px',
                                                fontSize: 16,
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                color: 'text.primary',
                                            }}
                                            noWrap
                                        >
                                            1 Ticket
                                        </Typography>
                                    </StyledChipHover>
                                    <StyledChipHover
                                        component={ButtonBase}
                                        sx={{
                                            alignItems: 'center',
                                        }}
                                        onClick={function (event) {
                                            event.stopPropagation()
                                            navigateToGiftTickets(
                                                navigate,
                                                ticketState,
                                                eventImage,
                                                userimage,
                                                state,
                                                setState
                                            )
                                        }}
                                    >
                                        <CardGiftcardRoundedIcon
                                            sx={{
                                                color: 'text.primary',
                                                mr: '8px',
                                                fontSize: 16,
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                color: 'text.primary',
                                            }}
                                        >
                                            Gift
                                        </Typography>
                                    </StyledChipHover>
                                    <StyledChipHover
                                        component={ButtonBase}
                                        sx={{
                                            alignItems: 'center',
                                        }}
                                    >
                                        <DeleteOutlineRoundedIcon
                                            sx={{
                                                color: 'text.primary',
                                                mr: '8px',
                                                fontSize: 16,
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                color: 'text.primary',
                                            }}
                                        >
                                            Delete
                                        </Typography>
                                    </StyledChipHover>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </EventCard>
    )
}

export default TicketItemView
