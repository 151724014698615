import { useEffect, useState } from 'react'
import {
    Box,
    Typography,
    Tab,
    Tabs,
    Divider,
    List,
    ButtonBase,
} from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'
import { NavigatePostState } from '../../../models/NavigateState'
import { string } from 'yup'
import { DataStatus } from '../../../models/Dataloading'
import { Post } from '../../../models/Post'
import { set } from 'date-fns'
import PostItemCommentView from '../PostItemCommentView'
import {
    PrimaryDivider,
    SecondaryTypography,
    StyledChip,
    StyledCircularProgress,
    TertiaryTypography,
} from '../../Components/StyledComponents'
import { Comment } from '../../../models/Comment'
import CommentView from '../CommentView'
import { styled } from '@mui/material/styles'
import ExpandImagePostView from '../ExpandImagePostView'
import ExpandImagePostSideView from './ExpandImagePostSideView'
import CreateCommentView from '../CreateCommentView'
import SortPopper from '../../Components/SortPopper'
import { StyledArrowForwardIosRoundedIcon } from '../../Components/StyledComponents/StyledIcons'

const ScrollBarDiv = styled('div')(({ theme }) => ({
    '&::-webkit-scrollbar': {
        width: '6px',
    },
    '&::-webkit-scrollbar-track': {
        background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.text.disabled,
        borderRadius: '10px',
        opacity: 0.5,
    },
    '&::-webkit-scrollbar-thumb:hover': {
        opacity: 0.5,
        backgroundColor: theme.palette.text.disabled,
    },
}))

interface ExpandImageSideViewProps {
    post: Post
    postImage: string
    postAuthorImage: string
    comments: Comment[] | null
    setComments: React.Dispatch<React.SetStateAction<Comment[] | null>>
}
const ExpandImageSideView = ({
    post,
    postImage,
    postAuthorImage,
    comments,
    setComments,
}: ExpandImageSideViewProps) => {
    const { uuid } = useParams<{ uuid: string }>()
    const [sortbyShowState, setSortbyShowState] = useState(false)
    const [sortbyState, setSortByState] = useState('Hot')
    const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null)

    const handleSortPopperOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (!sortbyShowState) {
            setSortAnchorEl(event.currentTarget)
            setSortbyShowState(true)
        } else {
            setSortAnchorEl(null)
            setSortbyShowState(false)
        }
    }
    return (
        <ScrollBarDiv
            style={{
                height: '100%',
                width: '100%',
                overflowY: 'auto',
                position: 'relative',
                justifyContent: 'center',
                display: 'flex',
                float: 'left',
            }}
        >
            {' '}
            <Box sx={{ padding: '10px', width: '100%' }}>
                <ExpandImagePostSideView
                    post={post}
                    postAuthorImage={postAuthorImage}
                />
                <PrimaryDivider
                    sx={{ marginTop: '10px', marginBottom: '5px' }}
                />
                <CreateCommentView
                    post={post}
                    comments={comments}
                    setComments={setComments}
                />

                <Box
                    sx={{
                        display: 'flex',
                        mt: '8px',
                        alignItems: 'center',
                        ml: '10px',
                    }}
                >
                    <TertiaryTypography fontSize={13} fontWeight={'bold'}>
                        Sort by:
                    </TertiaryTypography>
                    <SortPopper
                        sortOptions={['Hot', 'Newest', 'Top']}
                        sortbyState={sortbyState}
                        setSortByState={setSortByState}
                        openpopover={sortbyShowState}
                        setOpenPopover={setSortbyShowState}
                        anchorEl={sortAnchorEl}
                        setAnchorEl={setSortAnchorEl}
                    />
                    <StyledChip
                        sx={{
                            ml: '4px',
                            cursor: 'pointer',
                            background: (theme) =>
                                theme.palette.background.tertiary,
                        }}
                        component={ButtonBase}
                        onClick={handleSortPopperOpen}
                    >
                        <SecondaryTypography fontSize={13} fontWeight={'bold'}>
                            {sortbyState}
                        </SecondaryTypography>
                        <StyledArrowForwardIosRoundedIcon
                            sx={{ fontSize: 13, ml: '4px' }}
                            open={sortbyShowState}
                        />
                    </StyledChip>
                </Box>
                {comments !== null &&
                    comments.length > 0 &&
                    comments.map((comment) => (
                        <Box sx={{ padding: '5px 0px' }}>
                            <CommentView post={post} comment={comment} />
                        </Box>
                    ))}
            </Box>
        </ScrollBarDiv>
    )
}

export default ExpandImageSideView
