import { Box, Chip, Typography, styled } from '@mui/material'
import { CreateEventPreviewProps } from './CreateEventPreviewView'

const StyledChip = styled(Chip)(({ theme }) => ({
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.tertiary,
}))

const CreateEventPreviewTagsView = ({ tempEvent }: CreateEventPreviewProps) => {
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'text.tertiary',
                }}
            >
                TAGS
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    gap: 1,
                    marginTop: '8px',
                }}
            >
                {tempEvent.tag_list.map((tag, index) => (
                    <StyledChip key={index} label={`#${tag}`} />
                ))}
            </Box>
        </Box>
    )
}

export default CreateEventPreviewTagsView
