import { Box, Button, ButtonBase, Typography, styled } from '@mui/material'
import { SecondaryDivider, StyledChip } from '../../Components/StyledComponents'
import { useAppState } from '../../../AppStateProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavigateGiftTicketState } from '../../../models/NavigateState'
import { useState } from 'react'
import TicketItemView from '../TicketItemView'
import GiftTicketItemView from './GiftTicketItemView'

const GiftEditContainer = styled(Box)(({ theme }) => ({
    width: '600px',
    height: '100%',
    borderRight: '1px solid ' + theme.palette.border.primary,
}))

const GiftTicketView = () => {
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    const location = useLocation()
    const initialGiftTicketData = location.state as NavigateGiftTicketState
    const [giftTicketData, setGiftTicketData] = useState(
        initialGiftTicketData.ticket
    )

    return (
        <Box
            style={{
                height: '100%',
                width: '100%',
                marginTop: '56px',
            }}
        >
            <GiftEditContainer
                sx={{
                    float: 'left',
                    padding: '16px 16px',
                    height: '100%',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'text.primary',
                        ml: '0px',
                        mt: '8px',
                    }}
                >
                    Gift Tickets
                </Typography>
                <SecondaryDivider
                    sx={{
                        mt: '0px',
                        mb: '16px',
                        ml: '0px',
                        mr: '0px',
                    }}
                />
                <Box>
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: 'text.tertiary',
                        }}
                    >
                        SELECTED GIFTED TICKET
                    </Typography>
                    <StyledChip
                        component={ButtonBase}
                        sx={{
                            cursor: 'pointer',
                            mt: '8px',
                        }}
                    >
                        {giftTicketData ? (
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    ml: '4px',
                                    fontWeight: 'bold',
                                    color: 'text.primary',
                                }}
                            >
                                {`${giftTicketData.event.title}`}
                            </Typography>
                        ) : (
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    ml: '4px',
                                    fontWeight: 'bold',
                                    color: 'text.primary',
                                }}
                            >
                                Set an event location
                            </Typography>
                        )}
                    </StyledChip>
                    <Box mt="8px">
                        <GiftTicketItemView ticket={giftTicketData} />
                    </Box>
                </Box>
                <Typography
                    sx={{
                        fontSize: 12,
                        color: 'text.tertiary',
                    }}
                ></Typography>
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: 'text.tertiary',
                        mt: '16px',
                    }}
                >
                    GIFTED TICKET DETAILS
                </Typography>
                <Typography
                    sx={{
                        fontSize: 14,
                        color: 'text.secondary',
                        mt: '8px',
                    }}
                >
                    Tickets remaining: 1
                </Typography>
            </GiftEditContainer>
        </Box>
    )
}

export default GiftTicketView
