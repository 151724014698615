import { Box, styled } from '@mui/material'
import { User } from '../../models/User'
import {
    PrimaryDivider,
    PrimaryTypography,
    SelectTypography,
} from '../Components/StyledComponents'
import AccountDetailsSubView from '../AccountView/AccountSubViews/AccountDetailsSubView'

const Container = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.border.primary}`,
    backgroundColor: theme.palette.background.secondary,
    padding: 0,
    borderRadius: 10,
    width: '100%',
}))

interface AccountSideRecommendedAccountsViewProps {
    user: User
}

const AccountSideRecommendedAccountsView = ({
    user,
}: AccountSideRecommendedAccountsViewProps) => {
    return (
        <Container sx={{ height: '100%' }}>
            <Box
                sx={{
                    height: '100%',
                    padding: '16px 16px',
                }}
            >
                <PrimaryTypography
                    sx={{
                        fontSize: 16,
                        display: 'flex',
                    }}
                >
                    Similar Accounts
                </PrimaryTypography>
                <PrimaryDivider
                    sx={{
                        margin: '8px 0',
                    }}
                />
                <AccountDetailsSubView
                    user={user}
                    followButton={false}
                    bio={false}
                    size={40}
                />
                <SelectTypography
                    sx={{
                        fontSize: 14,
                        display: 'flex',
                        marginTop: '8px',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                        cursor: 'pointer',
                    }}
                >
                    View More
                </SelectTypography>
            </Box>
        </Container>
    )
}

export default AccountSideRecommendedAccountsView
