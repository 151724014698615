import { Box, ClickAwayListener, IconButton, Typography } from '@mui/material'
import { SelectButton, StyledPopper } from './StyledComponents'
import { useState } from 'react'
import { addDays, format, isBefore } from 'date-fns'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import { Calendar } from './DateRangeCalendar'

interface DateRangePopperCalendarProps {
    value: [Date | null, Date | null]
    setValue: React.Dispatch<React.SetStateAction<[Date | null, Date | null]>>
    openPopper: boolean
    setOpenPopper: React.Dispatch<React.SetStateAction<boolean>>
    anchorEl: HTMLElement | null
}

const DateRangePopperCalendar = ({
    value,
    setValue,
    openPopper,
    setOpenPopper,
    anchorEl,
}: DateRangePopperCalendarProps) => {
    const [currentMonth, setCurrentMonth] = useState(new Date())
    const handleSelectDate = (date: Date) => {
        if (!value[0] || value[1]) {
            setValue([date, null])
        } else if (isBefore(date, value[0])) {
            setValue([date, value[0]])
        } else {
            setValue([value[0], date])
        }
    }
    return (
        <StyledPopper
            sx={{
                padding: '10px 15px',
                zIndex: 10000000000000,
                width: 300,
            }}
            placement="bottom-start"
            open={openPopper}
            anchorEl={anchorEl}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 14,
                        color: 'text.primary',
                    }}
                >
                    {format(currentMonth, 'MMMM yyyy')}
                </Typography>
                <Box
                    sx={{
                        ml: 'auto',
                        display: 'flex',
                    }}
                >
                    <IconButton
                        onClick={() =>
                            setCurrentMonth(addDays(currentMonth, -30))
                        }
                    >
                        <ChevronLeftRoundedIcon
                            sx={{
                                color: 'text.primary',
                            }}
                        />
                    </IconButton>
                    <IconButton
                        onClick={() =>
                            setCurrentMonth(addDays(currentMonth, 30))
                        }
                    >
                        <ChevronRightRoundedIcon
                            sx={{
                                color: 'text.primary',
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>
            <Calendar
                currentMonth={currentMonth}
                selectedDates={value}
                onSelectDate={handleSelectDate}
            />
            <SelectButton
                sx={{
                    mt: '4px',
                }}
                fullWidth
                onClick={() => setOpenPopper(false)}
                disabled={!value[0] || !value[1]}
            >
                Select Dates
            </SelectButton>
        </StyledPopper>
    )
}

export default DateRangePopperCalendar
