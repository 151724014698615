import {
    Box,
    BoxProps,
    Button,
    InputBase,
    TextField,
    Typography,
    styled,
} from '@mui/material'
import { RegisterRequestData } from '../../../api/Auth'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { CreateAccountFormValues } from '../CreateAccountItems/CreateAccountDetailsView'
import {
    OutlineContainer,
    OutlineInputBase,
    SelectButton,
} from '../../Components/StyledComponents'
import { useState } from 'react'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'

interface CreateAccountPasswordMobileViewProps {
    setShowLogin: React.Dispatch<React.SetStateAction<boolean>>
    setShowRegister: React.Dispatch<React.SetStateAction<boolean>>
    registerstate: CreateAccountFormValues
    setRegisterState: React.Dispatch<
        React.SetStateAction<CreateAccountFormValues>
    >
    setRegisterPage: React.Dispatch<React.SetStateAction<number>>
}

const StyledUnderlineTextFields = styled(TextField)(({ theme }) => ({
    '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.text.selected,
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: 'transparent',
    },
}))
const CreateAccountPasswordMobileView = ({
    setShowLogin,
    setShowRegister,
    registerstate,
    setRegisterState,
    setRegisterPage,
}: CreateAccountPasswordMobileViewProps) => {
    const [focused, setFocused] = useState(false)
    const handleFocus = () => {
        setFocused(true)
    }
    const handleBlur = () => {
        setFocused(false)
    }
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    color: 'text.primary',
                }}
            >
                Please enter your password
            </Typography>
            <Typography
                sx={{
                    fontSize: 14,
                    color: 'text.secondary',
                }}
            >
                Your password must be at least 8 characters long
            </Typography>
            <Formik
                initialValues={{
                    password: registerstate.password,
                }}
                initialErrors={
                    {
                        password: 'password is required',
                    } as CreateAccountFormValues
                }
                validate={(values) => {
                    const errors: Partial<CreateAccountFormValues> = {}
                    if (!values.password) {
                        errors.password = 'password is required'
                    }
                    return errors
                }}
                validationSchema={Yup.object().shape({
                    password: Yup.string().min(6).max(255),
                })}
                onSubmit={(values) => {
                    setRegisterState((prev) => ({
                        ...prev,
                        password: values.password,
                    }))
                    setRegisterPage(3)
                }}
            >
                {({
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <OutlineContainer
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mt: '16px',
                            }}
                            focused={focused}
                        >
                            <OutlineInputBase
                                name="password"
                                type="password"
                                fullWidth
                                placeholder="Enter your password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                            />
                            {values.password !== '' && (
                                <CancelRoundedIcon
                                    sx={{
                                        color: 'text.primary',
                                        fontSize: 20,
                                    }}
                                    onClick={() =>
                                        handleChange({
                                            target: {
                                                name: 'password',
                                                value: '',
                                            },
                                        })
                                    }
                                />
                            )}
                        </OutlineContainer>
                        <SelectButton
                            fullWidth
                            type="submit"
                            sx={{
                                mt: '32px',
                                height: '40px',
                                fontSize: 16,
                            }}
                            disabled={!isValid}
                        >
                            Continue
                        </SelectButton>
                        <Box
                            sx={{
                                justifyContent: 'center',
                                display: 'flex',
                                width: '100%',
                            }}
                        >
                            <Typography
                                sx={{
                                    mt: '16px',
                                    fontSize: 14,
                                    color: 'text.tertiary',
                                }}
                            >
                                Already have an account?
                            </Typography>
                            <Typography
                                sx={{
                                    mt: '16px',
                                    ml: '4px',
                                    fontSize: 14,
                                    color: 'text.selected',
                                }}
                                onClick={function () {
                                    setShowRegister(false)
                                    setShowLogin(true)
                                }}
                            >
                                Log in here
                            </Typography>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default CreateAccountPasswordMobileView
