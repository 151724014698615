import {
    Box,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material'
import {
    DefaultButton,
    SelectButton,
    StyledStepConnector,
    StyledStepIcon,
} from '../Components/StyledComponents'
import { CreateEvent } from '../../models/CreateEvent'
import { createEvent } from '../../api/RestEvent'
import { useAppState } from '../../AppStateProvider'

const steps = [
    {
        label: 'Add event details',
        description: `Create an event by selecting a photo, adding a title, and writing a description that will capture your audience's attention. Don't forget to specify the event date, add relevant tags, and choose categories to help users discover your event easily. These details will be displayed on the event page.`,
    },
    {
        label: 'Customize your event settings',
        description: `Customize your event settings by setting the maximum number of attendees and choosing whether the event will be public or private. These settings allow you to control attendance and visibility, even if they aren't displayed on the event page.`,
    },
    {
        label: 'Add hosts (optional)',
        description: `Add hosts to your event by selecting people who follow you. These hosts will have control over some or all event features, helping you manage the event smoothly.`,
    },
    {
        label: 'Invite guests (optional)',
        description: `Invite your followers to the event and specify how many tickets each person will receive, based on the maximum number of attendees. This allows you to control ticket distribution while ensuring everyone gets their spot at the event.t how to tell if
                  they're running and how to resolve approval issues.`,
    },
]

interface CreateEventStepperProps {
    activeStep: number
    setActiveStep: React.Dispatch<React.SetStateAction<number>>
    tempEventData: CreateEvent
    setTempEventData: React.Dispatch<React.SetStateAction<CreateEvent>>
    activeStepIsComplete: boolean[]
}
const CreateEventStepper = ({
    activeStep,
    setActiveStep,
    tempEventData,
    setTempEventData,
    activeStepIsComplete,
}: CreateEventStepperProps) => {
    const { state, setState } = useAppState()
    const handleNext = () => {
        if (activeStep === 3) {
            console.log('Create Event Data:', tempEventData)
            createEvent(tempEventData, state.usertoken)
                .then((response) => {
                    console.log('Response:', response)
                })
                .catch((error) => {
                    console.log('Error:', error)
                })
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
    }
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
    return (
        <Box sx={{ p: '16px' }}>
            <Typography
                sx={{
                    color: 'text.primary',
                    fontSize: 16,
                    fontWeight: 'bold',
                }}
            >
                Create Event
            </Typography>

            <Box
                sx={{
                    mt: '8px',
                }}
            >
                <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    connector={<StyledStepConnector />}
                >
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel StepIconComponent={StyledStepIcon}>
                                <Typography
                                    sx={{
                                        color: 'text.primary',
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {step.label}
                                </Typography>
                            </StepLabel>
                            <StepContent>
                                <Typography
                                    sx={{
                                        color: 'text.secondary',
                                        fontSize: 14,
                                    }}
                                >
                                    {step.description}
                                </Typography>
                                <Box sx={{ mt: '8px' }}>
                                    <DefaultButton
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                    >
                                        Back
                                    </DefaultButton>
                                    <SelectButton
                                        onClick={handleNext}
                                        sx={{
                                            ml: '8px',
                                        }}
                                        disabled={
                                            activeStepIsComplete[activeStep] ===
                                            false
                                        }
                                    >
                                        {index === steps.length - 1
                                            ? 'Finish'
                                            : 'Next'}
                                    </SelectButton>
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        </Box>
    )
}

export default CreateEventStepper
