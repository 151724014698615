import React, { useEffect, useState, useRef } from 'react'
import { Event } from '../../../models/Event'
import { DataStatus } from '../../../models/Dataloading'
import { styled } from '@mui/material/styles'
import { Box, CardContent, CardMedia, Typography } from '@mui/material'
import { grabEventPhoto } from '../../../api/RestEvent'
import { useAppState } from '../../../AppStateProvider'
import { Link, useNavigate } from 'react-router-dom'
import { iso2eventtime } from '../../../utils/DateStringConverter'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import { grabUserPhoto } from '../../../api/RestUser'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded'
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import {
    AttendingBox,
    HostBox,
    SecondaryTypography,
    SelectTypography,
} from '../../Components/StyledComponents'
import AccountAvatarSubView from '../../AccountView/AccountAvatarSubView'
import AccountDetailsSubViewWithFollow from '../../AccountView/AccountSubViews/AccountDetailsSubView'
import AccountDetailsSubView from '../../AccountView/AccountSubViews/AccountDetailsSubView'
import { navigateToEvent } from '../../../utils/NavigationFunctions'
import { Person } from '@mui/icons-material'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'

interface EventSubViewProps {
    event: Event
}

const LoadingBox = styled(CardMedia)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
}))

const EventTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: 16,
}))

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    padding: '10px 0px',
    '&:last-child': {
        paddingBottom: 0,
    },
    zIndex: 10,
}))

const EventCard = styled('div')(({ theme }) => ({
    boxShadow: 'none',
    // backgroundColor: theme.palette.background.primary,
    padding: '16px 16px',
    pb: '8px',
    borderRadius: 0,
    position: 'relative',
    cursor: 'pointer',
}))

const StyledFavoriteBorderRoundedIcon = styled(FavoriteBorderRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const StyledRepeatRoundedIcon = styled(RepeatRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const StyledPersonOutlineRoundedIcon = styled(PersonOutlineRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const DetailsBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    alignItems: 'center',
    paddingBottom: 5,
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderRadius: 50,
}))

const EventSubMobileView = ({ event }: EventSubViewProps) => {
    const navigator = useNavigate()
    const { state, setState } = useAppState()
    const [userimagestatus, setUserImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventimagestatus, setEventImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventimage, setEventImage] = React.useState<string | null>(null)
    const timerRef = useRef<number | null>(null)
    const [openAccountPopover, setOpenAccountPopover] = useState(false)
    const [accountAnchorEl, setAccountAnchorEl] = useState<null | HTMLElement>(
        null
    )
    const handleAccountPopperOpen = (event: any) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        setAccountAnchorEl(event.currentTarget)
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(true)
        }, 400)
    }

    const handleAccountPopperClose = () => {
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(false)
        }, 300)
    }
    const [userimage, setUserImage] = React.useState<string | null>(null)

    useEffect(() => {
        try {
            grabEventPhoto(event.image, state.usertoken)
                .then((response) => {
                    setEventImageStatus(DataStatus.failure)
                    setEventImage(response)
                    setEventImageStatus(DataStatus.success)
                })
                .catch((error) => {
                    console.error(error)
                    setEventImageStatus(DataStatus.failure)
                })
        } catch (error) {
            console.error(error)
            setEventImageStatus(DataStatus.failure)
        }
    }, [])
    useEffect(() => {
        try {
            grabUserPhoto(event.primary_host.image, state.usertoken)
                .then((response) => {
                    setUserImage(response)
                    setUserImageStatus(DataStatus.success)
                })
                .catch((error) => {
                    console.error(error)
                    setUserImageStatus(DataStatus.failure)
                })
        } catch (error) {
            console.error(error)
            setUserImageStatus(DataStatus.failure)
        }
    }, [])
    return (
        <EventCard
            onClick={() =>
                navigateToEvent(
                    event,
                    eventimage,
                    navigator,
                    state,
                    setState,
                    'details'
                )
            }
        >
            {/* {eventimagestatus === DataStatus.success && eventimage && (
            <div
            style={{
                overflow: 'hidden',
                position: 'absolute',
                width: '100%',
                height: '100%',
                borderRadius: '10px',
                zIndex: 0,
                left: 0,
                top: 0,
            }}
            >
            <img
                src={eventimage}
                alt="Event"
                style={{
                    height: '100%',
                    width: '100%',
                    borderRadius: '10px',
                    filter: 'blur(20px)',
                    zIndex: 0,
                    objectFit: 'cover',
                    aspectRatio: '20/9',

                }}
            />
            </div>)} */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    aspectRatio: '20/9',
                    position: 'relative',
                }}
            >
                {eventimagestatus === DataStatus.success && eventimage ? (
                    <img
                        src={eventimage}
                        alt="Event"
                        style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                            borderRadius: '10px',
                        }}
                    />
                ) : eventimagestatus === DataStatus.loading ? (
                    <LoadingBox />
                ) : (
                    <LoadingBox />
                )}
                <DetailsBox>
                    <StyledFavoriteBorderRoundedIcon
                        style={{ marginRight: 5, fontSize: 18 }}
                    />
                    <Typography
                        variant="body1"
                        color="textPrimary"
                        fontSize={14}
                    >
                        {event.favorites_count}
                    </Typography>
                    <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        ml:'20px'
                    }}
                    onClick={function(e){
                        e.stopPropagation()
                        navigateToEvent(
                            event,
                            eventimage,
                            navigator,
                            state,
                            setState,
                            'posts'
                        )

                    }}>
                    <StyledRepeatRoundedIcon
                        style={{ marginRight: 5, fontSize: 18 }}
                    />
                    <Typography color="textPrimary" fontSize={14}>
                        {event.post_count}
                    </Typography>
                    </Box>
                    <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        ml:'20px'
                    }}
                    onClick={function(e){
                        e.stopPropagation()
                        navigateToEvent(
                            event,
                            eventimage,
                            navigator,
                            state,
                            setState,
                            'attendees'
                        )

                    }}>
                    <StyledPersonOutlineRoundedIcon
                        style={{ marginRight: 5, fontSize: 18 }}
                    />
                    <Typography color="textPrimary" fontSize={14}>
                        {event.attendee_count}
                    </Typography>
                    </Box>
                </DetailsBox>
            </div>
            <StyledCardContent>
                <Box>
                    <Box display="flex" alignItems="center">
                        {event.is_host ? (
                            <HostBox
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mb: '4px',
                                    mr: '8px',
                                }}
                            >
                                <SecurityRoundedIcon
                                    sx={{
                                        color: 'text.primary',
                                        fontSize: 12,
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        color: 'text.primary',
                                        ml: '4px',
                                    }}
                                >
                                    Host
                                </Typography>
                            </HostBox>
                        ) : (
                            event.attending && (
                                <AttendingBox
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: '4px',
                                    }}
                                >
                                    <PersonRoundedIcon
                                        sx={{
                                            color: 'text.primary',
                                            fontSize: 14,
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                            color: 'text.primary',
                                            ml: '4px',
                                        }}
                                    >
                                        Attending
                                    </Typography>
                                </AttendingBox>
                            )
                        )}
                    </Box>
                    <EventTitle
                        sx={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            '&:hover': {
                                textDecoration: 'underline',
                            },
                        }}
                    >
                        {event.title}
                    </EventTitle>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ paddingBottom: '5px' }}
                    >
                        <AccountDetailsSubView
                            user={event.primary_host}
                            followButton={false}
                            bio={false}
                            size={35}
                        />
                    </Box>
                    <Box display="flex">
                        {/* <StyledCalendarMonthRoundedIcon
                            style={{ marginRight: 5, fontSize: 18 }}
                        /> */}
                        <SelectTypography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                            }}
                        >
                            {iso2eventtime(event.event_time)}
                        </SelectTypography>
                        {/* <Box width={10} />
                        <StyledLocationOnRoundedIcon
                            style={{ marginRight: 5, fontSize: 18 }}
                        />
                        <Typography
                            variant="body1"
                            color="textPrimary"
                            fontSize={14}
                        >
                            20
                        </Typography>
                        <Box width={10} />
                        <StyledShoppingCartRoundedIcon
                            style={{ marginRight: 5, fontSize: 18 }}
                        />
                        <Typography
                            variant="body1"
                            color="textPrimary"
                            fontSize={14}
                        >
                            Free
                        </Typography> */}
                    </Box>
                </Box>
            </StyledCardContent>
        </EventCard>
    )
}

export default EventSubMobileView
