import { Box, IconButton, styled } from '@mui/material'
import FeedTopBarMobileView from './FeedTopBarMobileView'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { NavigateFeedState } from '../../../models/NavigateState'
import { useAppState } from '../../../AppStateProvider'
import { Post } from '../../../models/Post'
import { DataStatus } from '../../../models/Dataloading'
import { getPosts } from '../../../api/RestPost'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import {
    PrimaryDivider,
    StyledCircularProgress,
} from '../../Components/StyledComponents'
import PostView from '../../PostView/PostView'
import EmptyPostsView from '../../Components/StaticPages/EmptyView/EmptyPostsView'
import PostMobileView from '../../PostView/PostMobileView/PostMobileView'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { use } from 'marked'

const PostIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.selected,
    padding: '16px',
    '&:hover': {
        backgroundColor: theme.palette.background.selected,
    },
}))

const FeedMobileView = () => {
    const [offset, setOffset] = useState(0)
    const location = useLocation()
    const initialpoststateData = location.state as NavigateFeedState | null
    const { state, setState } = useAppState()
    const [postState, setPostState] = useState<Post[]>([])
    const [postloaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)

    // Fetches posts from the server with every offset change
    // useEffect(() => {
    //     document.body.style.overflow = 'auto';
    // },[])
    useEffect(() => {
        if (offset === 0 && initialpoststateData !== null) {
            setPostLoaded(DataStatus.success)
            setPostState(initialpoststateData.poststate)
        } else {
            try {
                getPosts(null, null, null, 15, offset, state.usertoken)
                    .then((response) => {
                        setPostState((prevState) => [
                            ...prevState,
                            ...response.posts,
                        ])
                        setPostLoaded(DataStatus.success)
                    })
                    .catch((error) => {
                        setPostLoaded(DataStatus.failure)
                        console.log(error)
                    })
            } catch (error) {
                setPostLoaded(DataStatus.failure)
                console.log(error)
            }
        }
    }, [offset])
    return (
        <Box>
            <FeedTopBarMobileView />
            <Box
                sx={{
                    mt: '60px',
                    mb: '80px',
                    position: 'relative',
                }}
            >
                {/* <Box
                    sx={{
                        position: 'fixed',
                        top: '0',
                        right: '0',
                        padding: '16px',
                        height: 'calc(100vh -  100px)',
                        zIndex: '100',
                        justifyContent: 'flex-end',
                        flexDirection: 'column',
                        display: 'flex',
                        pointerEvents: 'none',
                    }}
                >
                    <PostIconButton>
                        <AddRoundedIcon />
                    </PostIconButton>
                </Box> */}
                {postloaded === DataStatus.loading ? (
                    <Box
                        sx={{
                            pt: '8px',
                            justifyContent: 'center',
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <StyledCircularProgress />
                    </Box>
                ) : postloaded === DataStatus.failure ? (
                    <ErrorTabView />
                ) : (
                    postloaded === DataStatus.success && (
                        <>
                            {postState.length > 0 ? (
                                <Box>
                                    {postState.map((post) => (
                                        <Box>
                                            <PostMobileView post={post} />
                                            <PrimaryDivider />
                                        </Box>
                                    ))}
                                </Box>
                            ) : (
                                <></>
                            )}
                        </>
                    )
                )}
            </Box>
        </Box>
    )
}

export default FeedMobileView
