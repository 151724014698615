import axios from 'axios'
import { mainUrl } from '../constants'
import { Post } from '../models/Post'
import { ErrorCode } from '../models/ErrorCode'
import { convertBlobToBase64 } from '../utils/Base64ImageEncoding'
import { Comment } from '../models/Comment'
import { GetPostReply } from './RestPost'

export interface GetCommentsReply {
    comments: Comment[]
}

export interface CommentReply {
    comment: Comment
}

export interface CreateCommentRequest {
    comment: {
        body: string
    }
}

export const getComments = async (
    postslug: string,
    userToken: string
): Promise<GetCommentsReply> => {
    try {
        const response = await axios.get<GetCommentsReply>(
            `${mainUrl}posts/${postslug}/comments`,
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const createComment = async (
    body: string,
    postslug: string,
    userToken: string
): Promise<CommentReply> => {
    try {
        const requestData: CreateCommentRequest = {
            comment: {
                body: body,
            },
        }
        const response = await axios.post<CommentReply>(
            `${mainUrl}posts/${postslug}/comments`,
            requestData,
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    'Content-Type': 'application/json',
                },
            }
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const favoriteComment = async (
    postID: string,
    commentID: string,
    userToken: string
): Promise<GetPostReply> => {
    try {
        const response = await axios.post<GetPostReply>(
            `${mainUrl}posts/${postID}/comments/${commentID}/favorite`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const unfavoriteComment = async (
    postID: string,
    commentID: string,
    userToken: string
): Promise<GetPostReply> => {
    try {
        const response = await axios.delete<GetPostReply>(
            `${mainUrl}posts/${postID}/comments/${commentID}/favorite`,
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}
