import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import { styled } from '@mui/material/styles'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

export const FavoritedRoundedIcon = styled(FavoriteRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.favorite,
    })
)

interface StyledArrowForwardIosRoundedIconProps
    extends React.ComponentProps<typeof ArrowForwardIosRoundedIcon> {
    open: boolean
}
export const StyledArrowForwardIosRoundedIcon = styled(
    (props: StyledArrowForwardIosRoundedIconProps) => (
        <ArrowForwardIosRoundedIcon {...props} />
    )
)(({ theme, open }) => ({
    color: theme.palette.text.primary,
    transition: 'transform 0.2s ease-in-out',
    transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
}))

export const StyledCloseRoundedIcon = styled(CloseRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))
