import { useEffect, useState } from 'react'
import { useAppState } from '../../../AppStateProvider'
import { getPosts } from '../../../api/RestPost'
import { Box } from '@mui/material'
import { Post } from '../../../models/Post'
import { DataStatus } from '../../../models/Dataloading'
import { error } from 'console'
import {
    PrimaryDivider,
    StyledCircularProgress,
} from '../../Components/StyledComponents'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import PostMobileView from '../../PostView/PostMobileView/PostMobileView'
import EventPostsTopBarMobileView from './EventPostsTopBarMobileView'
import {
    StyledTab,
    StyledTabs,
    TabBox,
} from '../../Components/StyledComponents/StyledTabView'

interface EventPostsMobileViewProps {
    event_slug: string
}

const EventPostsMobileView = ({ event_slug }: EventPostsMobileViewProps) => {
    const { state } = useAppState()
    const [postState, setPostState] = useState<Post[]>([])
    const [postloaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    const [sortbyShowState, setSortbyShowState] = useState(false)
    const [sortbyState, setSortByState] = useState('Best')
    const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null)
    const [value, setValue] = useState('all')
    const handleSortPopperOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (!sortbyShowState) {
            setSortAnchorEl(event.currentTarget)
            setSortbyShowState(true)
        } else {
            setSortAnchorEl(null)
            setSortbyShowState(false)
        }
    }
    useEffect(() => {
        getPosts(null, null, event_slug, 20, 0, state.usertoken)
            .then((response) => {
                setPostState(response.posts)
                setPostLoaded(DataStatus.success)
            })
            .catch((error) => {
                setPostLoaded(DataStatus.failure)
                console.log(error)
            })
    }, [])
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
    }
    return (
        <Box
            sx={{
                mt: '112px',
                position: 'relative',
            }}
        >
            <TabBox
                sx={{
                    position: 'fixed',
                    top: '56px',
                    width: '100%',
                    zIndex: 2,
                    backgroundColor: 'background.primary',
                }}
            >
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                >
                    <StyledTab label="All" value="all" />
                    <StyledTab label="Media" value="media" />
                    <StyledTab label="Hosts" value="hosts" />
                </StyledTabs>
            </TabBox>
            <EventPostsTopBarMobileView />
            {postloaded === DataStatus.loading ? (
                <Box
                    sx={{
                        pt: '8px',
                        justifyContent: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : postloaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : (
                postloaded === DataStatus.success && (
                    <>
                        {postState.length > 0 ? (
                            <Box>
                                {postState.map((post) => (
                                    <Box>
                                        <PostMobileView post={post} />
                                        <PrimaryDivider />
                                    </Box>
                                ))}
                            </Box>
                        ) : (
                            <></>
                        )}
                    </>
                )
            )}
        </Box>
    )
}

export default EventPostsMobileView
