import { Box, Chip, Typography, styled } from '@mui/material'
import { TertiaryTypography } from '../../Components/StyledComponents'
import { Event } from '../../../models/Event'

interface EventDetailsTagViewProps {
    event: Event
}

const StyledChip = styled(Chip)(({ theme }) => ({
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.tertiary,
}))

const EventDetailsTagView = ({ event }: EventDetailsTagViewProps) => {
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'text.tertiary',
                }}
            >
                TAGS
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    gap: 1,
                    marginTop: '8px',
                }}
            >
                {event.tags.map((tag, index) => (
                    <StyledChip key={index} label={`#${tag}`} />
                ))}
            </Box>
        </Box>
    )
}

export default EventDetailsTagView
