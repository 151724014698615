import React, { useState, useRef, useEffect } from 'react'
import {
    Box,
    Toolbar,
    Drawer,
    ButtonBase,
    Popper,
    ClickAwayListener,
    Button,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import TuneRoundedIcon from '@mui/icons-material/TuneRounded'
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import DatePickerView from './FilterItems/DatePickerView'
import AttendeesPickerView from './FilterItems/AttendeesPickerView'
import RadiusPickerView from './FilterItems/RadiusPickerView'
import EventTypePickerView from './FilterItems/EventTypePickerView'
import FollowersPickerView from './FilterItems/FollowersPickerView'
import CategoriesPickerView from './FilterItems/CategoriesPickerView'
import AllFiltersView from './AllFiltersView'
import { LocationSearch } from '../../models/Location'
import LocationSearchBox from '../Components/LocationSearchBox'
import { FilterData } from '../../models/Filter'
import { FilterDateChoice } from '../../models/Filter'
import { EventType } from '../../models/Filter'
import { alpha } from '@mui/system'

const StyledTundeRoundedIcon = styled(TuneRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

interface StyledKeyboardArrowDownRoundedIconProps
    extends React.ComponentProps<typeof KeyboardArrowDownRoundedIcon> {
    filter: string
    shownfilter: string | null
}

const StyledKeyboardArrowDownRoundedIcon = styled(
    (props: StyledKeyboardArrowDownRoundedIconProps) => (
        <KeyboardArrowDownRoundedIcon {...props} />
    )
)(({ theme, filter, shownfilter }) => ({
    color: theme.palette.text.primary,
    width: 20,
    height: 20,
    transition: 'transform 0.2s ease-in-out',
    transform: shownfilter === filter ? 'rotate(0deg)' : 'rotate(-90deg)',
}))

const StyledPlaceRoundedIcon = styled(PlaceRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.tertiary,
}))

const FilterCapsule = styled(Box)(({ theme }) => ({
    borderRadius: 50,
    backgroundColor: theme.palette.background.tertiary,
    color: theme.palette.text.primary,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '8px 20px',
    fontSize: 14,
    height: 18,
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
}))

const SelectedFilterCapsule = styled(Box)(({ theme }) => ({
    borderRadius: 50,
    backgroundColor: theme.palette.background.selected,
    color: theme.palette.text.primary,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '8px 20px',
    fontSize: 14,
    height: 18,
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
}))

const FilterDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        width: 500,
        boxSizing: 'border-box',
        background: theme.palette.background.secondary,
    },
}))

const StyledPopper = styled(Popper)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    borderRadius: 10,
    boxShadow: '0px 0px 10px 0px ' + 'black',
    zIndex: theme.zIndex.modal + 1,
}))

const ApplyButton = styled(Button)(({ theme }) => ({
    borderRadius: 50,
    backgroundColor: theme.palette.text.selected,
    color: theme.palette.text.primary,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '18px 20px',
    fontSize: 16,
    height: 18,
    width: '100%',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    marginTop: 5,
    '&:hover': {
        backgroundColor: theme.palette.text.selected,
    },
}))

const ApplyButtonDisabled = styled(Button)(({ theme }) => ({
    borderRadius: 50,
    backgroundColor: alpha(theme.palette.text.selected, 0.5),
    color: theme.palette.text.tertiary,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '18px 20px',
    fontSize: 16,
    height: 18,
    width: '100%',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    marginTop: 5,
    '&:hover': {
        backgroundColor: alpha(theme.palette.text.selected, 0.5),
    },
}))

const HorizontalScrollDiv = styled('div')(({ theme }) => ({
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
}))

interface FilterTopViewProps {
    locationsearchstate: LocationSearch | null
    setLocationSearchState: React.Dispatch<
        React.SetStateAction<LocationSearch | null>
    >
    filterstate: FilterData
    setFilterState: React.Dispatch<React.SetStateAction<FilterData>>
}

const FilterTopView = React.memo(
    ({
        locationsearchstate,
        setLocationSearchState,
        filterstate,
        setFilterState,
    }: FilterTopViewProps) => {
        const maxAttendees = 500
        const maxDistance = 500
        const [filterdraweropen, setFilterDrawerOpen] = useState(false)
        const [shownfilter, setShownFilter] = useState<string | null>(null)
        const [tempfilterstate, setTempFilterState] =
            useState<FilterData>(filterstate)
        const [openpopover, setOpenPopover] = useState(false)
        const [anchorEl, setAnchorEl] = useState(null)
        const popperRef = useRef<HTMLDivElement>(null)
        const [popoverContent, setPopoverContent] =
            useState<JSX.Element | null>(null)

        const handlePopoverOpen = (event: any, content: JSX.Element) => {
            setAnchorEl(event.currentTarget)
            setPopoverContent(content)
            setOpenPopover(true)
        }
        const handlePopperClose = () => {
            setShownFilter(null)
            setAnchorEl(null)
            setOpenPopover(false)
        }

        return (
            <HorizontalScrollDiv
                style={{
                    width: '100%',
                    height: 50,
                    alignContent: 'center',
                }}
            >
                <Toolbar
                    style={{
                        minHeight: 30,
                        padding: '0px 10px',
                        width: '100%',
                    }}
                >
                    <LocationSearchBox
                        locationsearchstate={locationsearchstate}
                        setLocationSearchState={setLocationSearchState}
                    />
                    <ButtonBase
                        sx={{ borderRadius: 50 }}
                        onClick={() => setFilterDrawerOpen(true)}
                    >
                        <FilterCapsule
                            sx={{ marginLeft: '5px', marginRight: '5px' }}
                        >
                            <StyledTundeRoundedIcon
                                sx={{ fontSize: 20, marginRight: '5px' }}
                            />
                            Filters
                        </FilterCapsule>
                    </ButtonBase>
                    <ButtonBase
                        sx={{ borderRadius: 50 }}
                        onClick={function (event) {
                            setTempFilterState(filterstate)
                            setShownFilter('date')
                            handlePopoverOpen(
                                event,
                                <DatePickerView
                                    tempfilterstate={tempfilterstate}
                                    setTempFilterState={setTempFilterState}
                                    width={'300px'}
                                />
                            )
                        }}
                    >
                        {filterstate.date.choice === FilterDateChoice.Any ? (
                            <FilterCapsule
                                sx={{ marginLeft: '5px', marginRight: '5px' }}
                            >
                                Date
                                <StyledKeyboardArrowDownRoundedIcon
                                    sx={{ fontSize: 20 }}
                                    filter="date"
                                    shownfilter={shownfilter}
                                />
                            </FilterCapsule>
                        ) : (
                            <SelectedFilterCapsule
                                sx={{ marginLeft: '5px', marginRight: '5px' }}
                            >
                                {filterstate.date.choice !==
                                FilterDateChoice.Custom
                                    ? filterstate.date.choice.toString()
                                    : `${filterstate.date.dates[0].toDateString()} to ${filterstate.date.dates[1].toDateString()}`}
                                <StyledKeyboardArrowDownRoundedIcon
                                    sx={{ fontSize: 20 }}
                                    filter="date"
                                    shownfilter={shownfilter}
                                />
                            </SelectedFilterCapsule>
                        )}
                    </ButtonBase>
                    <ButtonBase
                        sx={{ borderRadius: 50 }}
                        onClick={function (event) {
                            setTempFilterState(filterstate)
                            setShownFilter('range')
                            handlePopoverOpen(
                                event,
                                <RadiusPickerView
                                    filterstate={tempfilterstate}
                                    setFilterState={setTempFilterState}
                                    maxDistance={maxDistance}
                                    width={'300px'}
                                />
                            )
                        }}
                    >
                        {filterstate.range === maxDistance ? (
                            <FilterCapsule
                                sx={{ marginLeft: '5px', marginRight: '5px' }}
                            >
                                Distance
                                <StyledKeyboardArrowDownRoundedIcon
                                    sx={{ fontSize: 20 }}
                                    filter="range"
                                    shownfilter={shownfilter}
                                />
                            </FilterCapsule>
                        ) : (
                            <SelectedFilterCapsule
                                sx={{ marginLeft: '5px', marginRight: '5px' }}
                            >
                                {`${filterstate.range} miles away`}
                                <StyledKeyboardArrowDownRoundedIcon
                                    sx={{ fontSize: 20 }}
                                    filter="range"
                                    shownfilter={shownfilter}
                                />
                            </SelectedFilterCapsule>
                        )}
                    </ButtonBase>
                    <ButtonBase
                        sx={{ borderRadius: 50 }}
                        onClick={function (event) {
                            setTempFilterState(filterstate)
                            setShownFilter('attendeeCount')
                            handlePopoverOpen(
                                event,
                                <AttendeesPickerView
                                    tempfilterstate={tempfilterstate}
                                    setTempFilterState={setTempFilterState}
                                    maxAttendees={maxAttendees}
                                    width={'300px'}
                                />
                            )
                        }}
                    >
                        {filterstate.attendeeCount[0] === 0 &&
                        filterstate.attendeeCount[1] === maxAttendees ? (
                            <FilterCapsule
                                sx={{ marginLeft: '5px', marginRight: '5px' }}
                            >
                                Attendee count
                                <StyledKeyboardArrowDownRoundedIcon
                                    sx={{ fontSize: 20 }}
                                    filter="attendeeCount"
                                    shownfilter={shownfilter}
                                />
                            </FilterCapsule>
                        ) : (
                            <SelectedFilterCapsule
                                sx={{ marginLeft: '5px', marginRight: '5px' }}
                            >
                                {filterstate.attendeeCount[0] > 0 &&
                                filterstate.attendeeCount[1] === maxAttendees
                                    ? `At least ${filterstate.attendeeCount[0]} attendees`
                                    : filterstate.attendeeCount[0] === 0 &&
                                        filterstate.attendeeCount[1] <
                                            maxAttendees
                                      ? `Up to ${filterstate.attendeeCount[1]} attendees`
                                      : `${filterstate.attendeeCount[0]} to ${filterstate.attendeeCount[1]} attendees`}
                                <StyledKeyboardArrowDownRoundedIcon
                                    sx={{ fontSize: 20 }}
                                    filter="attendeeCount"
                                    shownfilter={shownfilter}
                                />
                            </SelectedFilterCapsule>
                        )}
                    </ButtonBase>
                    <ButtonBase
                        sx={{ borderRadius: 50 }}
                        onClick={function (event) {
                            setTempFilterState(filterstate)
                            setShownFilter('premium')
                            handlePopoverOpen(
                                event,
                                <EventTypePickerView
                                    tempfilterstate={tempfilterstate}
                                    setTempFilterState={setTempFilterState}
                                    width="200px"
                                />
                            )
                        }}
                    >
                        {filterstate.premium === EventType.None ? (
                            <FilterCapsule
                                sx={{ marginLeft: '5px', marginRight: '5px' }}
                            >
                                Event type
                                <StyledKeyboardArrowDownRoundedIcon
                                    sx={{ fontSize: 20 }}
                                    filter="premium"
                                    shownfilter={shownfilter}
                                />
                            </FilterCapsule>
                        ) : (
                            <SelectedFilterCapsule
                                sx={{ marginLeft: '5px', marginRight: '5px' }}
                            >
                                {filterstate.premium.toString()}
                                <StyledKeyboardArrowDownRoundedIcon
                                    sx={{ fontSize: 20 }}
                                    filter="premium"
                                    shownfilter={shownfilter}
                                />
                            </SelectedFilterCapsule>
                        )}
                    </ButtonBase>
                    <ButtonBase
                        sx={{ borderRadius: 50 }}
                        onClick={function (event) {
                            setTempFilterState(filterstate)
                            setShownFilter('following')
                            handlePopoverOpen(
                                event,
                                <FollowersPickerView
                                    tempfilterstate={tempfilterstate}
                                    setTempFilterState={setTempFilterState}
                                    width="400px"
                                />
                            )
                        }}
                    >
                        {!filterstate.following ? (
                            <FilterCapsule
                                sx={{ marginLeft: '5px', marginRight: '5px' }}
                            >
                                Following attending
                                <StyledKeyboardArrowDownRoundedIcon
                                    sx={{ fontSize: 20 }}
                                    filter="following"
                                    shownfilter={shownfilter}
                                />
                            </FilterCapsule>
                        ) : (
                            <SelectedFilterCapsule
                                sx={{ marginLeft: '5px', marginRight: '5px' }}
                            >
                                Following
                                <StyledKeyboardArrowDownRoundedIcon
                                    sx={{ fontSize: 20 }}
                                    filter="following"
                                    shownfilter={shownfilter}
                                />
                            </SelectedFilterCapsule>
                        )}
                    </ButtonBase>
                    <ButtonBase
                        sx={{ borderRadius: 50 }}
                        onClick={function (event) {
                            setTempFilterState(filterstate)
                            setShownFilter('categories')
                            handlePopoverOpen(
                                event,
                                <CategoriesPickerView
                                    tempfilterstate={tempfilterstate}
                                    setTempFilterState={setTempFilterState}
                                    width="400px"
                                />
                            )
                        }}
                    >
                        {filterstate.category.length === 0 ? (
                            <FilterCapsule
                                sx={{ marginLeft: '5px', marginRight: '5px' }}
                            >
                                Categories
                                <StyledKeyboardArrowDownRoundedIcon
                                    sx={{ fontSize: 20 }}
                                    filter="categories"
                                    shownfilter={shownfilter}
                                />
                            </FilterCapsule>
                        ) : (
                            <SelectedFilterCapsule
                                sx={{ marginLeft: '5px', marginRight: '5px' }}
                            >
                                {`${filterstate.category[0].toString()} +${filterstate.category.length}`}
                                <StyledKeyboardArrowDownRoundedIcon
                                    sx={{ fontSize: 20 }}
                                    filter="categories"
                                    shownfilter={shownfilter}
                                />
                            </SelectedFilterCapsule>
                        )}
                    </ButtonBase>

                    <StyledPopper
                        sx={{
                            padding: '10px 15px',
                        }}
                        placement="bottom-start"
                        open={openpopover}
                        anchorEl={anchorEl}
                    >
                        <ClickAwayListener
                            onClickAway={function () {
                                setTempFilterState(filterstate)
                                handlePopperClose()
                            }}
                        >
                            <div ref={popperRef}>
                                {popoverContent}
                                {(tempfilterstate.date.choice ===
                                    FilterDateChoice.Custom &&
                                    tempfilterstate.date.dates.length < 2) ||
                                tempfilterstate === filterstate ? (
                                    <ApplyButtonDisabled>
                                        Apply
                                    </ApplyButtonDisabled>
                                ) : (
                                    <ApplyButton
                                        onClick={function () {
                                            setFilterState(tempfilterstate)
                                            window.setTimeout(() => {
                                                handlePopperClose()
                                            }, 10)
                                        }}
                                    >
                                        Apply
                                    </ApplyButton>
                                )}
                            </div>
                        </ClickAwayListener>
                    </StyledPopper>

                    <FilterDrawer
                        open={filterdraweropen}
                        onClose={() => setFilterDrawerOpen(false)}
                        anchor="right"
                    >
                        <AllFiltersView
                            setFilterDrawerOpen={setFilterDrawerOpen}
                            filterstate={filterstate}
                            setFilterState={setFilterState}
                            tempfilterstate={tempfilterstate}
                            setTempFilterState={setTempFilterState}
                            maxDistance={maxDistance}
                            maxAttendees={maxAttendees}
                        />
                    </FilterDrawer>
                </Toolbar>
            </HorizontalScrollDiv>
        )
    }
)

export default FilterTopView
