import React, { useState, useEffect } from 'react'
import { Box, Typography, ClickAwayListener } from '@mui/material'
import {
    APIProvider,
    Map,
    Marker,
    AdvancedMarker,
    InfoWindow,
} from '@vis.gl/react-google-maps'
import { useAppState } from '../../AppStateProvider'
import { stat } from 'fs'
import { Event } from '../../models/Event'
import { styled } from '@mui/system'
import {
    PrimaryTypography,
    SecondaryTypography,
    StyledPopper,
} from '../Components/StyledComponents'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import { Info } from '@mui/icons-material'
import { set } from 'date-fns'
import {
    OverlayView,
    OverlayViewF,
    GoogleMap,
    LoadScript,
    InfoBox,
    InfoBoxF,
} from '@react-google-maps/api'
import MapEventSubView from './MapEventSubView'
import { googleMapsApiKey } from '../../constants'

const StyledPersonOutlineRoundedIcon = styled(PersonRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const StyledFavoriteRoundedIcon = styled(FavoriteRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const mapStyle = [
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'administrative',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'road',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
    },
] as google.maps.MapTypeStyle[]

const EventMarker = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.selected,
    color: theme.palette.text.primary,
    borderRadius: '20px',
    padding: '0px 10px',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
    alignContent: 'center',
    cursor: 'pointer',
}))

interface MapViewProps {
    eventstate: Event[]
}

const center = {
    lat: 43.64,
    lng: -79.41,
}

interface CustomMarkerProps {
    position: google.maps.LatLngLiteral
    eventdata: Event
    setSelectedMarker: React.Dispatch<React.SetStateAction<Event | null>>
}

const CustomMarker = ({
    position,
    eventdata,
    setSelectedMarker,
}: CustomMarkerProps) => {
    return (
        <OverlayViewF
            position={position}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
            <EventMarker onClick={() => setSelectedMarker(eventdata)}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {eventdata.favorited && (
                        <StyledFavoriteRoundedIcon style={{ fontSize: 16 }} />
                    )}

                    <StyledPersonOutlineRoundedIcon style={{ fontSize: 16 }} />
                    <PrimaryTypography fontSize={14}>
                        {eventdata.attendee_count}
                    </PrimaryTypography>
                </Box>
            </EventMarker>
        </OverlayViewF>
    )
}

const MapView = React.memo(({ eventstate }: MapViewProps) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY!
    const [selectedMarker, setSelectedMarker] = React.useState<Event | null>(
        null
    )
    const { state, setState } = useAppState()

    return (
        <LoadScript googleMapsApiKey={googleMapsApiKey}>
            <GoogleMap
                mapContainerStyle={{
                    height: '100%',
                    width: '100%',
                }}
                center={{
                    lat: state.location.latitude
                        ? state.location.latitude
                        : 43.64,
                    lng: state.location.longitude
                        ? state.location.longitude
                        : -79.41,
                }}
                zoom={14}
                options={{
                    disableDefaultUI: true,
                    mapTypeControl: false,
                    mapId: '7a9e2ebecd32a903',
                    clickableIcons: false,
                }}
            >
                {selectedMarker && (
                    <ClickAwayListener
                        onClickAway={() => setSelectedMarker(null)}
                    >
                        <InfoBox
                            position={
                                new google.maps.LatLng(
                                    selectedMarker.location.x,
                                    selectedMarker.location.y
                                )
                            }
                            options={{
                                closeBoxURL: ``,
                                enableEventPropagation: true,
                                pixelOffset: new google.maps.Size(-150, 30),
                            }}
                        >
                            <MapEventSubView event={selectedMarker} />
                        </InfoBox>
                    </ClickAwayListener>
                )}
                <Marker
                    position={{
                        lat: state.location.latitude
                            ? state.location.latitude
                            : 43.64,
                        lng: state.location.longitude
                            ? state.location.longitude
                            : -79.41,
                    }}
                />
                {eventstate.map((eventdata) => (
                    // <Marker
                    //     key={event.slug}
                    //     position={{
                    //         lat: event.location.x,
                    //         lng: event.location.y,
                    //     }}
                    // />
                    <CustomMarker
                        eventdata={eventdata}
                        position={{
                            lat: eventdata.location.x,
                            lng: eventdata.location.y,
                        }}
                        setSelectedMarker={setSelectedMarker}
                    />
                ))}
            </GoogleMap>
        </LoadScript>
    )
})
// const MapView = React.memo(({ eventstate }: MapViewProps) => {
//     const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY!
//     const [selectedMarker, setSelectedMarker] = React.useState<Event | null>(
//         null
//     )
//     const { state, setState } = useAppState()

//     return (
//         <APIProvider apiKey={'AIzaSyCTm7sXwQNKtccUyry0RZty7qQFiFyCIZw'}>
//             <Map
//                 defaultCenter={{ lat: 43.64, lng: -79.41 }}
//                 defaultZoom={10}
//                 gestureHandling={'greedy'}
//                 disableDefaultUI
//                 clickableIcons={false}
//                 mapTypeId={'roadmap'}
//                 mapId={'7a9e2ebecd32a903'}
//                 viewState={{
//                     latitude: state.location.latitude
//                         ? state.location.latitude
//                         : 43.64,
//                     longitude: state.location.longitude
//                         ? state.location.longitude
//                         : -79.41,
//                     zoom: 11,
//                 }}
//             >
//                 {selectedMarker && (
//                 <AdvancedMarker
//                     position={{
//                         lat: selectedMarker.location.x,
//                         lng: selectedMarker.location.y,
//                     }}

//                 >
//                     <div
//                     style={{paddingTop: `200px`,

//                     }}
//                     >
//                     <div
//                         style={{
//                             backgroundColor: `red`,
//                             opacity: 1,
//                             padding: `12px`,
//                             height: `100px`,
//                             width: `200px`,
//                         }}
//                     >
//                         <div style={{ fontSize: `16px` }}>
//                             Hello, World!
//                         </div>
//                     </div>
//                     </div>
//                 </AdvancedMarker>
//                 )}

//                 <Marker
//                     position={{
//                         lat: state.location.latitude
//                             ? state.location.latitude
//                             : 43.64,
//                         lng: state.location.longitude
//                             ? state.location.longitude
//                             : -79.41,
//                     }}
//                 />
//                 {eventstate.map((eventdata) => (
//                     // <Marker
//                     //     key={event.slug}
//                     //     position={{
//                     //         lat: event.location.x,
//                     //         lng: event.location.y,
//                     //     }}
//                     // />
//                     <AdvancedMarker
//                         key={eventdata.slug}
//                         position={{
//                             lat: eventdata.location.x,
//                             lng: eventdata.location.y,
//                         }}
//                         onClick={function(){ setSelectedMarker(eventdata)
//                             console.log(selectedMarker)
//                         }}
//                     >
//                         <EventMarker>
//                             <Box
//                                 display="flex"
//                                 justifyContent="space-between"
//                                 alignItems="center"
//                             >
//                                 {eventdata.favorited && (
//                                     <StyledFavoriteRoundedIcon
//                                         style={{ fontSize: 16 }}
//                                     />
//                                 )}

//                                 <StyledPersonOutlineRoundedIcon
//                                     style={{ fontSize: 16 }}
//                                 />
//                                 <PrimaryTypography fontSize={14}>
//                                     {eventdata.attendee_count}
//                                 </PrimaryTypography>
//                             </Box>
//                         </EventMarker>
//                     </AdvancedMarker>
//                 ))}
//             </Map>
//         </APIProvider>
//     )
// })

export default MapView
