import React, { useState, useRef } from 'react'
import { styled } from '@mui/material/styles'

import { Box, Container, IconButton, CircularProgress } from '@mui/material'
import { RegisterRequest, RegisterRequestData } from '../../api/Auth'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import CreateAccountDetailsView from './CreateAccountItems/CreateAccountDetailsView'
import CreateAccountPictureView from './CreateAccountItems/CreateAccountPictureView'
import { useAppState } from '../../AppStateProvider'
import CreateAccountMobileView from './CreatAccountMobileView'

const DivRoot = styled('div')(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(10px)',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    position: 'fixed',
}))

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    padding: '20px 50px',
    borderRadius: 10,
}))

const StyledClearRoundedIcon = styled(ClearRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const StyledChevronLeftRoundedIcon = styled(ChevronLeftRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.text.selected,
}))

interface CreateAccountViewProps {
    setShowLogin: React.Dispatch<React.SetStateAction<boolean>>
    setShowRegister: React.Dispatch<React.SetStateAction<boolean>>
}

const CreateAccountView = ({
    setShowLogin,
    setShowRegister,
}: CreateAccountViewProps) => {
    const { state, setState } = useAppState()
    return (
        <>
            {state.isMobile ? (
                <CreateAccountMobileView
                    setShowLogin={setShowLogin}
                    setShowRegister={setShowRegister}
                />
            ) : (
                <CreateAccountDesktopView
                    setShowLogin={setShowLogin}
                    setShowRegister={setShowRegister}
                />
            )}
        </>
    )
}
const CreateAccountDesktopView = ({
    setShowLogin,
    setShowRegister,
}: CreateAccountViewProps) => {
    const viewRef = useRef<HTMLDivElement>(null)
    const [registerpage, setRegisterPage] = useState<number>(0)
    const [authenticating, setAuthenticating] = useState(false)
    const [registerstate, setRegisterState] = useState<RegisterRequestData>({
        user: {
            email: '',
            password: '',
            username: '',
            name: '',
            birth_date: '',
        },
    })

    return (
        <DivRoot
            onMouseDown={function (event) {
                if (event.target === viewRef.current) {
                    setShowRegister(false)
                }
            }}
        >
            <Box
                ref={viewRef}
                display="flex"
                width="100%"
                height="100%"
                justifyContent="center"
                alignContent={'center'}
                alignItems={'center'}
            >
                <StyledContainer maxWidth="sm">
                    <div
                        style={{
                            visibility: authenticating ? 'hidden' : 'visible',
                            position: 'relative',
                        }}
                    >
                        <Box
                            sx={{ justifyContent: 'center' }}
                            width="100%"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Box
                                display="flex"
                                position="relative"
                                width="100%"
                            >
                                <Box sx={{ flexGrow: 1 }} />
                                {registerpage !== 0 && (
                                    <IconButton
                                        onClick={() =>
                                            setRegisterPage((prev) => prev - 1)
                                        }
                                        sx={{
                                            width: 35,
                                            height: 35,
                                            fontSize: 10,
                                            left: 0,
                                            position: 'absolute',
                                        }}
                                    >
                                        <StyledChevronLeftRoundedIcon
                                            sx={{
                                                fontSize: 10,
                                                height: 25,
                                                width: 25,
                                            }}
                                        />
                                    </IconButton>
                                )}

                                <img
                                    src={`${process.env.PUBLIC_URL}/logotest03long.png`}
                                    height={30}
                                />
                                <Box sx={{ flexGrow: 1 }} />
                                <IconButton
                                    onClick={() => setShowRegister(false)}
                                    sx={{
                                        width: 35,
                                        height: 35,
                                        fontSize: 10,
                                        right: 0,
                                        position: 'absolute',
                                    }}
                                >
                                    <StyledClearRoundedIcon
                                        sx={{
                                            fontSize: 10,
                                            height: 25,
                                            width: 25,
                                        }}
                                    />
                                </IconButton>
                            </Box>
                        </Box>
                        {registerpage === 0 ? (
                            <CreateAccountDetailsView
                                setShowLogin={setShowLogin}
                                setShowRegister={setShowRegister}
                                setRegisterState={setRegisterState}
                                setRegisterPage={setRegisterPage}
                            />
                        ) : (
                            <CreateAccountPictureView
                                registerstate={registerstate}
                                setRegisterState={setRegisterState}
                                setRegisterPage={setRegisterPage}
                                setAuthenticating={setAuthenticating}
                            />
                        )}
                    </div>
                    {authenticating && (
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                display: 'flex',
                                margin: 'auto',
                                left: 0,
                                top: 0,
                            }}
                        >
                            <StyledCircularProgress />
                        </Box>
                    )}
                </StyledContainer>
            </Box>
        </DivRoot>
    )
}

export default CreateAccountView
