import { styled } from '@mui/material'
import { UserPhotoCircle } from '../../StyledComponents'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'

const StyledPersonRoundedIcon = styled(PersonRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.disabled,
}))

interface ErrorAccountAvatarViewProps {
    size: number
    includeBorder: boolean
}

const ErrorAccountAvatarView = ({
    size,
    includeBorder,
}: ErrorAccountAvatarViewProps) => {
    return (
        <UserPhotoCircle
            includeBorder={includeBorder}
            sx={{
                width: size,
                height: size,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
            }}
        >
            <StyledPersonRoundedIcon
                sx={{
                    width: '75%',
                    height: '75%',
                }}
            />
        </UserPhotoCircle>
    )
}

export default ErrorAccountAvatarView
