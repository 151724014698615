import axios from 'axios'
import { mainUrl } from '../constants'
import { ErrorCode } from '../models/ErrorCode'

export interface LoginRequest {
    email: string
    password: string
}

export interface RegisterRequest {
    email: string
    password: string
    username: string
    name: string
    birth_date: string
    twitter?: string
    instagram?: string
}

export interface RegisterRequestData {
    user: RegisterRequest
}

export interface RegisterResponse {
    email: string
    token: string
    username: string
    name: string
    bio?: string
    image: string
    birth_date: string
    follower_size: number
    following_size: number
    twitter?: string
    instagram?: string
}

export interface LoginResponse {
    email: string
    token: string
    username: string
    name: string
    bio?: string
    image: string
    birth_date: string
    follower_size: number
    following_size: number
    twitter?: string
    instagram?: string
}

export interface LoginRequestData {
    user: LoginRequest
}

export interface LoginResponseData {
    user: LoginResponse
}

export const login = async (
    requestData: LoginRequestData
): Promise<LoginResponseData> => {
    const url = `${mainUrl}users/login`

    try {
        const response = await axios.post<LoginResponseData>(url, requestData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        console.log(response.data)
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        }
        throw new Error('An unexpected error occurred')
    }
}

export const register = async (
    requestData: RegisterRequestData
): Promise<RegisterResponse> => {
    const url = `${mainUrl}users`

    try {
        const response = await axios.post<RegisterResponse>(url, requestData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })

        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        }
        throw new Error('An unexpected error occurred')
    }
}
