import {
    AppBar,
    Box,
    Button,
    ButtonBase,
    IconButton,
    styled,
    Toolbar,
    Typography,
} from '@mui/material'
import { hexToRGBA } from '../../utils/haxToRGBA'
import { useAppState } from '../../AppStateProvider'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined'
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { useEffect, useState } from 'react'
import {
    navigateTickets,
    navigateToAccount,
    navigateToDiscover,
    navigateToFeed,
} from '../../utils/NavigationFunctions'
import { useNavigate } from 'react-router-dom'

export const navMobileBarHeight = 70
const iconSize = 26
const NavBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    borderTop: '1px solid ' + theme.palette.border.secondary,
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `calc(100%)`,
    backdropFilter: 'blur(10px)',
    zIndex: theme.zIndex.drawer + 2,
    bottom: 0,
    top: 'auto',
    alignContent: 'top',
}))

const AddButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.background.primary,
    backgroundColor: theme.palette.text.primary,
    padding: '2px 8px',
    borderRadius: '10px',
    '&:hover': {
        backgroundColor: theme.palette.text.primary,
    },
}))
const items = [
    {
        href: '/app/feed/fyp',
        icon: <HomeOutlinedIcon style={{ fontSize: iconSize }} />,
        name: 'Home',
        nav: navigateToFeed,
    },
    {
        href: '/app/discover/search',
        icon: <PublicRoundedIcon style={{ fontSize: iconSize }} />,
        name: 'Discover',
        nav: navigateToDiscover,
    },
    {
        href: '/app/tickets',
        icon: <LocalActivityOutlinedIcon style={{ fontSize: iconSize }} />,
        name: 'Tickets',
        nav: navigateTickets,
    },
    {
        href: '/app/tickets',
        icon: <LocalActivityOutlinedIcon style={{ fontSize: iconSize }} />,
        name: 'Tickets',
        nav: navigateTickets,
    },
    {
        href: '/app/account',
        icon: <PersonOutlineRoundedIcon style={{ fontSize: iconSize }} />,
        name: 'Account',
        navigateToAccount,
    },
]

interface RoutesDict {
    [key: string]: number
}
const routes: RoutesDict = {
    '/app/feed/fyp': 0,
    '/app/discover/search': 1,
    '/app/tickets': 2,
    '/app/notification': 3,
}

interface NavMobileBarProps {
    showCreateMobileView: boolean
    setShowCreateMobileView: React.Dispatch<React.SetStateAction<boolean>>
}
const NavMobileBar = ({
    showCreateMobileView,
    setShowCreateMobileView,
}: NavMobileBarProps) => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const [value, setValue] = useState(-1)

    const getSelected = () => {
        const href = window.location.pathname
        console.log(href)
        for (const route in routes) {
            if (href.includes(route)) {
                return routes[route]
            }
        }
        if (href.includes(`/app/account/${state.username}`)) {
            return 3
        }
        return -1
    }

    useEffect(() => {
        setValue(getSelected())
    }, [window.location.pathname])
    // const handleNavigate = (index: number) => {
    //     if (index === 0) {
    //         navigateToFeed(navigate, state, setState, '/app/feed/fyp')
    //     } else if (index === 1) {
    //         navigateToDiscover(
    //             navigate,
    //             state,
    //             setState,
    //             '/app/discover/search'
    //         )
    //     } else if (index === 2) {
    //         navigateTickets(navigate, state, setState, 'active')
    //     } else if (index === 4) {
    //         navigateToAccount(
    //             state.username,
    //             null,
    //             null,
    //             null,
    //             navigate,
    //             state,
    //             setState,
    //             'attending'
    //         )
    //     }
    // }
    return (
        <NavBar>
            <Toolbar
                sx={{
                    minHeight: navMobileBarHeight,
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    justifyContent: 'space-around',
                }}
            >
                {/* {items.map((item, index) => (
                    <Box
                        key={index}
                        component={ButtonBase}
                        sx={{
                            color:
                                value === index
                                    ? 'text.primary'
                                    : 'text.tertiary',
                            justifyContent: 'center',
                        }}
                        onClick={() => handleNavigate(index)}
                    >
                        <Box>
                            {item.icon}
                            <Typography sx={{ fontSize: 12, mt:0 }}>
                                {item.name}
                            </Typography>
                        </Box>
                    </Box>
                ))}
                 */}
                <Box
                    component={ButtonBase}
                    disableRipple
                    sx={{
                        color: value === 0 ? 'text.primary' : 'text.tertiary',
                        justifyContent: 'center',
                        mb: 'auto',
                        mt: '4px'
                    }}
                    onClick={function () {
                        setShowCreateMobileView(false)
                        navigateToFeed(
                            navigate,
                            state,
                            setState,
                            '/app/feed/fyp'
                        )
                    }}
                >
                    <Box
                        sx={{
                            p: '0px 8px',
                        }}
                    >
                        <HomeOutlinedIcon style={{ fontSize: iconSize }} />
                        <Typography sx={{ fontSize: 10, mt: 0 }}>
                            Home
                        </Typography>
                    </Box>
                </Box>
                <Box
                    component={ButtonBase}
                    sx={{
                        color: value === 1 ? 'text.primary' : 'text.tertiary',
                        justifyContent: 'center',
                        mb: 'auto',
                        mt: '4px'
                    }}
                    disableRipple
                    onClick={function () {
                        setShowCreateMobileView(false)
                        navigateToDiscover(
                            navigate,
                            state,
                            setState,
                            '/app/discover/search'
                        )
                    }}
                >
                    <Box
                        sx={{
                            p: '0px 8px',
                        }}
                    >
                        <PublicRoundedIcon style={{ fontSize: iconSize }} />
                        <Typography sx={{ fontSize: 10, mt: 0 }}>
                            Discover
                        </Typography>
                    </Box>
                </Box>
                <Box
                    component={ButtonBase}
                    disableRipple
                    sx={{
                        alignContent: 'top',
                        height: '100%',
                        display: 'flex',
                        mb: 'auto',
                        mt: '6px'
                    }}
                    onClick={() =>
                        setShowCreateMobileView(!showCreateMobileView)
                    }
                >
                    <Box
                        sx={{
                            p: '0px 8px',
                        }}
                    >
                        <AddButton>
                            <AddRoundedIcon
                                sx={{ fontSize: 22, fontWeight: 'bold' }}
                            />
                        </AddButton>
                        <Typography
                            sx={{
                                fontSize: 10,
                                mt: '4px',
                                color: 'text.primary',
                            }}
                        >
                            Create
                        </Typography>
                    </Box>
                </Box>
                <Box
                    component={ButtonBase}
                    disableRipple
                    sx={{
                        color: value === 2 ? 'text.primary' : 'text.tertiary',
                        justifyContent: 'center',
                                                mb: 'auto',
                        mt: '4px'
                    }}
                    onClick={function () {
                        setShowCreateMobileView(false)
                        navigateTickets(navigate, state, setState, 'active')
                    }}
                >
                    <Box
                        sx={{
                            p: '0px 8px',
                        }}
                    >
                        <LocalActivityOutlinedIcon
                            style={{ fontSize: iconSize }}
                        />
                        <Typography sx={{ fontSize: 10, mt: 0 }}>
                            Tickets
                        </Typography>
                    </Box>
                </Box>
                <Box
                    component={ButtonBase}
                    disableRipple
                    sx={{
                        color: value === 3 ? 'text.primary' : 'text.tertiary',
                        justifyContent: 'center',
                                                mb: 'auto',
                        mt: '4px'
                    }}
                    onClick={function () {
                        setShowCreateMobileView(false)
                        navigateToAccount(
                            state.username,
                            null,
                            null,
                            null,
                            navigate,
                            state,
                            setState,
                            'attending'
                        )
                    }}
                >
                    <Box
                        sx={{
                            p: '0px 8px',
                        }}
                    >
                        <PersonOutlineRoundedIcon
                            style={{ fontSize: iconSize }}
                        />
                        <Typography sx={{ fontSize: 10, mt: 0 }}>
                            Account
                        </Typography>
                    </Box>
                </Box>
            </Toolbar>
        </NavBar>
    )
}

export default NavMobileBar
