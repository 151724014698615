import axios from 'axios'
import { googleMapsApiKey } from '../constants'

export const getGoogleMapsAutocomplete = async (input: string) => {
    const autocompleteurl = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&key=${googleMapsApiKey}`
    console.log(autocompleteurl)
    try {
        const autocompleteResponse = await axios.get(autocompleteurl)
        const autocompleteData = autocompleteResponse.data
        console.log(autocompleteData)
        const mapdatarequest = autocompleteData.predictions.map(
            async (prediction: any) => {
                try {
                    const placeurl = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${prediction.place_id}&fields=geometry&key=${googleMapsApiKey}`
                    const placedata = await axios.get(placeurl)
                    console.log(placedata.data)
                    const placedatajson = placedata.data
                    return {
                        description: prediction.description,
                        maintext: prediction.structured_formatting.main_text,
                        secondarytext:
                            prediction.structured_formatting.secondary_text,
                        place_id: prediction.place_id,
                        location: {
                            lat: placedatajson.result.geometry.location.lat,
                            lng: placedatajson.result.geometry.location.lng,
                        },
                    }
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        throw new Error(
                            error.response?.data.message || 'An error occurred'
                        )
                    } else {
                        throw new Error('An unexpected error occurred')
                    }
                }
            }
        )
        return await Promise.all(mapdatarequest)
    } catch (error) {
        console.log(error)
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data.message || 'An error occurred')
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}
