import { DataStatus } from '../../models/Dataloading'
import { Box, Card, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { User } from '../../models/User'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { useEffect, useState, useRef } from 'react'
import { grabUserPhoto } from '../../api/RestUser'
import { useAppState } from '../../AppStateProvider'
import {
    PrimaryTypography,
    SecondaryTypography,
} from '../Components/StyledComponents'
import AccountAvatarSubView from './AccountAvatarSubView'
import AccountPopoverView from './AccountPopoverView'
import { navigateToAccount } from '../../utils/NavigationFunctions'
import { useNavigate } from 'react-router-dom'

const UserPhotoCircle = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
}))

const FollowIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.background.primary,
    backgroundColor: theme.palette.text.primary,
    border: '2px solid ' + theme.palette.background.primary,
    '&:hover': {
        backgroundColor: theme.palette.text.primary,
    },
}))

const FollowingIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.text.selected,
    border: '2px solid ' + theme.palette.background.primary,
    '&:hover': {
        backgroundColor: theme.palette.text.selected,
    },
}))

const AccountContainer = styled(Box)(({ theme }) => ({
    borderRadius: '10px',
    '&:hover': {
        backgroundColor: theme.palette.background.hover,
    },
    cursor: 'pointer',
}))

interface AccountAttendeeSubViewProps {
    user: User
}
const AccountAttendeeSubView = ({ user }: AccountAttendeeSubViewProps) => {
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    const [userAuthorImage, setUserAuthorImage] = useState<string | null>(null)
    const [userState, setUserState] = useState<User>(user)
    const [userImageLoaded, setUserImageLoaded] = useState<DataStatus>(
        DataStatus.loading
    )

    const timerRef = useRef<number | null>(null)
    const [openAccountPopover, setOpenAccountPopover] = useState(false)
    const [accountAnchorEl, setAccountAnchorEl] = useState<null | HTMLElement>(
        null
    )

    const handleAccountPopperOpen = (event: any) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        setAccountAnchorEl(event.currentTarget)
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(true)
        }, 400)
    }

    const handleAccountPopperClose = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(false)
        }, 500)
    }

    useEffect(() => {
        try {
            grabUserPhoto(user.image, state.usertoken)
                .then((response) => {
                    setUserAuthorImage(response)
                    setUserImageLoaded(DataStatus.success)
                })
                .catch((error) => {
                    console.log(error)
                    setUserImageLoaded(DataStatus.failure)
                })
        } catch (error) {
            console.log(error)
            setUserImageLoaded(DataStatus.failure)
        }
    }, [])

    return (
        <AccountContainer style={{ padding: '8px' }}>
            <Box
                sx={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '5px',
                    display: 'flex',
                }}
            >
                <Box sx={{ marginLeft: '8px' }}>
                    <AccountAvatarSubView
                        user={user}
                        userimage={userAuthorImage}
                        userimagestatus={userImageLoaded}
                        handleAccountPopperClose={handleAccountPopperClose}
                        handleAccountPopperOpen={handleAccountPopperOpen}
                        following={userState.following}
                        height={50}
                        width={50}
                    />
                </Box>
            </Box>
            <PrimaryTypography
                noWrap
                sx={{
                    fontSize: 14,
                    textAlign: 'center',
                    marginTop: '5px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                }}
                onMouseOver={(event) => handleAccountPopperOpen(event)}
                onMouseLeave={handleAccountPopperClose}
                onClick={() =>
                    navigateToAccount(
                        userState.username,
                        userState,
                        userAuthorImage,
                        null,
                        navigate,
                        state,
                        setState,
                        'attending'
                    )
                }
            >
                {userState.name}
            </PrimaryTypography>
            <SecondaryTypography style={{ fontSize: 14, textAlign: 'center' }}>
                @{userState.username}
            </SecondaryTypography>
            <AccountPopoverView
                user={userState}
                setUser={setUserState}
                openpopover={openAccountPopover}
                setOpenPopover={setOpenAccountPopover}
                anchorEl={accountAnchorEl}
                setAnchorEl={setAccountAnchorEl}
                timerRef={timerRef}
            />
        </AccountContainer>
    )
}

export default AccountAttendeeSubView
