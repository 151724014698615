import { Box, Tab, Tabs, styled } from '@mui/material'

export const TabBox = styled(Box)(({ theme }) => ({
    borderBottom: '1px solid ' + theme.palette.border.secondary,
}))

export const StyledTabs = styled(Tabs)(({ theme }) => ({
    minHeight: 45,
    height: 45,
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.background.selected,
        color: theme.palette.text.selected,
        height: 4,
        borderRadius: 20,
    },
    '&.MuiTab-root': {
        justifyContent: 'baseline',
        minHeight: 45,
        height: 45,
    },
}))

export const StyledTab = styled(Tab)(({ theme }) => ({
    fontSize: 14,
    textTransform: 'none',
    color: theme.palette.text.tertiary,
    cornerRadius: '20 20 0 0',
    minHeight: 45,
    height: 45,
    '&:hover': {
        color: theme.palette.text.primary,
        cornerRadius: '20 20 0 0',
        backgroundColor: theme.palette.background.hover,
        borderRadius: '10px 10px 10px 10px',
    },
    justifyContent: 'initial',
    '&.MuiTab-root': {
        justifyContent: 'baseline',
        minHeight: 40,
        height: 40,
    },
    '&.Mui-selected': {
        color: theme.palette.text.primary,
    },
}))
