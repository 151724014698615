import { Box, ButtonBase } from '@mui/material'
import { CreateEvent } from '../../../models/CreateEvent'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import {
    PrimaryTypography,
    StyledChip,
} from '../../Components/StyledComponents'
import exp from 'constants'
import { googleMapsApiKey } from '../../../constants'
import { useNavigate } from 'react-router-dom'
import { stat } from 'fs'

interface CreateEventLocationViewProps {
    tempEventData: CreateEvent
    setTempEventData: React.Dispatch<React.SetStateAction<CreateEvent>>
}
const CreateEventLocationView = ({
    tempEventData,
    setTempEventData,
}: CreateEventLocationViewProps) => {
    const navigate = useNavigate()
    const zoom = 11
    const height = 300
    return (
        <Box
            sx={{
                marginBottom: '16px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '8px',
                }}
            >
                <StyledChip
                    component={ButtonBase}
                    sx={{
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        navigate('/app/createevent/location', {
                            state: { tempEventData: tempEventData },
                        })
                    }}
                >
                    <LocationOnRoundedIcon
                        sx={{
                            fontSize: 18,
                            color: (theme) => theme.palette.text.primary,
                        }}
                    />
                    {tempEventData.locationsearch ? (
                        <Box>
                            <PrimaryTypography
                                sx={{
                                    fontSize: 14,
                                    ml: '4px',
                                    fontWeight: 'bold',
                                }}
                            >
                                {`${tempEventData.locationsearch.maintext}`}
                            </PrimaryTypography>
                        </Box>
                    ) : (
                        <PrimaryTypography
                            sx={{ fontSize: 14, ml: '4px', fontWeight: 'bold' }}
                        >
                            Set an event location
                        </PrimaryTypography>
                    )}
                </StyledChip>
            </Box>
            {/* {tempEventData.location && (
                <img
                src={`https://maps.googleapis.com/maps/api/staticmap?center=${tempEventData.location.x},${tempEventData.location.y}&zoom=${zoom}&size=${2000}x${height}&maptype=roadmap&markers=color:red%7C${tempEventData.location.x},${tempEventData.location.y}&map_id=7a9e2ebecd32a903&key=${googleMapsApiKey}`}
                style={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: '10px',
                    marginTop: '8px',
                }}
            />
                )} */}
        </Box>
    )
}

export default CreateEventLocationView
