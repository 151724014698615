import { DataStatus } from '../../models/Dataloading'
import { Box, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { User } from '../../models/User'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { navigateToAccount } from '../../utils/NavigationFunctions'
import { useNavigate } from 'react-router-dom'
import { useAppState } from '../../AppStateProvider'
import React, { useState } from 'react'
import { followUser, unfollowUser } from '../../api/RestUser'
import ErrorAccountAvatarView from '../Components/StaticPages/ErrorView/ErrorAccountAvatarView'

const UserPhotoCircle = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
}))

const FollowIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.background.primary,
    backgroundColor: theme.palette.text.primary,
    border: '2px solid ' + theme.palette.background.primary,
    '&:hover': {
        backgroundColor: theme.palette.text.primary,
    },
}))

const FollowingIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.selected,
    border: '2px solid ' + theme.palette.background.primary,
    '&:hover': {
        backgroundColor: theme.palette.background.selected,
    },
}))

interface AccountAvatarSubViewProps {
    user: User
    userimage: string | null
    userimagestatus: DataStatus
    handleAccountPopperClose: () => void
    handleAccountPopperOpen: (event: any) => void
    following: boolean
    height: number
    width: number
    showFollowButton?: boolean
}
const AccountAvatarSubView = ({
    user,
    userimage,
    userimagestatus,
    handleAccountPopperClose,
    handleAccountPopperOpen,
    following,
    height,
    width,
    showFollowButton = true,
}: AccountAvatarSubViewProps) => {
    const navigator = useNavigate()
    const { state, setState } = useAppState()
    const [userState, setUserState] = useState<User>(user)
    const handleFollow = (event: React.MouseEvent) => {
        event.stopPropagation()
        setUserState({
            ...userState,
            follower_size: userState.follower_size + 1,
            following: true,
        })
        try {
            followUser(userState.username, state.usertoken)
                .then((response) => {
                    return
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleUnfollow = (event: React.MouseEvent) => {
        event.stopPropagation()
        setUserState({
            ...userState,
            follower_size: userState.follower_size - 1,
            following: false,
        })
        try {
            unfollowUser(userState.username, state.usertoken)
                .then((response) => {
                    return
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            {userimagestatus === DataStatus.success && userimage ? (
                <Box position={'relative'}>
                    <UserPhotoCircle
                        sx={{
                            width: width,
                            height: height,
                            borderRadius: '50%',
                            marginRight: 1,
                            overflow: 'hidden',
                            position: 'relative',
                            cursor: 'pointer',
                        }}
                        onMouseOver={(event) => handleAccountPopperOpen(event)}
                        onMouseLeave={handleAccountPopperClose}
                        onClick={function (event) {
                            event.stopPropagation()
                            navigateToAccount(
                                userState.username,
                                userState,
                                userimage,
                                null,
                                navigator,
                                state,
                                setState,
                                'attending'
                            )
                        }}
                    >
                        <img
                            src={userimage}
                            alt="User"
                            style={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    </UserPhotoCircle>
                    {state.username !== userState.username && showFollowButton &&
                        (userState.following ? (
                            <FollowingIconButton
                                sx={{
                                    height: height / 2,
                                    width: width / 2,
                                    position: 'absolute',
                                    right: -2,
                                    top: -2,
                                }}
                                onClick={handleUnfollow}
                            >
                                <CheckRoundedIcon
                                    sx={{ fontSize: 14, fontWeight: 'bold' }}
                                />
                            </FollowingIconButton>
                        ) : (
                            <FollowIconButton
                                sx={{
                                    height: height / 2,
                                    width: width / 2,
                                    position: 'absolute',
                                    right: -2,
                                    top: -2,
                                }}
                                onClick={handleFollow}
                            >
                                <AddRoundedIcon
                                    sx={{ fontSize: 14, fontWeight: 'bold' }}
                                />
                            </FollowIconButton>
                        ))}
                </Box>
            ) : userimagestatus === DataStatus.loading ? (
                <UserPhotoCircle
                    sx={{
                        width: width,
                        height: height,
                        borderRadius: '50%',
                        marginRight: 1,
                    }}
                />
            ) : (
                (userimagestatus === DataStatus.failure || !userimage) && (
                    <Box sx={{ marginRight: 1 }}>
                        <ErrorAccountAvatarView
                            size={height}
                            includeBorder={false}
                        />
                    </Box>
                )
            )}
        </>
    )
}

export default AccountAvatarSubView
