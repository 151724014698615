import { Box, styled } from '@mui/material'
import {
    PrimaryDivider,
    PrimaryTypography,
    SelectTypography,
    StyledCircularProgress,
} from '../Components/StyledComponents'
import { DataStatus } from '../../models/Dataloading'
import { useEffect, useState } from 'react'
import { getEvents } from '../../api/RestEvent'
import { useAppState } from '../../AppStateProvider'
import EventSubPostView from '../EventView/EventSubPostView'
import { Event } from '../../models/Event'
import { User } from '../../models/User'

interface AccountSideSimilarEventsViewProps {
    user: User
}

const Container = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.border.primary}`,
    backgroundColor: theme.palette.background.secondary,
    padding: 0,
    borderRadius: 10,
    width: '100%',
}))

const AccountSideSimilarEventsView =
    ({}: AccountSideSimilarEventsViewProps) => {
        const { state } = useAppState()
        const [eventloaded, setEventLoaded] = useState<DataStatus>(
            DataStatus.loading
        )
        const [eventstate, setEventState] = useState<Event[]>([])
        useEffect(() => {
            if (eventloaded === DataStatus.loading) {
                try {
                    getEvents(null, null, null, 5, 5, 0, state.usertoken)
                        .then((response) => {
                            setEventState(response.events)
                            setEventLoaded(DataStatus.success)
                        })
                        .catch((error) => {
                            console.log(error)
                            setEventLoaded(DataStatus.failure)
                        })
                } catch (error) {
                    console.log(error)
                    setEventLoaded(DataStatus.failure)
                }
            }
        }, [])
        return (
            <Container sx={{ height: '100%' }}>
                <Box
                    sx={{
                        height: '100%',
                        padding: '16px 16px',
                    }}
                >
                    <PrimaryTypography
                        sx={{
                            fontSize: 16,
                            display: 'flex',
                        }}
                    >
                        Shared Events
                    </PrimaryTypography>
                    <PrimaryDivider
                        sx={{
                            margin: '8px 0',
                        }}
                    />
                    {eventloaded === DataStatus.loading ? (
                        <Box
                            sx={{
                                marginTop: '16px',
                                justifyContent: 'center',
                                display: 'flex',
                                height: '200px',
                            }}
                        >
                            <StyledCircularProgress />
                        </Box>
                    ) : (
                        <Box marginTop={'16px'}>
                            {eventstate.map((event) => (
                                <Box marginBottom={'16px'}>
                                    <EventSubPostView event={event} />
                                </Box>
                            ))}
                        </Box>
                    )}
                    <SelectTypography
                        sx={{
                            fontSize: 14,
                            display: 'flex',
                            '&:hover': {
                                textDecoration: 'underline',
                            },
                            cursor: 'pointer',
                        }}
                    >
                        View More
                    </SelectTypography>
                </Box>
            </Container>
        )
    }

export default AccountSideSimilarEventsView
