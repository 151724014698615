// DateUtils.ts

import { format, parse } from 'date-fns'

export const date2string = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        month: 'long',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    }
    return date.toLocaleString('en-US', options)
}

export const date2ymd = (date: Date): string => {
    return date.toISOString().split('T')[0]
}

export const birthday2string = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
        month: 'long',
        day: '2-digit',
        year: 'numeric',
    }
    return date.toLocaleDateString('en-US', options)
}

export const Date2mdstring = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
        month: 'long',
        day: '2-digit',
    }
    return date.toLocaleDateString('en-US', options)
}

export const date2stringday = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        month: 'long',
        day: '2-digit',
        year: 'numeric',
    }
    return date.toLocaleDateString('en-US', options)
}

export const date2stringtime = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    }
    return date.toLocaleTimeString('en-US', options)
}

export const string2dateday = (date: string): Date => {
    const parsedDate = new Date(date)
    return isNaN(parsedDate.getTime()) ? new Date() : parsedDate
}

export const string2datetime = (date: string): Date => {
    const parsedDate = new Date(`1970-01-01T${date}`)
    return isNaN(parsedDate.getTime()) ? new Date() : parsedDate
}

export const iso2day = (iso: string): string => {
    const date = new Date(iso)
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        month: 'long',
        day: '2-digit',
        year: 'numeric',
    }
    return date.toLocaleDateString('en-US', options)
}

export const iso2date = (iso: string): Date => {
    return new Date(iso)
}

export const date2iso = (date: Date): string => {
    return date.toISOString()
}

export const iso2eventtime = (iso: string): string => {
    const date = new Date(iso)
    const options: Intl.DateTimeFormatOptions = {
        month: 'long',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    }
    return date.toLocaleString('en-US', options).replace('at', ' \u2022')
}

export const iso2time = (iso: string): string => {
    const date = new Date(iso)
    const options: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    }
    return date.toLocaleTimeString('en-US', options)
}

export const dateminutedifference = (
    date1: Date,
    date2: Date,
    min: number
): boolean => {
    const difference = Math.abs(date2.getTime() - date1.getTime())
    const minutesDifference = Math.floor(difference / (1000 * 60))
    return minutesDifference > min
}

export const datetime2iso = (date: Date, time: string): string => {
    const parsedTime = parse(time, 'hh:mm a', new Date())
    const hours = parsedTime.getHours()
    const minutes = parsedTime.getMinutes()

    date.setHours(hours)
    date.setMinutes(minutes)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS000")
}

export const time2Date = (time: string): Date => {
    const parsedTime = parse(time, 'hh:mm a', new Date())
    return parsedTime
}
