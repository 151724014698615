import React, { useEffect, useState, useRef } from 'react'
import { Post } from '../../../models/Post'
import { DataStatus } from '../../../models/Dataloading'
import { styled } from '@mui/material/styles'
import { Link, useNavigate } from 'react-router-dom'
import { useAppState } from '../../../AppStateProvider'
import { grabUserPhoto } from '../../../api/RestUser'
import { grabEventPhoto } from '../../../api/RestEvent'
import {
    favoritePost,
    grabPostPhoto,
    unfavoritePost,
} from '../../../api/RestPost'
import EventSubPostView from '../../EventView/EventSubPostView'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded'
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'

import {
    Box,
    CardContent,
    CardMedia,
    Typography,
    Divider,
    IconButton,
    Collapse,
    Chip,
    Button,
    ButtonBase,
} from '@mui/material'
import {
    PrimaryTypography,
    SecondaryTypography,
} from '../../Components/StyledComponents'
import { timeDifferenceFromCurrentTime } from '../../../utils/TimeDifference'
import { getComments } from '../../../api/RestComment'
import { set } from 'date-fns'
import AccountPopoverView from '../../AccountView/AccountPopoverView'
import AccountAvatarSubView from '../../AccountView/AccountAvatarSubView'
import { Expand } from '@mui/icons-material'
import ExpandImagePostView from '../ExpandImagePostView'
import HashtagTypography from '../../Components/HighlightTypography'
import PostHeaderView from '../PostHeaderView'
import { navigateToPost } from '../../../utils/NavigationFunctions'
import ExpandImagePostMobileView from './ExpandImagePostMobileView'
import LinkPreview from '../../Components/LinkPreview'
import { ExtractUrl } from '../../../utils/ExtractUrl'

const UserPhotoCircle = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
}))

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    padding: 10,
    '&:last-child': {
        paddingBottom: 0,
    },
}))

const PostLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
        textDecoration: 'none',
        color: 'inherit',
    },
}))

const PostCard = styled(Box)(({ theme }) => ({
    boxShadow: 'none',
    backgroundColor: theme.palette.background.primary,
    padding: '8px 16px',
    cursor: 'pointer',
}))

const LoadingBox = styled(CardMedia)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
}))

const StyledFavoriteBorderRoundedIcon = styled(FavoriteBorderRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const FavoritedBorderRoundedIcon = styled(FavoriteBorderRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.favorite,
    })
)

const StyledChatBubbleOutlineRoundedIcon = styled(ChatBubbleOutlineRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const StyledIosShareRoundedIcon = styled(IosShareRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

interface StyledArrowForwardIosRoundedIconProps
    extends React.ComponentProps<typeof ArrowForwardIosRoundedIcon> {
    open: boolean
}
const StyledArrowForwardIosRoundedIcon = styled(
    (props: StyledArrowForwardIosRoundedIconProps) => (
        <ArrowForwardIosRoundedIcon {...props} />
    )
)(({ theme, open }) => ({
    color: theme.palette.text.primary,
    transition: 'transform 0.2s ease-in-out',
    transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
    marginTop: 5,
    marginBottom: 5,
    color: theme.palette.border.primary,
}))

const StyledChip = styled(Box)(({ theme }) => ({
    color: theme.palette.text.secondary,
    borderRadius: 50,
    padding: '5px 10px',
    display: 'flex',
    backgroundColor: 'transparent',
    // backgroundColor: theme.palette.background.tertiary,
    alignItems: 'center',
    '&:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    '&:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
}))

const FollowIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.background.primary,
    backgroundColor: theme.palette.text.primary,
    border: '2px solid ' + theme.palette.background.primary,
    '&:hover': {
        backgroundColor: theme.palette.text.primary,
    },
}))

const FollowingIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.text.selected,
    border: '2px solid ' + theme.palette.background.primary,
    '&:hover': {
        backgroundColor: theme.palette.text.selected,
    },
}))

interface PostViewProps {
    post: Post
    postImage: string | null
    postAuthorImage: string | null
    postloaded: DataStatus
    postAuthorImageLoaded: DataStatus
    postImageLoaded: DataStatus
}

const PostItemCommentMobileView = ({
    post,
    postImage,
    postAuthorImage,
    postloaded,
    postAuthorImageLoaded,
    postImageLoaded,
}: PostViewProps) => {
    const { state, setState } = useAppState()
    const navigator = useNavigate()
    const [eventcollapse, setEventCollapse] = useState(false)
    const [postState, setPostState] = useState<Post>(post)
    const [expandImage, setExpandImage] = useState(false)
    const [urlPreview, setUrlPreview] = useState<string | null>(null)
    const [postimagestatus, setPostImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [userimagestatus, setUserImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventimagestatus, setEventImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [userState, setUserState] = useState(post.author)
    const [postimage, setPostImage] = React.useState<string | null>(postImage)
    const [eventimage, setEventImage] = React.useState<string | null>(null)
    const [userimage, setUserImage] = React.useState<string | null>(
        postAuthorImage
    )
    const timerRef = useRef<number | null>(null)
    const [openAccountPopover, setOpenAccountPopover] = useState(false)
    const [accountAnchorEl, setAccountAnchorEl] = useState<null | HTMLElement>(
        null
    )
    const handleAccountPoperOpen = (event: any) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        setAccountAnchorEl(event.currentTarget)
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(true)
        }, 400)
    }

    const handleAccountPoperClose = () => {
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(false)
        }, 500)
    }

    useEffect(() => {
        const respose = ExtractUrl(post.caption)
        if (respose) {
            setUrlPreview(respose[0])
        }
        try {
            grabUserPhoto(userState.image, state.usertoken)
                .then((response) => {
                    setUserImage(response)
                    setUserImageStatus(DataStatus.success)
                })
                .catch((error) => {
                    console.log(error)
                    setUserImageStatus(DataStatus.failure)
                })
        } catch (error) {
            console.log(error)
            setUserImageStatus(DataStatus.failure)
        }
    }, [])
    useEffect(() => {
        try {
            grabPostPhoto(post.image, state.usertoken)
                .then((response: any) => {
                    setPostImage(response)
                    setPostImageStatus(DataStatus.success)
                })
                .catch((error) => {
                    console.log(error)
                    setPostImageStatus(DataStatus.failure)
                })
        } catch (error) {
            console.log(error)
            setPostImageStatus(DataStatus.failure)
        }
    }, [])

    const handleFavorite = (event: React.MouseEvent) => {
        event.stopPropagation()
        if (!postState.favorited) {
            setPostState({
                ...postState,
                favorited: true,
                favorites_count: postState.favorites_count + 1,
            })
            try {
                favoritePost(postState.id, state.usertoken)
                    .then((response) => {
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } catch (error) {
                console.log(error)
            }
        } else {
            setPostState({
                ...postState,
                favorited: false,
                favorites_count: postState.favorites_count - 1,
            })
            try {
                unfavoritePost(postState.id, state.usertoken)
                    .then((response) => {
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } catch (error) {
                console.log(error)
            }
        }
    }
    return (
        <PostCard position={'relative'}>
            {expandImage && (
                <ExpandImagePostMobileView
                    post={post}
                    postImage={postimage!}
                    postAuthorImage={userimage!}
                    comments={null}
                    expandImage={expandImage}
                    setExpandImage={setExpandImage}
                />
            )}
            <PostHeaderView
                post={post}
                user={userState}
                setUser={setUserState}
                userImage={userimage}
                userImageLoaded={userimagestatus}
            />

            {/* <PrimaryTypography style={{ fontSize: 14 }} paddingBottom={1}>
                {postState.caption}
            </PrimaryTypography> */}
            <Box
                sx={{
                    fontSize: 14,
                    paddingBottom: '8px',
                }}
            >
                <HashtagTypography text={post.caption} fontSize={14} />
            </Box>
            {urlPreview && <LinkPreview url={urlPreview} />}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    aspectRatio: '20/9',
                    position: 'relative',
                }}
            >
                {postimagestatus === DataStatus.success && postimage ? (
                    <img
                        src={postimage}
                        alt="Event"
                        style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                            borderRadius: '10px',
                        }}
                        onClick={function (event) {
                            event.stopPropagation()
                            setExpandImage(true)
                        }}
                    />
                ) : eventimagestatus === DataStatus.loading ? (
                    <LoadingBox />
                ) : (
                    <LoadingBox />
                )}
            </div>
            <Box
                display="flex"
                alignItems="center"
                alignContent="center"
                justifyContent="space-between"
                width="100%"
                paddingTop="8px"
            >
                <Box display="flex">
                    <StyledChip component={ButtonBase} onClick={handleFavorite}>
                        {postState.favorited ? (
                            <>
                                <FavoriteRoundedIcon
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.text.favorite,
                                        marginRight: '8px',
                                        fontSize: 16,
                                    }}
                                />
                                <PrimaryTypography
                                    sx={{
                                        // color: (theme) =>
                                        //     theme.palette.text.favorite,
                                        fontSize: 14,
                                    }}
                                >
                                    {postState.favorites_count}
                                </PrimaryTypography>
                            </>
                        ) : (
                            <>
                                <StyledFavoriteBorderRoundedIcon
                                    style={{ marginRight: 8, fontSize: 16 }}
                                />
                                <PrimaryTypography fontSize={14}>
                                    {postState.favorites_count}
                                </PrimaryTypography>
                            </>
                        )}
                    </StyledChip>
                    <Box width={20} />
                    <StyledChip>
                        <ChatBubbleOutlineRoundedIcon
                            sx={{
                                color: (theme) => theme.palette.text.primary,
                                marginRight: '8px',
                                fontSize: 16,
                            }}
                        />
                        <PrimaryTypography fontSize={14}>
                            {postState.comment_count}
                        </PrimaryTypography>
                    </StyledChip>
                    <Box width={20} />
                    <StyledChip component={ButtonBase}>
                        <IosShareRoundedIcon
                            sx={{
                                color: (theme) => theme.palette.text.primary,
                                marginRight: '8px',
                                fontSize: 16,
                            }}
                        />
                        <PrimaryTypography fontSize={14}>
                            Share
                        </PrimaryTypography>
                    </StyledChip>
                </Box>
                <StyledIconButton
                    onClick={function (event) {
                        event.stopPropagation()
                        setEventCollapse(!eventcollapse)
                    }}
                >
                    <StyledArrowForwardIosRoundedIcon
                        style={{ fontSize: 16 }}
                        open={eventcollapse}
                    />
                </StyledIconButton>
            </Box>
            <Collapse in={eventcollapse} timeout="auto" unmountOnExit>
                <StyledDivider style={{ marginBottom: 16 }} />

                <EventSubPostView event={post.event} />
            </Collapse>
        </PostCard>
    )
}

export default PostItemCommentMobileView
