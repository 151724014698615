import { useEffect, useRef, useState } from 'react'
import { Box, Typography, Tab, Tabs } from '@mui/material'
import { styled } from '@mui/system'
import { Event } from '../../models/Event'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { DataStatus } from '../../models/Dataloading'
import {
    PrimaryTypography,
    ScrollBarDiv,
    SecondaryTypography,
    StyledCircularProgress,
} from '../Components/StyledComponents'
import { NavigateEventState } from '../../models/NavigateState'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded'
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded'
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import EventPostsView from './EventPostsView'
import EventPostsSecondaryView from './EventPostSecondaryView'
import EventAttendeesView from './EventAttendeesView'
import EventDetailsView from './EventDetailsView'
import {
    StyledTab,
    StyledTabs,
    TabBox,
} from '../Components/StyledComponents/StyledTabView'
import SideLinksView from '../SideViews/SideLinksView'
import { set } from 'date-fns'
import EventPhotosView from './EventPhotosView'
import { navigateToEventPhotos } from '../../utils/NavigationFunctions'
import { tab } from '@testing-library/user-event/dist/tab'
import { useAppState } from '../../AppStateProvider'
import { hexToRGBA } from '../../utils/haxToRGBA'
import EventPageMobileView from './EventMobileView'

const StyledPersonRoundedIcon = styled(PersonRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.disabled,
}))

const StyledFavoriteBorderRoundedIcon = styled(FavoriteBorderRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const StyledRepeatRoundedIcon = styled(RepeatRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const StyledPersonOutlineRoundedIcon = styled(PersonOutlineRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
    })
)

const BackgroundBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.background.primary,
    width: '100%',
    zIndex: 0,
}))

const PicturesBox = styled(Box)(({ theme }) => ({
    width: '100%',
    borderRadius: 50,
    backgroundColor: hexToRGBA(theme.palette.background.primary, 0.6),
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignText: 'center',
    display: 'flex',
    padding: '5px 10px',
    cursor: 'pointer',
}))

interface tabDictProps {
    [key: string]: number
}

const EventPageView = () => {
    const { state, setState } = useAppState()
    return (
        <>
            {state.isMobile ? (
                <EventPageMobileView />
            ) : (
                <EventPageDesktopView />
            )}
        </>
    )
}
const EventPageDesktopView = () => {
    const { state, setState } = useAppState()
    const { slug } = useParams<{ slug: string }>()
    const navigate = useNavigate()
    const { tabIndex } = useParams<{ tabIndex: string }>()
    const location = useLocation()
    const locationEvent = location.state as NavigateEventState | null
    const eventslugRef = useRef(slug)
    const [event, setEvent] = useState<Event | null>(null)
    const [eventImage, setEventImage] = useState<string | null>(null)
    const [eventHostImage, setEventHostImage] = useState<string | null>(null)
    const [eventloaded, setEventLoaded] = useState<DataStatus>(
        DataStatus.loading
    )
    const [value, setValue] = useState(tabIndex)

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
        navigate(`/app/event/${slug}/${newValue}`)
    }

    useEffect(() => {
        setValue(tabIndex)
        if (eventslugRef.current !== slug) {
            window.scrollTo(0, 0)
        }
        eventslugRef.current = slug
        if (locationEvent !== null) {
            setEventImage(locationEvent.eventImage)
            setEventLoaded(DataStatus.success)
            setEvent(locationEvent.event)
        } else {
            setEventLoaded(DataStatus.failure)
        }
    }, [window.location.pathname])
    return (
        <Box
            id="event-page-view"
            sx={{
                height: '100%',
                width: '100%',
                position: 'relative',
                backgroundColor: (theme) => theme.palette.background.primary,
                overflow: 'hidden',
            }}
        >
            {window.location.pathname.includes('images') && <EventPhotosView />}
            {eventloaded === DataStatus.loading ? (
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        // overflowY: 'auto',
                        marginTop: 56,
                        justifyContent: 'center',
                        display: 'flex',
                        borderRadius: 2,
                        padding: 10,
                    }}
                >
                    <StyledCircularProgress />
                </div>
            ) : event !== null ? (
                <Box
                    sx={{
                        width: '100%',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        marginTop: '56px',
                        position: 'relative',
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                        }}
                    >
                        <div
                            style={{
                                overflow: 'hidden',
                                position: 'relative',
                                width: '100%',
                                height: 250,
                            }}
                        >
                            <img
                                src={eventImage!}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    opacity: 1,
                                    filter: 'blur(20px)',
                                    zIndex: 1,
                                    objectFit: 'cover',
                                    scale: 1.1,
                                }}
                            />
                        </div>
                        <Box
                            sx={{
                                width: '100%',
                                position: 'absolute',
                                top: 0,
                                justifyContent: 'center',
                                alignContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                            }}
                        >
                            <Box
                                sx={{
                                    maxWidth: 1200,
                                    width: '100%',
                                    padding: '0px 40px',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: 'calc(40% - 20px)',
                                        height: '100%',
                                        float: 'right',
                                        padding: '0px 0px',
                                        marginTop: '130px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            position: 'relative',
                                        }}
                                    >
                                        <img
                                            src={eventImage!}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                opacity: 1,
                                                borderRadius: 10,
                                                position: 'relative',
                                                zIndex: 2,
                                                objectFit: 'cover',
                                            }}
                                        />
                                        {value === 'details' ? (
                                            <Box>
                                                <SideLinksView />
                                            </Box>
                                        ) : value === 'posts' ? (
                                            <Box>
                                                <EventPostsSecondaryView
                                                    event_slug={event.slug}
                                                    event={event}
                                                    eventImage={eventImage!}
                                                />
                                                <SideLinksView />
                                            </Box>
                                        ) : (
                                            value === 'attendees' && (
                                                <Box>
                                                    <SideLinksView />
                                                </Box>
                                            )
                                        )}
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        width: 'calc(60% - 20px)',
                                        height: '100%',
                                        float: 'left',
                                        padding: '0px 0px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            height: 250,
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                paddingBottom: '12px',
                                                width: '100%',
                                            }}
                                        >
                                            <PrimaryTypography
                                                sx={{
                                                    fontSize: 50,
                                                    fontWeight: 800,
                                                    textAlign: 'left',
                                                    textShadow:
                                                        '0px 0px 10px rgba(0, 0, 0, 0.3)',
                                                }}
                                            >
                                                {event.title}
                                            </PrimaryTypography>
                                            <Box
                                                sx={{
                                                    alighItems: 'left',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    textShadow:
                                                        '0px 0px 10px rgba(0, 0, 0, 0.3)',
                                                }}
                                            >
                                                <StyledFavoriteBorderRoundedIcon
                                                    style={{
                                                        marginRight: 5,
                                                        fontSize: 20,
                                                    }}
                                                />
                                                <PrimaryTypography
                                                    variant="body1"
                                                    color="textPrimary"
                                                    fontSize={16}
                                                    sx={{
                                                        textShadow:
                                                            '0px 0px 10px rgba(0, 0, 0, 0.3)',
                                                    }}
                                                >
                                                    {event.favorites_count}{' '}
                                                    likes
                                                </PrimaryTypography>
                                                <Box width={20} />
                                                <StyledRepeatRoundedIcon
                                                    style={{
                                                        marginRight: 5,
                                                        fontSize: 20,
                                                        textShadow:
                                                            '0px 0px 10px rgba(0, 0, 0, 0.3)',
                                                    }}
                                                />
                                                <PrimaryTypography
                                                    variant="body1"
                                                    color="textPrimary"
                                                    fontSize={16}
                                                    sx={{
                                                        textShadow:
                                                            '0px 0px 10px rgba(0, 0, 0, 0.3)',
                                                    }}
                                                >
                                                    {event.post_count} posts
                                                </PrimaryTypography>
                                                <Box width={20} />
                                                <StyledPersonOutlineRoundedIcon
                                                    style={{
                                                        marginRight: 5,
                                                        fontSize: 20,
                                                        textShadow:
                                                            '0px 0px 10px rgba(0, 0, 0, 0.3)',
                                                    }}
                                                />

                                                <PrimaryTypography
                                                    variant="body1"
                                                    color="textPrimary"
                                                    fontSize={16}
                                                    sx={{
                                                        textShadow:
                                                            '0px 0px 10px rgba(0, 0, 0, 0.3)',
                                                    }}
                                                >
                                                    {event.attendee_count}{' '}
                                                    attendees
                                                </PrimaryTypography>
                                            </Box>
                                            <Box>
                                                <PicturesBox
                                                    sx={{ marginTop: '8px' }}
                                                    onClick={() =>
                                                        navigateToEventPhotos(
                                                            event,
                                                            eventImage,
                                                            navigate,
                                                            state,
                                                            setState,
                                                            tabIndex!
                                                        )
                                                    }
                                                >
                                                    <PrimaryTypography
                                                        sx={{ fontSize: 14 }}
                                                    >
                                                        See all 1 photo
                                                    </PrimaryTypography>
                                                </PicturesBox>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <BackgroundBox
                            sx={{
                                padding: '10px 0px',
                                width: '100%',
                                justifyContent: 'center',
                                display: 'flex',
                            }}
                        >
                            <Box
                                sx={{
                                    maxWidth: 1200,
                                    width: '100%',
                                    padding: '0px 40px',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: 'calc(60% - 20px)',
                                        height: '100%',
                                        float: 'left',
                                        padding: '0px 0px',
                                    }}
                                >
                                    <TabBox>
                                        <StyledTabs
                                            value={value}
                                            onChange={handleChange}
                                            aria-label="basic tabs example"
                                        >
                                            <StyledTab
                                                label="Details"
                                                value="details"
                                            />
                                            <StyledTab
                                                label="Posts"
                                                value="posts"
                                            />
                                            <StyledTab
                                                label="Attendees"
                                                value="attendees"
                                            />
                                        </StyledTabs>
                                    </TabBox>
                                    {value === 'details' ? (
                                        <EventDetailsView event={event} />
                                    ) : value === 'posts' ? (
                                        <EventPostsView
                                            event_slug={event.slug}
                                        />
                                    ) : (
                                        value == 'attendees' && (
                                            <EventAttendeesView event={event} />
                                        )
                                    )}
                                </Box>
                            </Box>
                        </BackgroundBox>
                    </Box>
                </Box>
            ) : (
                <div></div>
            )}
        </Box>
    )
}

export default EventPageView
