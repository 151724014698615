export interface FilterData {
    date: FilterDate
    attendeeCount: number[]
    range: number
    premium: EventType
    following: boolean
    category: FilterCategory[]
}

export enum FilterDateChoice {
    Any = 'Any',
    Today = 'Today',
    Tomorrow = 'Tomorrow',
    ThisWeek = 'This Week',
    ThisMonth = 'This Month',
    Custom = 'Custom',
}

export interface FilterDate {
    choice: FilterDateChoice
    dates: Date[]
}

export enum FilterCategory {
    Party = 'Party',
    Beach = 'Beach',
    Music = 'Music',
    Sports = 'Sports & Fitness',
    Food = 'Food',
    Social = 'Social',
    Technology = 'Technology',
    Business = 'Business',
    Film = 'Film & Media',
    Arts = 'Arts',
    Travel = 'Travel',
    Cars = 'Cars',
    Health = 'Health',
}

export enum EventType {
    None = 'Any',
    Free = 'Free',
    Premium = 'Premium',
}
