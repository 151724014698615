import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppState } from '../../AppStateProvider'
import { NavigateAccountState } from '../../models/NavigateState'
import { User } from '../../models/User'
import { followUser, grabUserPhoto, unfollowUser } from '../../api/RestUser'
import { DataStatus } from '../../models/Dataloading'
import { getEvents, grabEventPhoto } from '../../api/RestEvent'
import { Box, Tab, Tabs } from '@mui/material'
import {
    EmptyImageBox,
    FollowButton,
    PrimaryTypography,
    SecondaryTypography,
    TertiaryTypography,
    UnfollowButton,
    UserPhotoCircle,
} from '../Components/StyledComponents'
import { styled } from '@mui/system'
import AccountAttendingView from './AccountAttendingView'
import AccountPostsView from './AccountPostsView'
import AccountHostingView from './AccountHostingView'
import AccountMediaView from './AccountMediaView'
import {
    navigateToAccountEdit,
    navigateToFollowers,
    navigateToFollowing,
} from '../../utils/NavigationFunctions'
import AccountSideView from './AccountPageView/AccountSideView'
import {
    StyledTab,
    StyledTabs,
    TabBox,
} from '../Components/StyledComponents/StyledTabView'
import ErrorAccountAvatarView from '../Components/StaticPages/ErrorView/ErrorAccountAvatarView'
import AccountFFView from './AccountFFView'
import EditAccountView from './EditAccountView'
import AccountMobileView from './AccountMobileView'

const eventphotoheight = 200
const userphotosize = 125

const AccountView = () => {
    const { state } = useAppState()
    return (
        <>{state.isMobile ? <AccountMobileView /> : <AccountDesktopView />}</>
    )
}

const AccountDesktopView = () => {
    const navigator = useNavigate()
    const { state, setState } = useAppState()
    const location = useLocation()
    const { username } = useParams<{ username: string }>()
    const { tabIndex } = useParams<{ tabIndex: string }>()
    const usernameRef = useRef(username)
    const initialAccountStateData = location.state as NavigateAccountState
    const [userState, setUserState] = useState<User>(
        initialAccountStateData.user
    )
    const [userImage, setUserImage] = useState<string | null>(
        initialAccountStateData.userImage
    )
    const [userimagestatus, setUserImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventImage, setEventImage] = useState<string | null>(
        initialAccountStateData.eventImage
    )
    const [eventImageStatus, setEventImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [isUser, setIsUser] = useState(state.username === username)
    const [value, setValue] = useState(tabIndex)
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
        navigator(`/app/account/${username}/${newValue}`, {
            state: {
                user: userState,
                userImage: userImage,
                eventImage: eventImage,
            },
        })
    }
    useEffect(() => {
        setValue(tabIndex)
        setUserState(initialAccountStateData.user)
        setUserImage(initialAccountStateData.userImage)
        setEventImage(initialAccountStateData.eventImage)
    }, [window.location.pathname])
    const handleFollow = () => {
        setUserState({
            ...userState,
            follower_size: userState.follower_size + 1,
            following: true,
        })
        try {
            followUser(userState.username, state.usertoken)
                .then((response) => {
                    return
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleUnfollow = () => {
        setUserState({
            ...userState,
            follower_size: userState.follower_size - 1,
            following: false,
        })
        try {
            unfollowUser(userState.username, state.usertoken)
                .then((response) => {
                    return
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (usernameRef.current !== username) {
            window.scrollTo(0, 0)
        }
        usernameRef.current = username
        if (state.username === username) {
            setIsUser(true)
        } else {
            setIsUser(false)
        }
        if (userImage) {
            setUserImageStatus(DataStatus.success)
        } else {
            try {
                grabUserPhoto(userState.image, state.usertoken)
                    .then((response) => {
                        setUserImage(response)
                        setUserImageStatus(DataStatus.success)
                    })
                    .catch((error) => {
                        console.log(error)
                        setUserImageStatus(DataStatus.failure)
                    })
            } catch (error) {
                console.log(error)
                setUserImageStatus(DataStatus.failure)
            }
        }
        if (eventImage) {
            setEventImageStatus(DataStatus.success)
        } else {
            try {
                getEvents(
                    null,
                    userState.username,
                    null,
                    0,
                    1,
                    0,
                    state.usertoken
                )
                    .then((response) => {
                        grabEventPhoto(
                            response.events[0].image,
                            state.usertoken
                        )
                            .then((response) => {
                                setEventImage(response)
                                setEventImageStatus(DataStatus.success)
                            })
                            .catch((error) => {
                                console.log(error)
                                setEventImageStatus(DataStatus.failure)
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                        setEventImageStatus(DataStatus.failure)
                    })
            } catch (error) {
                console.log(error)
                setEventImageStatus(DataStatus.failure)
            }
        }
    }, [window.location.pathname])
    return (
        <div
            id="account-main-view"
            style={{
                height: '100%',
                width: '100%',
                position: 'relative',
                justifyContent: 'center',
                display: 'flex',
                float: 'left',
                marginTop: '56px',
            }}
        >
            {window.location.pathname.includes('ff') && <AccountFFView />}
            {window.location.pathname.includes('edit') && <EditAccountView />}
            <Box
                sx={{
                    position: 'absolute',
                    width: '100%',
                    maxWidth: 1200,
                    marginTop: '8px',
                    borderRadius: '10px',
                    padding: '0px 20px',
                }}
            >
                {eventImageStatus === DataStatus.success && eventImage ? (
                    <img
                        src={eventImage}
                        alt="event"
                        style={{
                            width: '100%',
                            height: 200,
                            objectFit: 'cover',
                            borderRadius: '10px',
                        }}
                    />
                ) : (
                    <EmptyImageBox
                        sx={{
                            width: '100%',
                            height: 200,
                            borderRadius: '10px',
                        }}
                    />
                )}
            </Box>
            <Box
                id="account-main-view-content-container"
                sx={{
                    maxWidth: 1200,
                    width: '100%',
                    marginRight: '0',
                    height: '100%',
                    marginTop: '8px',
                    marginBottom: '50px',
                    zIndex: 0,
                    position: 'relative',
                    padding: '0px 20px',
                }}
            >
                <Box
                    sx={{
                        width: 'calc(100% - 400px)',
                        float: 'left',
                        position: 'relative',
                    }}
                >
                    {/* <Box position="relative">
                        {eventImageStatus === DataStatus.success &&
                        eventImage ? (
                            <img
                                src={eventImage}
                                alt="event"
                                style={{
                                    width: '100%',
                                    height: 200,
                                    borderRadius: '10px',
                                    objectFit: 'cover',
                                }}
                            />
                        ) : (
                            <EmptyImageBox
                                sx={{
                                    width: '100%',
                                    height: 200,
                                    borderRadius: '10px',
                                }}
                            />
                        )}
                    </Box> */}
                    <Box
                        sx={{
                            padding: '12px 0px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            position: 'absolute',
                            top: eventphotoheight,
                            right: 0,
                        }}
                    >
                        {isUser ? (
                            <FollowButton
                                onClick={() =>
                                    navigateToAccountEdit(
                                        userState,
                                        userImage,
                                        eventImage,
                                        navigator,
                                        state,
                                        setState,
                                        value!
                                    )
                                }
                            >
                                Edit Profile
                            </FollowButton>
                        ) : (
                            <>
                                {userState.following ? (
                                    <UnfollowButton onClick={handleUnfollow}>
                                        Following
                                    </UnfollowButton>
                                ) : (
                                    <FollowButton onClick={handleFollow}>
                                        Follow
                                    </FollowButton>
                                )}
                            </>
                        )}
                    </Box>
                    <Box
                        sx={{
                            marginTop: `${userphotosize / 2 + 8 + eventphotoheight}px`,
                        }}
                    >
                        <Box
                            sx={{
                                padding: '0px 20px',
                            }}
                        >
                            <PrimaryTypography
                                sx={{ fontSize: 22, fontWeight: 'bold' }}
                            >
                                {userState.name}
                            </PrimaryTypography>
                            <SecondaryTypography sx={{ fontSize: 14 }}>
                                @{userState.username}
                            </SecondaryTypography>
                            <PrimaryTypography sx={{ fontSize: 16 }}>
                                {userState.bio}
                            </PrimaryTypography>
                            <Box
                                justifyContent={'left'}
                                alignItems={'center'}
                                display={'flex'}
                                marginTop={'8px'}
                            >
                                <Box
                                    sx={{
                                        justifyItems: 'center',
                                        paddingRight: '8px',
                                        '&:hover .underline': {
                                            textDecoration: (theme) =>
                                                `underline ${theme.palette.text.primary}`,
                                        },
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                        navigateToFollowers(
                                            userState,
                                            userImage,
                                            eventImage,
                                            null,
                                            navigator,
                                            state,
                                            setState,
                                            value!
                                        )
                                    }
                                >
                                    <PrimaryTypography
                                        fontSize={14}
                                        fontWeight={'bold'}
                                        textAlign={'center'}
                                        className="underline"
                                    >
                                        {userState.follower_size}
                                    </PrimaryTypography>
                                    <TertiaryTypography
                                        fontSize={14}
                                        textAlign={'center'}
                                        whiteSpace={'pre'}
                                        className="underline"

                                        // marginLeft={'4px'}
                                    >
                                        {' Followers'}
                                    </TertiaryTypography>
                                </Box>
                                <Box
                                    sx={{
                                        justifyItems: 'center',
                                        padding: '0px 8px',
                                        '&:hover .underline': {
                                            textDecoration: (theme) =>
                                                `underline ${theme.palette.text.primary}`,
                                        },
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                        navigateToFollowing(
                                            userState,
                                            userImage,
                                            eventImage,
                                            null,
                                            navigator,
                                            state,
                                            setState,
                                            value!
                                        )
                                    }
                                >
                                    <PrimaryTypography
                                        fontSize={14}
                                        fontWeight={'bold'}
                                        textAlign={'center'}
                                        className="underline"
                                    >
                                        {userState.following_size}
                                    </PrimaryTypography>
                                    <TertiaryTypography
                                        fontSize={14}
                                        textAlign={'center'}
                                        className="underline"
                                        whiteSpace={'pre'}
                                    >
                                        {' Following'}
                                    </TertiaryTypography>
                                </Box>
                                <Box
                                    sx={{
                                        justifyItems: 'center',
                                        padding: '0px 8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <PrimaryTypography
                                        fontSize={14}
                                        fontWeight={'bold'}
                                        textAlign={'center'}
                                    >
                                        {0}
                                    </PrimaryTypography>
                                    <TertiaryTypography
                                        fontSize={14}
                                        textAlign={'center'}
                                        // fontWeight={'bold'}
                                        whiteSpace={'pre'}
                                    >
                                        {' Posts'}
                                    </TertiaryTypography>
                                </Box>
                                <Box
                                    sx={{
                                        justifyItems: 'center',
                                        padding: '0px 8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <PrimaryTypography
                                        fontSize={14}
                                        fontWeight={'bold'}
                                        textAlign={'center'}
                                    >
                                        {0}
                                    </PrimaryTypography>
                                    <TertiaryTypography
                                        fontSize={14}
                                        textAlign={'center'}
                                        // fontWeight={'bold'}
                                        whiteSpace={'pre'}
                                    >
                                        {' Events'}
                                    </TertiaryTypography>
                                </Box>
                            </Box>
                        </Box>
                        <TabBox marginTop={'16px'}>
                            <StyledTabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                            >
                                <StyledTab
                                    label="Attending"
                                    value="attending"
                                />
                                <StyledTab label="Posts" value="posts" />
                                <StyledTab label="Media" value="media" />
                                <StyledTab label="Events" value="events" />
                            </StyledTabs>
                        </TabBox>
                        {value === 'attending' ? (
                            <AccountAttendingView user={userState} />
                        ) : value == 'posts' ? (
                            <AccountPostsView user={userState} />
                        ) : value == 'media' ? (
                            <AccountMediaView user={userState} />
                        ) : (
                            value == 'events' && (
                                <AccountHostingView user={userState} />
                            )
                        )}
                    </Box>
                    <Box
                        style={{
                            position: 'absolute',
                            top: eventphotoheight - userphotosize / 2,
                            left: '20px',
                        }}
                    >
                        {userimagestatus === DataStatus.success && userImage ? (
                            <UserPhotoCircle
                                includeBorder={true}
                                sx={{
                                    height: userphotosize,
                                    width: userphotosize,
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                }}
                            >
                                <img
                                    src={userImage}
                                    alt="userimage"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            </UserPhotoCircle>
                        ) : userimagestatus === DataStatus.loading ? (
                            <UserPhotoCircle
                                includeBorder={true}
                                sx={{
                                    height: userphotosize,
                                    width: userphotosize,
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                }}
                            />
                        ) : (
                            (userimagestatus === DataStatus.failure ||
                                !userImage) && (
                                <ErrorAccountAvatarView
                                    size={userphotosize}
                                    includeBorder={true}
                                />
                            )
                        )}
                    </Box>
                </Box>
                <AccountSideView user={userState} />
            </Box>
        </div>
    )
}

export default AccountView
