import { Post } from '../../models/Post'
import {
    Box,
    BoxProps,
    Button,
    InputBase,
    Typography,
    styled,
} from '@mui/material'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import { useState } from 'react'
import { set } from 'date-fns'
import { TertiaryTypography } from '../Components/StyledComponents'
import { Comment } from '../../models/Comment'
import { create } from 'domain'
import { useAppState } from '../../AppStateProvider'
import { createComment } from '../../api/RestComment'

const UserPhotoCircle = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
}))

interface CommentContainerProps extends BoxProps {
    focused: boolean
}

const CommentContainer = styled((props: CommentContainerProps) => (
    <Box {...props} />
))(({ theme, focused }) => ({
    // backgroundColor: theme.palette.background.secondary,
    borderBottom: '1px solid ' + theme.palette.border.secondary,
    display: 'flex',
    padding: '0px 0px',
    width: '100%',
    '&:hover': {
        borderColor: focused
            ? theme.palette.background.selected
            : theme.palette.text.secondary,
    },
    ...(focused && {
        borderColor: theme.palette.background.selected,
    }),
}))

const CommentButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.selected,
    textTransform: 'none',
    borderRadius: 50,
    padding: '4px 20px',
    '&:hover': {
        backgroundColor: theme.palette.background.selected,
    },
}))

const CancelButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.tertiary,
    textTransform: 'none',
    borderRadius: 50,
    padding: '4px 20px',
    '&:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
}))

const CommentInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: 14,
    alignItems: 'left',
    width: '100%',
    '& .MuiInputBase-input': {
        paddingLeft: 4,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
}))

interface CreateCommentViewProps {
    post: Post
    comments: Comment[] | null
    setComments: React.Dispatch<React.SetStateAction<Comment[] | null>>
}

const CreateCommentView = ({
    post,
    comments,
    setComments,
}: CreateCommentViewProps) => {
    const { state } = useAppState()
    const [focused, setFocused] = useState(false)
    const [commenting, setCommenting] = useState(false)
    const [input, setInput] = useState('')
    const handleFocus = () => {
        setFocused(true)
        setCommenting(true)
    }
    const handleBlur = () => {
        setFocused(false)
        if (input === '') {
            setCommenting(false)
        }
    }

    const handleCanncel = () => {
        setInput('')
        setFocused(false)
        setCommenting(false)
    }

    const handleComment = () => {
        try {
            createComment(input, post.id, state.usertoken)
                .then((response) => {
                    if (comments === null) {
                        setComments([response.comment])
                        setInput('')
                        setFocused(false)
                        setCommenting(false)
                    } else {
                        setComments([...comments, response.comment])
                        setInput('')
                        setFocused(false)
                        setCommenting(false)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Box sx={{ display: 'flex', padding: '8px', paddingBottom: '4px' }}>
            <Box>
                {state.userimage ? (
                    <UserPhotoCircle
                        id="user-photo-circle"
                        sx={{
                            width: '35px',
                            height: '35px',
                            borderRadius: '50%',
                            alignItems: 'center',
                            overflow: 'hidden',
                        }}
                    >
                        <img
                            src={state.userimage}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    </UserPhotoCircle>
                ) : (
                    <UserPhotoCircle
                        id="user-photo-circle"
                        sx={{
                            width: '5px',
                            height: '35px',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <PersonRoundedIcon
                            sx={{
                                color: (theme) => theme.palette.text.disabled,
                            }}
                        />
                    </UserPhotoCircle>
                )}
            </Box>
            <Box
                sx={{
                    marginLeft: '10px',
                    height: '100%',
                    width: '100%',
                    float: 'right',
                    alignItems: 'top',
                }}
            >
                {commenting && (
                    <Box display={'flex'}>
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: (theme) => theme.palette.text.secondary,
                            }}
                        >
                            Reply to
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: (theme) => theme.palette.text.selected,
                                marginLeft: '4px',
                            }}
                        >
                            @{post.author.username}
                        </Typography>
                    </Box>
                )}
                <CommentContainer focused={focused}>
                    <CommentInputBase
                        placeholder="Write a comment..."
                        multiline
                        fullWidth
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                    />
                </CommentContainer>
                {commenting && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            marginTop: '10px',
                        }}
                    >
                        <CancelButton onClick={handleCanncel}>
                            Cancel
                        </CancelButton>
                        <CommentButton
                            onClick={handleComment}
                            sx={{
                                marginLeft: '8px',
                            }}
                        >
                            Comment
                        </CommentButton>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default CreateCommentView
