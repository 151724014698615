import {
    Box,
    BoxProps,
    Button,
    FormControl,
    InputBase,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import { RegisterRequestData } from '../../../api/Auth'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { CreateAccountFormValues } from '../CreateAccountItems/CreateAccountDetailsView'
import {
    OutlineContainer,
    OutlineInputBase,
    SelectButton,
} from '../../Components/StyledComponents'
import { useState } from 'react'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import { styled } from '@mui/material/styles'
import { DayMask, YearMask } from '../../../utils/MaskedInputs'

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '&.Mui-selected': {
        color: theme.palette.text.selected,
    },
    '&.Mui-selected:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
    margin: '10px 0',
    '& label.Mui-focused': {
        color: theme.palette.text.secondary,
    },
    '& .MuiInputBase-input': {
        fontSize: 14,
    },
    '& .MuiInputLabel-root': {
        fontSize: 14,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.border.primary,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.text.secondary,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.text.selected,
        },
    },
}))

const StyledSelect = styled(Select)(({ theme }) => ({
    '& label.Mui-focused': {
        color: theme.palette.text.secondary,
    },
    '& .MuiInputBase-input': {
        fontSize: 14,
    },
    '& .MuiInputLabel-root': {
        fontSize: 14,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.border.primary,
        '&.Mui-focused': {
            borderColor: theme.palette.text.selected,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.text.secondary,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.text.selected,
        },
    },
}))

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
    fontSize: 14,
    color: theme.palette.text.secondary,
}))

interface CreateAccountDateMobileViewProps {
    setShowLogin: React.Dispatch<React.SetStateAction<boolean>>
    setShowRegister: React.Dispatch<React.SetStateAction<boolean>>
    registerstate: CreateAccountFormValues
    setRegisterState: React.Dispatch<
        React.SetStateAction<CreateAccountFormValues>
    >
    setRegisterPage: React.Dispatch<React.SetStateAction<number>>
}

const CreateAccountDateMobileView = ({
    setShowLogin,
    setShowRegister,
    registerstate,
    setRegisterState,
    setRegisterPage,
}: CreateAccountDateMobileViewProps) => {
    const [focused, setFocused] = useState(false)
    const handleFocus = () => {
        setFocused(true)
    }
    const handleBlur = () => {
        setFocused(false)
    }
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    color: 'text.primary',
                }}
            >
                Please enter your birthday
            </Typography>
            <Typography
                sx={{
                    fontSize: 14,
                    color: 'text.secondary',
                }}
            >
                You must be at least 18 years or older to use this app
            </Typography>
            <Formik
                initialValues={{
                    month: registerstate.month,
                    day: registerstate.day,
                    year: registerstate.year,
                }}
                initialErrors={
                    {
                        month: 'Month is required',
                        day: 'Day is required',
                        year: 'Year is required',
                    } as CreateAccountFormValues
                }
                validate={(values) => {
                    const errors: Partial<CreateAccountFormValues> = {}
                    if (!values.month) {
                        errors.month = 'Month is required'
                    }
                    if (!values.day) {
                        errors.day = 'Day is required'
                    }
                    if (!values.year) {
                        errors.year = 'Year is required'
                    }
                    return errors
                }}
                validationSchema={Yup.object().shape({
                    month: Yup.number().required(),
                    day: Yup.string().min(2).max(2).required(),
                    year: Yup.string().min(4).max(4).required(),
                })}
                onSubmit={(values) => {
                    setRegisterState((prev) => ({
                        ...prev,
                        month: values.month,
                        day: values.day,
                        year: values.year,
                    }))
                    setRegisterPage(4)
                }}
            >
                {({
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                            sx={{
                                mt: '16px',
                            }}
                        >
                            <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                            >
                                <StyledInputLabel id="demo-select-small-label">
                                    Month
                                </StyledInputLabel>
                                <StyledSelect
                                    label="Month"
                                    value={values.month}
                                    onChange={handleChange}
                                    name="month"
                                >
                                    {months.map((month, index) => (
                                        <StyledMenuItem
                                            key={index}
                                            value={index}
                                        >
                                            {month}
                                        </StyledMenuItem>
                                    ))}
                                </StyledSelect>
                            </FormControl>
                            <StyledTextField
                                fullWidth
                                sx={{
                                    margin: '0 10px',
                                    '& .MuiInputBase-root': {
                                        height: 42,
                                    },
                                }}
                                inputProps={{
                                    inputComponent: DayMask as any,
                                }}
                                size="small"
                                name="day"
                                label="Day"
                                margin="normal"
                                onBlur={handleBlur}
                                value={values.day}
                                onChange={handleChange}
                                variant="outlined"
                            />
                            <StyledTextField
                                fullWidth
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: 42,
                                    },
                                }}
                                inputProps={{
                                    inputComponent: YearMask,
                                }}
                                size="small"
                                name="year"
                                label="Year"
                                margin="normal"
                                value={values.year}
                                onChange={handleChange}
                                variant="outlined"
                            />
                        </Box>
                        <SelectButton
                            fullWidth
                            type="submit"
                            sx={{
                                mt: '32px',
                                height: '40px',
                                fontSize: 16,
                            }}
                            disabled={!isValid}
                        >
                            Continue
                        </SelectButton>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default CreateAccountDateMobileView
