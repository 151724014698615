import { AppBar, Box, styled, Toolbar, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAppState } from '../../../AppStateProvider'
import { StyledLinearProgress } from '../../Components/StyledComponents'
import { Event } from '../../../models/Event'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `calc(100%)`,
    borderBottom: '1px solid ' + theme.palette.border.primary,
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    height: 55,
    // boxShadow: 'none',
    // marginRight: 6,
}))

interface CreatePostSelectEventTopBarProps {
    initialEvent: Event | null
    selectedEvent: Event | null
    eventImage: string | null
    initialEventImage: string | null
    eventHostImage: string | null
    initialEventHostImage: string | null
}
const CreatePostSelectEventTopBar = ({
    initialEvent,
    selectedEvent,
    eventImage,
    initialEventImage,
    eventHostImage,
    initialEventHostImage,
}: CreatePostSelectEventTopBarProps) => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    return (
        <TopBar>
            {state.showprogress && (
                <StyledLinearProgress
                    variant="determinate"
                    value={state.progress}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transition: state.progresstransition
                                ? 'transform 0.2s linear'
                                : 'none',
                        },
                    }}
                />
            )}
            <Toolbar
                style={{ minHeight: 55, paddingLeft: 16, paddingRight: 16 }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'text.primary',
                    }}
                    onClick={() =>
                        navigate('/app/createpost', {
                            state: {
                                event: initialEvent,
                                eventImage: initialEventImage,
                                eventHostImage: initialEventHostImage,
                            },
                        })
                    }
                >
                    Back
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: selectedEvent
                            ? 'text.selected'
                            : 'text.disabled',
                    }}
                    onClick={() =>
                        navigate('/app/createpost', {
                            state: {
                                event: selectedEvent,
                                eventImage: eventImage,
                                eventHostImage: eventHostImage,
                            },
                        })
                    }
                >
                    Confirm
                </Typography>
            </Toolbar>
        </TopBar>
    )
}

export default CreatePostSelectEventTopBar
