export function timeDifferenceFromCurrentTime(
    toIsoDate: string
): string | null {
    const date = new Date(toIsoDate)
    const currentDate = new Date()
    const diff = currentDate.getTime() - date.getTime()

    const minutes = Math.floor(diff / (1000 * 60))
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)
    const weeks = Math.floor(days / 7)
    const months = Math.floor(days / 30)
    const years = Math.floor(days / 365)

    if (years > 0) {
        return `${years}y`
    } else if (months > 0) {
        return `${months}mo`
    } else if (weeks > 0) {
        return `${weeks}w`
    } else if (days > 0) {
        return `${days}d`
    } else if (hours > 0) {
        return `${hours}h`
    } else if (minutes > 0) {
        return `${minutes}m`
    } else {
        return 'now'
    }
}

export function timeDifferenceBetweenTimes(
    fromIsoDate: string,
    toIsoDate: string
): string | null {
    const fromDate = new Date(fromIsoDate)
    const toDate = new Date(toIsoDate)

    const diff = toDate.getTime() - fromDate.getTime()

    const minutes = Math.floor(diff / (1000 * 60))
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)
    const weeks = Math.floor(days / 7)
    const months = Math.floor(days / 30)
    const years = Math.floor(days / 365)

    if (years > 0) {
        return `${years} ${years > 1 ? 'years' : 'year'}`
    } else if (months > 0) {
        return `${months} ${months > 1 ? 'months' : 'month'}`
    } else if (weeks > 0) {
        return `${weeks} ${weeks > 1 ? 'weeks' : 'week'}`
    } else if (days > 0) {
        return `${days} ${days > 1 ? 'days' : 'day'}`
    } else if (hours > 0) {
        return `${hours} ${hours > 1 ? 'hours' : 'hour'}`
    } else if (minutes > 0) {
        return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`
    } else {
        return 'now'
    }
}

export const isSameDay = (date1: Date, date2: Date): boolean => {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    )
}
