import { useEffect, useState } from 'react'
import { useRoutes } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import { darkTheme } from './themes'
import routes from './routes'
import { useMediaQuery } from '@mui/material'
import { useAppState } from './AppStateProvider'

// app component
function App() {
    const [isDarkMode, setIsDarkMode] = useState(false)
    const isMobile = useMediaQuery('(max-width: 767px)')
    const { state, setState } = useAppState()
    const routing = useRoutes(routes())
    useEffect(() => {
        setState({ ...state, isMobile: isMobile })
    }, [isMobile])
    return (
        <ThemeProvider theme={isDarkMode ? darkTheme : darkTheme}>
            {routing}
        </ThemeProvider>
    )
}

export default App
