import { Location } from '../models/Location'

export const getDistanceInMiles = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
): number => {
    const R = 3958.8 // Radius of the Earth in miles
    const toRadians = (degree: number) => degree * (Math.PI / 180)

    const dLat = toRadians(lat2 - lat1)
    const dLon = toRadians(lon2 - lon1)

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) *
            Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2)

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    const distance = R * c // Distance in miles
    return distance
}

export const locationDifference = (location: Location): Promise<string> => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const lat = position.coords.latitude
                    const long = position.coords.longitude
                    const distance = getDistanceInMiles(
                        lat,
                        long,
                        location.x,
                        location.y
                    )
                    resolve(`${distance.toFixed()} miles`)
                },
                (error) => {
                    reject('Error getting location')
                }
            )
        } else {
            reject('Location not found')
        }
    })
}
