import axios from 'axios'
import { Address, LocationSearch } from '../models/Location'

export const getInfoFromCoords = async (
    lat: number,
    lng: number
): Promise<LocationSearch> => {
    try {
        const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCTm7sXwQNKtccUyry0RZty7qQFiFyCIZw`
        )
        const result = response.data.results[0]
        console.log(result, 'hi')
        return {
            description: result.formatted_address || '',
            maintext:
                `${result.address_components[0].long_name} ${result.address_components[1].long_name}` ||
                '',
            secondarytext: result.address_components[1].long_name || '',
            place_id: result.place_id || '',
            location: {
                lat: lat,
                lng: lng,
            },
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const convertFormattedAddressToDictionary = (
    formattedAddress: string
): Address => {
    const addressPattern = /^(.*?),\s*(.*?),\s*([A-Z]{2})\s*(\d{5})(?:,\s*.*)?$/
    const parts = formattedAddress.split(',')

    let firstAddress = parts[0].trim()
    let city = parts[1].trim()
    let stateZip = parts[2].trim()

    const stateZipPattern = /\s*([A-Z]{2})\s*(\d{5})/
    const stateZipMatch = stateZip.match(stateZipPattern)

    let state = ''
    let zipcode = ''
    if (stateZipMatch) {
        state = stateZipMatch[1]
        zipcode = stateZipMatch[2]
    }

    let secondAddress = ''
    const secondaryPattern = /(.*)\s+(Apt|Suite|Unit|#)\s+(\S+)/i
    const secondaryMatch = firstAddress.match(secondaryPattern)

    if (secondaryMatch) {
        secondAddress = `${secondaryMatch[2]} ${secondaryMatch[3]}`
        firstAddress = secondaryMatch[1]
    }

    return {
        street: firstAddress + (secondAddress ? ` ${secondAddress}` : ''),
        city: city,
        state: state,
        zipcode: zipcode,
    }
}
