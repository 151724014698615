import { Box } from '@mui/material'
import { PrimaryTypography, SecondaryTypography } from '../../StyledComponents'

export enum EmptyTicketsViewType {
    active = 'active',
    expired = 'expired',
}

interface EmptyTicketsViewProps {
    type: EmptyTicketsViewType
}
const EmptyTicketsView = ({ type }: EmptyTicketsViewProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                padding: '20px 20px',
            }}
        >
            <PrimaryTypography
                sx={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}
            >
                No tickets here
            </PrimaryTypography>
            {type === EmptyTicketsViewType.active ? (
                <SecondaryTypography
                    sx={{
                        fontSize: '14px',
                        mt: '8px',
                    }}
                >
                    No active tickets are in your inbox. Attend an event to get
                    a ticket
                </SecondaryTypography>
            ) : (
                type === EmptyTicketsViewType.expired && (
                    <SecondaryTypography
                        sx={{
                            fontSize: '14px',
                            mt: '8px',
                        }}
                    >
                        No expired tickets are in your inbox
                    </SecondaryTypography>
                )
            )}
        </Box>
    )
}

export default EmptyTicketsView
