import {
    AppBar,
    Box,
    IconButton,
    IconButtonProps,
    LinearProgress,
    styled,
    Toolbar,
    Typography,
} from '@mui/material'
import {
    BackgroundIconButton,
    StyledIconButton,
    UserPhotoCircle,
} from '../../Components/StyledComponents'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import { useAppState } from '../../../AppStateProvider'
import { hexToRGBA } from '../../../utils/haxToRGBA'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import ErrorAccountAvatarView from '../../Components/StaticPages/ErrorView/ErrorAccountAvatarView'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { Post } from '../../../models/Post'
import { useEffect, useState } from 'react'
import { grabEventPhoto } from '../../../api/RestEvent'
import { DataStatus } from '../../../models/Dataloading'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import { Event } from '../../../models/Event'
import { NavigateEventState } from '../../../models/NavigateState'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    borderBottom: '1px solid ' + theme.palette.border.secondary,
    background: hexToRGBA('#111111', 0.6),
    position: 'fixed',
    width: `calc(100%)`,
    backdropFilter: 'blur(10px)',
    zIndex: theme.zIndex.drawer + 1,
    // boxShadow: 'none',
    // marginRight: 6,
}))

interface LogoIconButtonProps extends IconButtonProps {
    component?: React.ElementType
    href?: string
}

const LogoIconButton = styled((props: LogoIconButtonProps) => (
    <IconButton {...props} />
))(() => ({
    '&:hover': {
        backgroundColor: 'transparent',
    },
}))

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 5,
    color: theme.palette.text.selected,
    backgroundColor: 'transparent',
    height: 2,
    '& .MuiLinearProgress-bar': {
        color: theme.palette.text.selected,
        backgroundColor: theme.palette.text.selected,
    },
}))

interface TopBarMobileViewProps {}

const EventPostsTopBarMobileView = ({}: TopBarMobileViewProps) => {
    const { state, setState } = useAppState()
    const [eventImage, setEventImage] = useState<string | null>(null)
    const [eventImageStatus, setEventImageStatus] = useState(DataStatus.loading)
    const navigate = useNavigate()
    const location = useLocation()
    const locationEvent = location.state as NavigateEventState
    const [eventState, setEventState] = useState<Event>(locationEvent.event)
    useEffect(() => {
        try {
            grabEventPhoto(eventState.image, state.usertoken)
                .then((response) => {
                    setEventImage(response)
                    setEventImageStatus(DataStatus.success)
                })
                .catch((error) => {
                    console.log(error)
                    setEventImageStatus(DataStatus.failure)
                })
        } catch (error) {
            console.log(error)
            setEventImageStatus(DataStatus.failure)
        }
    }, [])
    return (
        <TopBar
            sx={{
                overflow: 'hidden',
            }}
        >
            {eventImageStatus === DataStatus.success && eventImage && (
                <img
                    src={eventImage}
                    alt="Event"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        position: 'absolute',
                        filter: 'blur(10px)',
                        top: 0,
                        left: 0,
                        zIndex: -1,
                        scale: 1.1,
                    }}
                />
            )}
            <Toolbar
                sx={{
                    minHeight: '55px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                }}
            >
                <BackgroundIconButton
                    sx={{
                        height: '36px',
                        width: '36px',
                    }}
                    onClick={() => {
                        navigate(-1)
                    }}
                >
                    <ArrowBackIosNewRoundedIcon
                        sx={{
                            fontSize: '16px',
                        }}
                    />
                </BackgroundIconButton>
                <Box ml="12px">
                    <Typography
                        sx={{
                            fontSize: '16px',
                            color: 'text.primary',
                            fontWeight: 'bold',
                        }}
                    >
                        {eventState.title}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            bt: 0,
                            color: 'text.secondary',
                        }}
                    >
                        {eventState.post_count} Posts
                    </Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <BackgroundIconButton
                        sx={{
                            height: '36px',
                            width: '36px',
                        }}
                    >
                        <MoreHorizRoundedIcon
                            sx={{
                                fontSize: '16px',
                            }}
                        />
                    </BackgroundIconButton>
                </Box>
            </Toolbar>
        </TopBar>
    )
}

export default EventPostsTopBarMobileView
