import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AppStateProvider } from './AppStateProvider'
import { GoogleOAuthProvider } from '@react-oauth/google'
import WebFont from 'webfontloader'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
WebFont.load({
    google: {
        families: ['Roboto:400,500,700'],
    },
    active: () => {
        root.render(
            <GoogleOAuthProvider clientId="464307113785-e74bbijqpocc2f0r40kirscp7t12alkj.apps.googleusercontent.com">
                <>
                    <BrowserRouter>
                        <AppStateProvider>
                            <App />
                        </AppStateProvider>
                    </BrowserRouter>
                </>
            </GoogleOAuthProvider>
        )
    },
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
