import React, { useEffect, useState } from 'react'
import { Event } from '../../../models/Event'
import { Box, ClickAwayListener, Container, Grid, styled } from '@mui/material'
import { hexToRGBA } from '../../../utils/haxToRGBA'
import { useAppState } from '../../../AppStateProvider'
import {
    NavigateFunction,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom'
import { NavigateCreatePostSelectEventState } from '../../../models/NavigateState'
import ReactDOM from 'react-dom'
import {
    PrimaryTypography,
    StyledCircularProgress,
} from '../../Components/StyledComponents'
import {
    StyledTab,
    StyledTabs,
    TabBox,
} from '../../Components/StyledComponents/StyledTabView'
import { DataStatus } from '../../../models/Dataloading'
import { getEvents } from '../../../api/RestEvent'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import EventSubView from '../../EventView/EventSubView'
import { navigateToCreatePost } from '../../../utils/NavigationFunctions'

const RootView = styled(Box)(({ theme }) => ({
    height: '100%',
    width: '100%',
    position: 'fixed',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
    zIndex: theme.zIndex.drawer + 1,
    // backdropFilter: 'blur(10px)',
    background: hexToRGBA('black', 0.7),
}))

const ContainerBox = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.primary,
    borderRadius: 20,
    padding: '20px 20px',
    minHeight: '60%',
}))

const CreatePostEventSelectorView = () => {
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    const location = useLocation()
    const [pageLoaded, setPageLoaded] = useState(false)
    const initialCreatePostEventSelectorState =
        location.state as NavigateCreatePostSelectEventState
    const [selectedEvent, setSelectedEvent] = useState(
        initialCreatePostEventSelectorState.event
    )
    const [eventImage, setEventImage] = useState(
        initialCreatePostEventSelectorState.eventImage
    )
    const [eventHostImage, setEventHostImage] = useState(
        initialCreatePostEventSelectorState.eventHostImage
    )
    const { tabIndex } = useParams<{ tabIndex: string }>()
    const [value, setValue] = useState(tabIndex)
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
        navigate(`/app/createpost/selectevent/${tabIndex}`, {
            state: {
                event: selectedEvent,
                eventImage: eventImage,
                eventHostImage: eventHostImage,
            },
        })
    }
    useEffect(() => {
        if (!pageLoaded) {
            setPageLoaded(true)
        }
    }, [])
    const parentElement = document.getElementById('root')

    return ReactDOM.createPortal(
        <RootView id="create-post-event-selector-view">
            <ClickAwayListener
                onClickAway={() => {
                    navigate('/app/createpost', {
                        state: {
                            event: selectedEvent,
                            eventImage: eventImage,
                            eventHostImage: eventHostImage,
                        },
                    })
                }}
            >
                <ContainerBox maxWidth="md" disableGutters>
                    <Box sx={{ position: 'relative' }}>
                        <PrimaryTypography
                            sx={{
                                textAlign: 'center',
                                fontSize: '20px',
                                fontWeight: 'bold',
                            }}
                        >
                            Select Event
                        </PrimaryTypography>
                        <TabBox marginTop={'8px'}>
                            <StyledTabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                            >
                                <StyledTab label="Recent" value="recent" />
                                <StyledTab label="Trending" value="trending" />
                                <StyledTab
                                    label="Attending"
                                    value="attending"
                                />
                                <StyledTab
                                    label="Favorited"
                                    value="favorited"
                                />
                            </StyledTabs>
                        </TabBox>
                        {value === 'recent' && (
                            <CreatePostEventSelectorRecentsView
                                selectedEvent={selectedEvent}
                                setSelectedEvent={setSelectedEvent}
                                navigate={navigate}
                            />
                        )}
                    </Box>
                </ContainerBox>
            </ClickAwayListener>
        </RootView>,
        parentElement as Element
    )
}

export default CreatePostEventSelectorView

interface CreatePostEventSelectorRecentsViewProps {
    selectedEvent: Event | null
    setSelectedEvent: React.Dispatch<React.SetStateAction<Event>>
    navigate: NavigateFunction
}
const CreatePostEventSelectorRecentsView = ({
    selectedEvent,
    setSelectedEvent,
    navigate,
}: CreatePostEventSelectorRecentsViewProps) => {
    const { state, setState } = useAppState()
    const [eventState, setEventState] = useState<Event[] | null>(null)
    const [eventStateLoaded, setEventStateLoaded] = useState<DataStatus>(
        DataStatus.loading
    )
    useEffect(() => {
        if (eventStateLoaded === DataStatus.loading) {
            getEvents(null, null, null, 5, 5, 0, state.usertoken)
                .then((response) => {
                    setEventState(response.events)
                    setEventStateLoaded(DataStatus.success)
                })
                .catch((error) => {
                    console.error(error)
                    setEventStateLoaded(DataStatus.failure)
                })
        }
    }, [])
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {eventStateLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : eventStateLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : (
                eventStateLoaded === DataStatus.success && (
                    <Box
                        sx={{
                            marginTop: '16px',
                        }}
                    >
                        <Grid container spacing={2}>
                            {eventState?.map((event) => (
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            position: 'relative',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            navigate('/app/createpost', {
                                                state: {
                                                    event: event,
                                                    eventImage: null,
                                                    eventHostImage: null,
                                                    caption: '',
                                                },
                                            })
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                zIndex: 2,
                                                pointerEvents: 'none',
                                            }}
                                        ></Box>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                position: 'relative',
                                                zIndex: 1,
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            <EventSubView event={event} />
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )
            )}
        </Box>
    )
}
