import { useState } from 'react'
import { styled } from '@mui/system'
import { Box, Slider, Typography } from '@mui/material'
import { FilterData } from '../../../models/Filter'

const StyledSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-track': {
        color: theme.palette.background.selected,
    },
    '& .MuiSlider-rail': {
        color: theme.palette.text.tertiary,
    },
    '& .MuiSlider-thumb': {
        color: theme.palette.background.selected,
    },
}))

const PrimaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const SecondaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

interface AttendeesPickerViewProps {
    tempfilterstate: FilterData
    setTempFilterState: React.Dispatch<React.SetStateAction<FilterData>>
    maxAttendees: number
    width: string
}

const AttendeesPickerView = ({
    tempfilterstate,
    setTempFilterState,
    maxAttendees,
    width,
}: AttendeesPickerViewProps) => {
    const [value, setValue] = useState<number[]>(tempfilterstate.attendeeCount)
    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number[])
        setTempFilterState({
            ...tempfilterstate,
            attendeeCount: newValue as number[],
        })
    }
    return (
        <Box sx={{ width: width }}>
            <PrimaryTypography sx={{ fontSize: 16 }}>
                Attendee Count
            </PrimaryTypography>
            <SecondaryTypography sx={{ fontSize: 14 }}>
                {value[0] == 0 && value[1] == maxAttendees
                    ? 'Any number of attendees'
                    : value[0] > 0 && value[1] == maxAttendees
                      ? `At least ${value[0]} attendees`
                      : value[0] == 0 && value[1] < maxAttendees
                        ? `Up to ${value[1]} attendees`
                        : `${value[0]} to ${value[1]} attendees`}
            </SecondaryTypography>
            <Box padding={'0px 0px'}>
                <StyledSlider
                    max={maxAttendees}
                    value={value}
                    step={10}
                    onChange={handleChange}
                    valueLabelDisplay="off"
                    sx={{ paddingBottom: '20px' }}
                />
            </Box>
        </Box>
    )
}

export default AttendeesPickerView
