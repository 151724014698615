import { Box, Chip, Typography, styled } from '@mui/material'
import { CreateEventPreviewProps } from './CreateEventPreviewView'
import exp from 'constants'

const StyledChip = styled(Chip)(({ theme }) => ({
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.tertiary,
}))

const CreateEventPreviewCategoryView = ({
    tempEvent,
}: CreateEventPreviewProps) => {
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'text.tertiary',
                }}
            >
                CATEGORIES
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    gap: 1,
                    marginTop: '8px',
                }}
            >
                {tempEvent.category_list.map((category, index) => (
                    <StyledChip key={index} label={category} />
                ))}
            </Box>
        </Box>
    )
}

export default CreateEventPreviewCategoryView
