import { useEffect, useState } from 'react'
import { Box, Button, IconButton, List, ListItem } from '@mui/material'
import { useAppState } from '../../AppStateProvider'
import { styled } from '@mui/material/styles'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import { PrimaryTypography } from '../Components/StyledComponents'
import SideTrendingEventsView from '../SideViews/SideTrendingEventsView'
import SideLinksView from '../SideViews/SideLinksView'

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.text.selected,
    textTransform: 'none',
    borderRadius: 50,
    '&:hover': {
        backgroundColor: theme.palette.text.selected,
    },
}))

const PostContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    borderRadius: '10px',
    border: '1px solid ' + theme.palette.border.primary,
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.tertiary,
    '&:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
}))

const TopBar = styled('div')(({ theme }) => ({
    borderBottom: '1px solid ' + theme.palette.border.primary,
    zIndex: 3,
}))

const FeedSideView = () => {
    const { state } = useAppState()

    return (
        <Box sx={{ width: '100%', marginTop: '0px' }}>
            <SideTrendingEventsView />
            <SideLinksView />
        </Box>
    )
}

export default FeedSideView
