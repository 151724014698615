import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Box, Button, Typography, Avatar, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
    RegisterRequest,
    RegisterRequestData,
    register,
} from '../../../api/Auth'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import { useNavigate } from 'react-router-dom'
import { useAppState } from '../../../AppStateProvider'
import { uploadPhoto } from '../../../api/RestPhoto'

const PrimaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const SecondaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

const StyledClearRoundedIcon = styled(ClearRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const StyledPersonRoundedIcon = styled(PersonRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.disabled,
}))

const ClearIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.quaternary,
    '&:hover': {
        backgroundColor: theme.palette.background.quaternary,
    },
}))
const CapsuleButton = styled((props: any) => <Button {...props} />)(
    ({ theme, isValid }) => ({
        borderRadius: '50px',
        padding: '10px 20px',
        backgroundColor: theme.palette.text.selected,
        opacity: isValid ? 1 : 1,
        color: theme.palette.text.primary,
        textTransform: 'none',
        fontWeight: 'bold',
        '&:hover': {
            opacity: isValid ? 0.9 : 1,
            backgroundColor: theme.palette.text.selected,
        },
    })
)

const ImageBox = styled(Box)(({ theme }) => ({
    width: 150,
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.background.tertiary,
    cursor: 'pointer',
}))

interface CreateAccountPictureViewProps {
    registerstate: RegisterRequestData
    setRegisterState: React.Dispatch<React.SetStateAction<RegisterRequestData>>
    setRegisterPage: React.Dispatch<React.SetStateAction<number>>
    setAuthenticating: React.Dispatch<React.SetStateAction<boolean>>
}

const CreateAccountPictureView = ({
    registerstate,
    setAuthenticating,
}: CreateAccountPictureViewProps) => {
    const [image, setImage] = useState<string | null>(null)
    const { state, setState } = useAppState()
    const navigate = useNavigate()

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0]
        const reader = new FileReader()

        reader.onloadend = () => {
            setImage(reader.result as string)
        }

        if (file) {
            reader.readAsDataURL(file)
        }
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })

    const removeImage = () => {
        setImage(null)
    }

    return (
        <div>
            <Box sx={{ width: '100%', paddingTop: '20px', mb: 3 }}>
                <Box display="flex" alignItems="center">
                    <PrimaryTypography
                        sx={{ fontSize: 24, fontWeight: 'bold' }}
                    >
                        Profile Picture
                    </PrimaryTypography>
                    <Box sx={{ flexGrow: 1 }} />
                </Box>
                <Box display="flex" alignItems="center">
                    <SecondaryTypography sx={{ fontSize: 14 }}>
                        Add a profile picture to your account
                    </SecondaryTypography>
                    <Box sx={{ flexGrow: 1 }} />
                </Box>
            </Box>
            <Box
                display="flex"
                sx={{
                    width: '100%',
                    padding: '15px 0px',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                    }}
                >
                    <Box
                        display="flex"
                        sx={{
                            width: '100%',
                            justifyContent: 'center',
                            position: 'relative',
                        }}
                    >
                        <ImageBox
                            sx={{ position: 'relative' }}
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            {image ? (
                                <Avatar
                                    src={image}
                                    alt="Circular Image"
                                    sx={{ width: '100%', height: '100%' }}
                                />
                            ) : (
                                <StyledPersonRoundedIcon
                                    sx={{
                                        fontSize: 30,
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                            )}
                        </ImageBox>
                        <ClearIconButton
                            onClick={removeImage}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 20,
                            }}
                        >
                            <StyledClearRoundedIcon />
                        </ClearIconButton>
                    </Box>
                    <SecondaryTypography
                        sx={{ fontSize: 14, textAlign: 'center', mt: 2 }}
                    >
                        Drag or click to upload a picture
                    </SecondaryTypography>
                </Box>
            </Box>
            <CapsuleButton
                color="primary"
                fullWidth
                size="large"
                variant="contained"
                sx={{ mt: 3 }}
                type="submit"
                onClick={() => {
                    setAuthenticating(true)
                    register(registerstate)
                        .then((response) => {
                            if (image) {
                                uploadPhoto(image, response.token)
                                    .then((photoresponse) => {
                                        setState((prevState) => ({
                                            ...prevState,
                                            name: response.name,
                                            username: response.username,
                                            useremail: response.email,
                                            usertoken: response.token,
                                            userimagelink: response.image,
                                            userimage: photoresponse,
                                            userpassword:
                                                registerstate.user.password,
                                        }))
                                        navigate('/app/feed/fyp')
                                        setAuthenticating(false)
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            setAuthenticating(false)
                        })
                }}
            >
                Create Account
            </CapsuleButton>
        </div>
    )
}

export default CreateAccountPictureView
