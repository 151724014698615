import { AppBar, Box, Toolbar, styled } from '@mui/material'
import { useAppState } from '../../AppStateProvider'
import { StyledIconButton } from '../../views/Components/StyledComponents'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 2,
    background: theme.palette.background.primary,
}))

interface SplashTopBarMobileProps {
    navBarOpen: boolean
    setNavBarOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SplashTopBarMobile = ({
    navBarOpen,
    setNavBarOpen,
}: SplashTopBarMobileProps) => {
    const { state, setState } = useAppState()

    return (
        <TopBar>
            <Toolbar style={{ minHeight: 55, paddingLeft: 8, paddingRight: 8 }}>
                <img
                    src={`${process.env.PUBLIC_URL}/logotest03long.png`}
                    height={30}
                />
                <Box sx={{ flexGrow: 1 }} />
                <StyledIconButton onClick={() => setNavBarOpen(!navBarOpen)}>
                    <MenuRoundedIcon />
                </StyledIconButton>
            </Toolbar>
        </TopBar>
    )
}

export default SplashTopBarMobile
