import React, { useRef, useState } from 'react'
import { Post } from '../../models/Post'
import { User } from '../../models/User'
import { Box, Typography } from '@mui/material'
import AccountAvatarSubView from '../AccountView/AccountAvatarSubView'
import AccountPopoverView from '../AccountView/AccountPopoverView'
import {
    PrimaryTypography,
    SecondaryTypography,
    StyledIconButton,
} from '../Components/StyledComponents'
import { timeDifferenceFromCurrentTime } from '../../utils/TimeDifference'
import { DataStatus } from '../../models/Dataloading'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import {
    navigateToAccount,
    navigateToEvent,
} from '../../utils/NavigationFunctions'
import { useNavigate } from 'react-router-dom'
import { useAppState } from '../../AppStateProvider'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
interface PostHeaderViewProps {
    post: Post
    user: User
    setUser: React.Dispatch<React.SetStateAction<User>>
    userImage: string | null
    userImageLoaded: DataStatus
}

const PostHeaderView = ({
    post,
    user,
    setUser,
    userImage,
    userImageLoaded,
}: PostHeaderViewProps) => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const timerRef = useRef<number | null>(null)
    const [openAccountPopover, setOpenAccountPopover] = useState(false)
    const [accountAnchorEl, setAccountAnchorEl] = useState<null | HTMLElement>(
        null
    )
    const handleAccountPopperOpen = (event: any) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        setAccountAnchorEl(event.currentTarget)
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(true)
        }, 400)
    }

    const handleAccountPopperClose = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        timerRef.current = window.setTimeout(() => {
            setOpenAccountPopover(false)
        }, 300)
    }
    return (
        <Box
            display="flex"
            alignItems="center"
            paddingBottom="4px"
            position={'relative'}
        >
            <Box>
                <AccountAvatarSubView
                    user={user}
                    userimage={userImage}
                    userimagestatus={userImageLoaded}
                    handleAccountPopperClose={handleAccountPopperClose}
                    handleAccountPopperOpen={handleAccountPopperOpen}
                    following={user.following}
                    height={35}
                    width={35}
                />
            </Box>

            <Box
                sx={{
                    marginLeft: '4px',
                }}
            >
                <Box display="flex" justifyContent="center">
                    <Typography
                        sx={{
                            fontSize: '14px',
                            color: 'text.primary',
                            cursor: 'pointer',
                            '&:hover': {
                                textDecoration: 'underline',
                            },
                        }}
                        onMouseOver={(event) => handleAccountPopperOpen(event)}
                        onMouseLeave={handleAccountPopperClose}
                        onClick={function (event) {
                            event.stopPropagation()
                            navigateToAccount(
                                user.username,
                                user,
                                userImage,
                                null,
                                navigate,
                                state,
                                setState,
                                'attending'
                            )
                        }}
                    >
                        {user.name}
                    </Typography>
                    {post.is_host ? (
                        <SecurityRoundedIcon
                            sx={{ color: 'text.host', fontSize: 18, ml: '4px' }}
                        />
                    ) : post.attending ? (
                        <PersonRoundedIcon
                            sx={{
                                color: 'text.attending',
                                fontSize: 18,
                                ml: '4px',
                            }}
                        />
                    ) : null}
                    <Box flex={1} />
                    <Typography
                        sx={{
                            fontSize: 14,
                            ml: '4px',
                            color: 'text.secondary',
                        }}
                    >
                        @{user.username}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 14,
                            ml: '4px',
                            color: 'text.secondary',
                        }}
                    >
                        {'\u{2022} ' +
                            timeDifferenceFromCurrentTime(post.created_at)}
                    </Typography>
                </Box>

                <Typography
                    sx={{
                        fontSize: 14,
                        cursor: 'pointer',
                        color: 'text.primary',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }}
                    onClick={function (event) {
                        event.stopPropagation()
                        navigateToEvent(
                            post.event,
                            null,
                            navigate,
                            state,
                            setState,
                            'details'
                        )
                    }}
                >
                    {post.event.title}
                </Typography>
            </Box>
            <StyledIconButton
                sx={{
                    float: 'right',
                    position: 'absolute',
                    right: '0px',
                    top: '0px',
                }}
            >
                <MoreHorizRoundedIcon sx={{ fontSize: 16 }} />
            </StyledIconButton>
            <AccountPopoverView
                user={user}
                setUser={setUser}
                openpopover={openAccountPopover}
                setOpenPopover={setOpenAccountPopover}
                anchorEl={accountAnchorEl}
                setAnchorEl={setAccountAnchorEl}
                timerRef={timerRef}
            />
        </Box>
    )
}

export default PostHeaderView
