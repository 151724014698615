import { useEffect, useState } from 'react'
import { Box, ButtonBase, List, Typography } from '@mui/material'
import { useAppState } from '../../AppStateProvider'
import { getPosts } from '../../api/RestPost'
import { Post } from '../../models/Post'
import {
    PrimaryDivider,
    StyledChip,
    StyledCircularProgress,
} from '../Components/StyledComponents'
import PostView from '../PostView/PostView'
import SortPopper from '../Components/SortPopper'
import { StyledArrowForwardIosRoundedIcon } from '../Components/StyledComponents/StyledIcons'

interface EventPostsViewProps {
    event_slug: string
}

const EventPostsView = ({ event_slug }: EventPostsViewProps) => {
    const { state } = useAppState()
    const [posts, setPosts] = useState<Post[]>([])
    const [loading, setLoading] = useState(true)
    const [sortbyShowState, setSortbyShowState] = useState(false)
    const [sortbyState, setSortByState] = useState('Best')
    const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null)

    const handleSortPopperOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (!sortbyShowState) {
            setSortAnchorEl(event.currentTarget)
            setSortbyShowState(true)
        } else {
            setSortAnchorEl(null)
            setSortbyShowState(false)
        }
    }
    useEffect(() => {
        getPosts(null, null, event_slug, 20, 0, state.usertoken).then(
            (response) => {
                if ('errorCode' in response) {
                    setLoading(false)
                } else {
                    setPosts(response.posts)
                    setLoading(false)
                }
            }
        )
    }, [])

    return (
        <Box sx={{ width: '100%', marginTop: '4px' }}>
            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '20px',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : (
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            margin: '8px 0px',
                            alignItems: 'center',
                            ml: '10px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 13,
                                fontWeight: 'bold',
                                color: 'text.tertiary',
                            }}
                        >
                            Sort by:
                        </Typography>
                        <SortPopper
                            sortOptions={['Best', 'New', 'Top']}
                            sortbyState={sortbyState}
                            setSortByState={setSortByState}
                            openpopover={sortbyShowState}
                            setOpenPopover={setSortbyShowState}
                            anchorEl={sortAnchorEl}
                            setAnchorEl={setSortAnchorEl}
                        />
                        <StyledChip
                            sx={{
                                ml: '4px',
                                cursor: 'pointer',
                                background: (theme) =>
                                    theme.palette.background.tertiary,
                            }}
                            component={ButtonBase}
                            onClick={handleSortPopperOpen}
                        >
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontWeight: 'bold',
                                    color: 'text.secondary',
                                }}
                            >
                                {sortbyState}
                            </Typography>
                            <StyledArrowForwardIosRoundedIcon
                                sx={{ fontSize: 13, ml: '4px' }}
                                open={sortbyShowState}
                            />
                        </StyledChip>
                    </Box>
                    <List sx={{ padding: 0 }}>
                        {posts.map((post) => (
                            <Box key={post.uuid} sx={{ padding: 0 }}>
                                <PostView post={post} />
                                <PrimaryDivider
                                    sx={{
                                        marginTop: '4px',
                                        marginBottom: '4px',
                                    }}
                                />
                            </Box>
                        ))}
                    </List>
                </Box>
            )}
        </Box>
    )
}

export default EventPostsView
