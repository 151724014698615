import { Box, Typography } from '@mui/material'
import { User } from '../../../models/User'
import {
    FollowButton,
    PrimaryTypography,
    SecondaryTypography,
    TertiaryTypography,
    UnfollowButton,
} from '../../Components/StyledComponents'
import { useEffect, useRef, useState } from 'react'
import { DataStatus } from '../../../models/Dataloading'
import { followUser, grabUserPhoto, unfollowUser } from '../../../api/RestUser'
import { useAppState } from '../../../AppStateProvider'
import { styled } from '@mui/system'
import AccountDetailsSubView from '../../AccountView/AccountSubViews/AccountDetailsSubView'

const UserPhotoCircle = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
}))

interface EventHostDetailsViewProps {
    host: User
}

const EventHostDetailsView = ({ host }: EventHostDetailsViewProps) => {
    const { state } = useAppState()
    const [userState, setUserState] = useState<User>(host)
    const [hostImage, setHostImage] = useState<string | null>(null)
    const [hostImageLoaded, setHostImageLoaded] = useState<DataStatus>(
        DataStatus.loading
    )

    useEffect(() => {
        if (hostImageLoaded === DataStatus.loading) {
            try {
                grabUserPhoto(host.image, state.usertoken)
                    .then((response) => {
                        setHostImage(response)
                        setHostImageLoaded(DataStatus.success)
                    })
                    .catch((error) => {
                        setHostImageLoaded(DataStatus.failure)
                    })
            } catch (error) {
                setHostImageLoaded(DataStatus.failure)
            }
        }
    }, [])
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'text.tertiary',
                }}
            >
                HOST
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    marginTop: '8px',
                }}
            >
                <AccountDetailsSubView
                    user={userState}
                    followButton={true}
                    bio={false}
                    size={40}
                />
            </Box>
        </Box>
    )
}

export default EventHostDetailsView
