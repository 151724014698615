import {
    Box,
    ClickAwayListener,
    Container,
    IconButton,
    InputBase,
    Typography,
    styled,
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    NavigateCreateEventLocationState,
    NavigateCreateEventState,
} from '../../../models/NavigateState'
import { useState } from 'react'
import { LocationSearch } from '../../../models/Location'
import ReactDOM from 'react-dom'
import {
    BackgroundFullPopperView,
    DefaultButton,
    PrimaryTypography,
    SecondaryDivider,
    SelectButton,
    StyledChip,
    StyledIconButton,
} from '../../Components/StyledComponents'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

const ContainerBox = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.primary,
    borderRadius: 20,
    padding: '20px 20px',
    minHeight: '60%',
}))

const TagsContainer = styled(Box)(({ theme }) => ({
    borderRadius: '10px',
    border: '1px solid ' + theme.palette.border.primary,
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    backgroundColor: theme.palette.background.tertiary,
    color: theme.palette.text.primary,
    width: '100%',
}))

const CreateEventEditTagsView = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const initialTempEventData =
        location.state as NavigateCreateEventLocationState
    const [tempEventData, setTempEventData] = useState(
        initialTempEventData.tempEventData
    )
    const [tagsState, setTagsState] = useState<string[] | null>(
        initialTempEventData.tempEventData.tag_list
    )
    const parentElement = document.getElementById('root')
    const handleSubmit = () => {
        if (tagsState) {
            navigate('/app/createevent', {
                state: {
                    tempEventData: {
                        ...tempEventData,
                        tag_list: tagsState,
                    },
                },
            })
        }
    }
    const [tagInput, setTagInput] = useState<string>('')
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTagInput(event.target.value)
    }
    const handleAddTag = () => {
        if (tagsState) {
            setTagsState([...tagsState, tagInput])
            setTagInput('')
        } else {
            setTagsState([tagInput])
            setTagInput('')
        }
    }

    const handleRemoveTag = (tag: string) => {
        if (tagsState) {
            setTagsState(tagsState.filter((item) => item !== tag))
        }
    }
    return ReactDOM.createPortal(
        <BackgroundFullPopperView
            id="create-event-edit-location-view"
            sx={{
                top: '0',
            }}
        >
            <ClickAwayListener
                onClickAway={() =>
                    navigate('/app/createevent', {
                        state: { tempEventData: tempEventData },
                    })
                }
            >
                <ContainerBox maxWidth="sm" disableGutters>
                    <Box>
                        <Box
                            sx={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <StyledIconButton
                                onClick={() =>
                                    navigate('/app/createevent', {
                                        state: { tempEventData: tempEventData },
                                    })
                                }
                            >
                                <CloseRoundedIcon
                                    sx={{
                                        fontSize: 20,
                                        color: 'text.primary',
                                    }}
                                />
                            </StyledIconButton>

                            <SelectButton
                                onClick={handleSubmit}
                                disabled={tagsState === tempEventData.tag_list}
                            >
                                Done
                            </SelectButton>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    display: 'flex',
                                    pointerEvents: 'none',
                                }}
                            >
                                <Typography
                                    sx={{
                                        justifyContent: 'center',
                                        alignText: 'center',
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        color: 'text.primary',
                                    }}
                                >
                                    Edit Tags
                                </Typography>
                            </Box>
                        </Box>
                        <SecondaryDivider sx={{ mt: '8px' }} />
                    </Box>
                    <Box sx={{ mt: '16px' }}>
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                color: 'text.tertiary',
                            }}
                        >
                            ADD OR REMOVE TAGS
                        </Typography>
                        <TagsContainer
                            sx={{
                                minHeight: '200px',
                                width: '100%',
                                mt: '8px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '8px',
                                    padding: '8px 8px',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                {tagsState?.map((tag, index) => (
                                    <StyledChip key={index}>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                color: 'text.secondary',
                                            }}
                                        >
                                            #{tag}
                                        </Typography>
                                        <IconButton
                                            sx={{
                                                backgroundColor:
                                                    'background.quaternary',
                                                padding: '4px',
                                                mr: '-4px',
                                                ml: '4px',
                                            }}
                                            onClick={() => handleRemoveTag(tag)}
                                        >
                                            <CloseRoundedIcon
                                                sx={{
                                                    fontSize: 16,
                                                    color: 'text.primary',
                                                }}
                                            />
                                        </IconButton>
                                    </StyledChip>
                                ))}
                            </Box>
                        </TagsContainer>
                        <StyledChip
                            sx={{
                                mt: '16px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                <StyledInputBase
                                    placeholder="Add a tag"
                                    value={tagInput}
                                    onChange={handleChange}
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === 'Enter' &&
                                            tagInput !== '' &&
                                            !tagsState?.includes(tagInput)
                                        ) {
                                            handleAddTag()
                                        }
                                    }}
                                    sx={{
                                        ml: '8px',
                                        fontSize: 14,
                                    }}
                                />

                                <DefaultButton
                                    disabled={
                                        tagInput === '' ||
                                        tagsState?.includes(tagInput)
                                    }
                                    sx={{
                                        ml: 'auto',
                                        whiteSpace: 'nowrap',
                                    }}
                                    onClick={handleAddTag}
                                >
                                    Add Tag
                                </DefaultButton>
                            </Box>
                        </StyledChip>
                    </Box>
                </ContainerBox>
            </ClickAwayListener>
        </BackgroundFullPopperView>,
        parentElement as Element
    )
}

export default CreateEventEditTagsView
