import { Box, Chip, Typography, styled } from '@mui/material'
import {
    StyledCircularProgress,
    TertiaryTypography,
} from '../../Components/StyledComponents'
import { Event } from '../../../models/Event'
import { useEffect, useState } from 'react'
import { useAppState } from '../../../AppStateProvider'
import { Post } from '../../../models/Post'
import { DataStatus } from '../../../models/Dataloading'
import { getPosts } from '../../../api/RestPost'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import PostMobileView from '../../PostView/PostMobileView/PostMobileView'
import PostSubEventMobileView from '../../PostView/PostMobileView/PostSubEventMobieView'
import { navigateToEvent } from '../../../utils/NavigationFunctions'
import { useNavigate } from 'react-router-dom'
import AccountAvatarSubView from '../../AccountView/AccountAvatarSubView'
import { User } from '../../../models/User'
import { grabUserPhoto } from '../../../api/RestUser'

interface EventDetailsAttendeesMobileViewProps {
    event: Event
    eventImage: string | null
}

const PostBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    borderRadius: '10px',
    border: '1px solid ' + theme.palette.border.primary,
    minWidth: 'calc(100% - 16px)',
}))
const EventDetailsAttendeesMobileView = ({
    event,
    eventImage,
}: EventDetailsAttendeesMobileViewProps) => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'text.tertiary',
                }}
            >
                ATTENDEES
            </Typography>
            <Box
            sx={{
                display: 'flex',
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    position: 'relative',
                    height: '60px',
                    mt: '16px',
                    maxWidth: '33%'
                }}
            >
                {event.attendees.map((attendee, index) => (
                    index < 5 && (
                    <Box
                    sx={{
                        position: 'absolute',
                        top: '0px',
                        left: `${index * 15}px`,
                    }}>
                        <AttendeeSubMobileView attendee={attendee} />
                    </Box>
                    )
                ))}
            </Box>
            </Box>
        </Box>
    )
}

interface AttendeeSubMobileViewProps {
    attendee: User
}
const AttendeeSubMobileView = ({
    attendee
}:AttendeeSubMobileViewProps) => {
    const [userImage, setUserImage] = useState<string | null>(null)
    const [userImageStatus, setUserImageStatus] = useState<DataStatus>(DataStatus.loading)
    const {state,setState} = useAppState()
    useEffect(() => {
        if (attendee.image) {
            grabUserPhoto(attendee.image,state.usertoken)
            .then((response) => {
                setUserImage(response)
                setUserImageStatus(DataStatus.success)
            })
            .catch((error) => {
                setUserImageStatus(DataStatus.failure)
            })
        }
    },[])
    return (
            <AccountAvatarSubView
                user={attendee}
                userimage={userImage}
                userimagestatus={userImageStatus}
                handleAccountPopperClose={() => {}}
                handleAccountPopperOpen={() => {}}
                following={false}
                height={40}
                width={40}
                showFollowButton={false}
            />
    )
}

export default EventDetailsAttendeesMobileView
