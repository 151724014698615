import { Box } from '@mui/material'
import { PrimaryTypography, SecondaryTypography } from '../../StyledComponents'

interface EmptyCommentsViewProps {}
const EmptyCommentsView = ({}: EmptyCommentsViewProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                padding: '20px 20px',
            }}
        >
            <PrimaryTypography
                sx={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}
            >
                No comments
            </PrimaryTypography>
            <SecondaryTypography
                sx={{
                    fontSize: '14px',
                    mt: '8px',
                }}
            >
                No one has made any comments on this post. Be the first to
                comment!
            </SecondaryTypography>
        </Box>
    )
}

export default EmptyCommentsView
