import {
    Box,
    ClickAwayListener,
    Fade,
    List,
    ListItem,
    ListItemProps,
    Popover,
    Popper,
} from '@mui/material'
import { PrimaryTypography, TertiaryTypography } from './StyledComponents'
import { styled } from '@mui/system'
import React from 'react'

interface SortPopperProps {
    sortOptions: string[]
    sortbyState: string
    setSortByState: React.Dispatch<React.SetStateAction<string>>
    openpopover: boolean
    setOpenPopover: (open: boolean) => void
    anchorEl: HTMLElement | null
    setAnchorEl: (element: HTMLElement | null) => void
}

const StyledPopper = styled(Popper)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 10,
    },
}))

interface StyledListItemProps extends ListItemProps {
    select: boolean
}
const StyledListItem = styled((props: StyledListItemProps) => (
    <ListItem {...props} />
))(({ theme, select }) => ({
    borderRadius: 10,
    fontSize: 14,
    color: theme.palette.text.tertiary,
    backgroundColor: 'transparent',
    paddingLeft: '12px',
    minWidth: '100px',
    '&:hover': {
        backgroundColor: theme.palette.background.hover,
    },
    ...(select && {
        backgroundColor: theme.palette.background.tertiary,
        color: theme.palette.text.primary,
        '&:hover': {
            backgroundColor: theme.palette.background.tertiary,
        },
    }),
}))

const ContainerPopper = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
    borderRadius: 10,
    padding: '16px',
    cursor: 'pointer',
}))

const SortPopper = (props: SortPopperProps) => {
    const {
        sortOptions,
        sortbyState,
        setSortByState,
        openpopover,
        setOpenPopover,
        anchorEl,
        setAnchorEl,
    } = props
    const handleClickAway = () => {
        if (anchorEl) {
            setOpenPopover(false)
        }
    }
    return (
        // <ClickAwayListener onClickAway={handleClickAway}>
        <StyledPopper
            open={openpopover}
            anchorEl={anchorEl}
            sx={{
                borderRadius: 10,
            }}
            placement="bottom-start"
            transition
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                    <ContainerPopper>
                        <TertiaryTypography
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                            }}
                        >
                            SORT BY
                        </TertiaryTypography>
                        <List>
                            {sortOptions.map((sortOption) => (
                                <StyledListItem
                                    key={sortOption}
                                    onClick={function () {
                                        setSortByState(sortOption)
                                        setOpenPopover(false)
                                    }}
                                    select={sortOption === sortbyState}
                                    sx={{
                                        mt: '4px',
                                    }}
                                >
                                    {sortOption}
                                </StyledListItem>
                            ))}
                        </List>
                    </ContainerPopper>
                </Fade>
            )}
        </StyledPopper>
        // </ClickAwayListener>
    )
}

export default SortPopper
