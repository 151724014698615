import { Box } from '@mui/material'
import { PrimaryTypography, SecondaryTypography } from '../../StyledComponents'

interface EmptyAttendingViewProps {
    name: string
}
const EmptyAttendingView = ({ name }: EmptyAttendingViewProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                padding: '20px 20px',
            }}
        >
            <PrimaryTypography
                sx={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}
            >
                No events attending
            </PrimaryTypography>
            <SecondaryTypography
                sx={{
                    fontSize: '14px',
                    mt: '8px',
                }}
            >
                {name} has not attended any events. Check back later for updates
            </SecondaryTypography>
        </Box>
    )
}

export default EmptyAttendingView
