import { Box, Typography, SelectChangeEvent } from '@mui/material'
import { useEffect, useState } from 'react'
import { IOSSwitch } from '../../DiscoveryView/FilterItems/FollowersPickerView'
import { CreateEvent } from '../../../models/CreateEvent'
import {
    datetime2iso,
    iso2date,
    time2Date,
} from '../../../utils/DateStringConverter'
import { DateRange } from '@mui/icons-material'
import {
    StyledMenuItem,
    StyledSelect,
    StyledTextField,
} from '../../Components/StyledComponents'
import DateRangePopperCalendar from '../../Components/DateRangePopperCalendar'
import { format, addMinutes, isSameDay } from 'date-fns'
import { thirtyMinuteIncrement } from '../../../constants'
import { date } from 'yup'

interface CreateEventDateViewProps {
    tempEventData: CreateEvent
    setTempEventData: React.Dispatch<React.SetStateAction<CreateEvent>>
}

const CreateEventDateView = ({
    tempEventData,
    setTempEventData,
}: CreateEventDateViewProps) => {
    const [dateRangeSelector, setDateRangeSelector] = useState<boolean>(true)
    const handleCategoryChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setDateRangeSelector(event.target.checked)
    }
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>(
        tempEventData.event_time
            ? [
                  iso2date(tempEventData.event_time),
                  iso2date(tempEventData.end_time),
              ]
            : [new Date(), null]
    )
    const [openDateRangePopper, setOpenDateRangePopper] =
        useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const handleDateRangePopperOpen = (
        event: React.MouseEvent<HTMLElement>
    ) => {
        setAnchorEl(event.currentTarget)
        setOpenDateRangePopper(true)
    }
    const [startTime, setStartTime] = useState<string>(
        tempEventData.event_time
            ? format(iso2date(tempEventData.event_time), 'hh:mm a')
            : format(thirtyMinuteIncrement[23], 'hh:mm a')
    )
    const [endTime, setEndTime] = useState<string>(
        tempEventData.end_time
            ? format(iso2date(tempEventData.end_time), 'hh:mm a')
            : format(thirtyMinuteIncrement[24], 'hh:mm a')
    )
    const handleStartTimeChange = (event: SelectChangeEvent<unknown>) => {
        setStartTime(event.target.value as string)
        console.log(event.target.value)
        // if (!dateRangeSelector){}
    }
    useEffect(() => {
        if (dateRangeSelector) {
            if (dateRange[0] && dateRange[1]) {
                setTempEventData({
                    ...tempEventData,
                    event_time: datetime2iso(dateRange[0], startTime),
                    end_time: datetime2iso(dateRange[1], endTime),
                })
            }
        }
    }, [dateRange, startTime, endTime])
    useEffect(() => {
        if (dateRangeSelector && dateRange[0] && dateRange[1]) {
            console.log('hii', dateRange)
            if (isSameDay(dateRange[0], dateRange[1])) {
                console.log('hiis', endTime)
                setEndTime(
                    format(addMinutes(time2Date(endTime), 30), 'hh:mm a')
                )
            }
        }
    }, [dateRange])
    const handleEndTimeChange = (event: SelectChangeEvent<unknown>) => {
        setEndTime(event.target.value as string)
        // if (!dateRangeSelector){}
    }
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: 'text.secondary',
                }}
            >
                Event Date
            </Typography>

            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        mt: '0px',
                        alignItems: 'center',
                    }}
                >
                    {dateRangeSelector ? (
                        <>
                            <StyledTextField
                                label="Date Range"
                                sx={{
                                    width: '100%',
                                    cursor: 'pointer',
                                }}
                                size="small"
                                value={
                                    dateRange[0] && dateRange[1]
                                        ? `${format(dateRange[0], 'MM/dd/yyyy')} - ${format(dateRange[1], 'MM/dd/yyyy')}`
                                        : ''
                                }
                                onClick={handleDateRangePopperOpen}
                            />
                            <DateRangePopperCalendar
                                value={dateRange}
                                setValue={setDateRange}
                                openPopper={openDateRangePopper}
                                setOpenPopper={setOpenDateRangePopper}
                                anchorEl={anchorEl}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                    <StyledSelect
                        sx={{
                            ml: '8px',
                            cursor: 'pointer',
                            height: '38px',
                        }}
                        size="small"
                        value={startTime}
                        name="startTime"
                        onChange={handleStartTimeChange}
                    >
                        {thirtyMinuteIncrement.map((time, index) => (
                            <StyledMenuItem
                                key={index}
                                value={format(time, 'hh:mm a')}
                            >
                                {format(time, 'hh:mm a')}
                            </StyledMenuItem>
                        ))}
                    </StyledSelect>
                    <StyledSelect
                        sx={{
                            ml: '8px',
                            cursor: 'pointer',
                            height: '38px',
                        }}
                        size="small"
                        value={endTime}
                        name="End Time"
                        placeholder="End Time"
                        onChange={handleEndTimeChange}
                    >
                        {thirtyMinuteIncrement.map((time, index) => (
                            <StyledMenuItem
                                key={index}
                                value={format(time, 'hh:mm a')}
                            >
                                {format(time, 'hh:mm a')}
                            </StyledMenuItem>
                        ))}
                    </StyledSelect>
                </Box>
                <Box
                    sx={{
                        mt: '0px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            color: 'text.primary',
                        }}
                    >
                        Date Range
                    </Typography>
                    <IOSSwitch
                        sx={{
                            marginLeft: 'auto',
                        }}
                        checked={dateRangeSelector}
                        onChange={handleCategoryChange}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default CreateEventDateView
