import React, { forwardRef } from 'react'
import MaskedInput, { MaskedInputProps } from 'react-text-mask'

export const DayMask = forwardRef<MaskedInput, MaskedInputProps>(
    function DayMask(props, ref) {
        const { ...other } = props
        return (
            <MaskedInput
                {...other}
                ref={ref}
                mask={[/\d/, /\d/]}
                placeholderChar={'\u2000'}
                showMask
                guide={true}
            />
        )
    }
)

export const YearMask = forwardRef<MaskedInput, MaskedInputProps>(
    function YearMask(props, ref) {
        const { ...other } = props
        return (
            <MaskedInput
                {...other}
                ref={ref}
                mask={[/\d/, /\d/, /\d/, /\d/]}
                placeholderChar={'\u2000'}
                showMask
                guide={true}
            />
        )
    }
)
