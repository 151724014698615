import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
    Box,
    Button,
    Grid,
    Link,
    TextField,
    Typography,
    IconButton,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Checkbox,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { RegisterRequest, RegisterRequestData } from '../../../api/Auth'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { DayMask, YearMask } from '../../../utils/MaskedInputs'

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

const PrimaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const SecondaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

const StyledLink = styled((props: any) => <Link {...props} />)(({ theme }) => ({
    color: theme.palette.text.selected,
}))

const StyledVisibility = styled(Visibility)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

const StyledVisibilityOff = styled(VisibilityOff)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

const PasswordIconButton = styled((props: any) => <IconButton {...props} />)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: theme.palette.background.tertiary,
        },
    })
)

const CapsuleButton = styled((props: any) => <Button {...props} />)(
    ({ theme, isValid }) => ({
        borderRadius: '50px',
        padding: '10px 20px',
        backgroundColor: theme.palette.text.selected,
        opacity: isValid ? 1 : 0.5,
        color: theme.palette.text.primary,
        textTransform: 'none',
        fontWeight: 'bold',
        '&:hover': {
            opacity: isValid ? 0.9 : 0.5,
            backgroundColor: theme.palette.text.selected,
        },
    })
)

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    color: theme.palette.text.secondary,
    '&.Mui-checked': {
        color: theme.palette.text.selected,
    },
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
    margin: '10px 0',
    '& label.Mui-focused': {
        color: theme.palette.text.secondary,
    },
    '& .MuiInputBase-input': {
        fontSize: 14,
    },
    '& .MuiInputLabel-root': {
        fontSize: 14,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.border.primary,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.text.secondary,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.text.selected,
        },
    },
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '&.Mui-selected': {
        color: theme.palette.text.selected,
    },
    '&.Mui-selected:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
}))

const StyledSelect = styled(Select)(({ theme }) => ({
    '& label.Mui-focused': {
        color: theme.palette.text.secondary,
    },
    '& .MuiInputBase-input': {
        fontSize: 14,
    },
    '& .MuiInputLabel-root': {
        fontSize: 14,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.border.primary,
        '&.Mui-focused': {
            borderColor: theme.palette.text.selected,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.text.secondary,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.text.selected,
        },
    },
}))

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
    fontSize: 14,
    color: theme.palette.text.secondary,
}))

export interface CreateAccountFormValues {
    email: string
    name: string
    username: string
    password: string
    month: string
    day: string
    year: string
    terms: boolean
}

interface CreateAccountDetailsViewProps {
    setShowLogin: React.Dispatch<React.SetStateAction<boolean>>
    setShowRegister: React.Dispatch<React.SetStateAction<boolean>>
    setRegisterState: React.Dispatch<React.SetStateAction<RegisterRequestData>>
    setRegisterPage: React.Dispatch<React.SetStateAction<number>>
}

const CreateAccountDetailsView = ({
    setShowLogin,
    setShowRegister,
    setRegisterState,
    setRegisterPage,
}: CreateAccountDetailsViewProps) => {
    const [showPassword, setShowPassword] = useState(false)

    return (
        <Formik
            initialValues={{
                email: '',
                name: '',
                username: '',
                password: '',
                month: '',
                day: '',
                year: '',
                terms: false,
            }}
            initialErrors={{
                email: 'Email is required',
                name: 'Name is required',
                username: 'Username is required',
                password: 'Password is required',
                month: 'Month is required',
                day: 'Day is required',
                year: 'Year is required',
                terms: 'Terms and Conditions must be accepted',
            }}
            validate={(values) => {
                const errors: Partial<CreateAccountFormValues> = {}
                if (!values.email) {
                    errors.email = 'Email is required'
                }
                if (!values.name) {
                    errors.name = 'Name is required'
                }
                if (!values.username) {
                    errors.username = 'Username is required'
                }
                if (!values.password) {
                    errors.password = 'Password is required'
                }
                if (!values.month) {
                    errors.month = 'Month is required'
                }
                if (!values.day) {
                    errors.day = 'Day is required'
                }
                if (!values.year) {
                    errors.year = 'Year is required'
                }
                if (!values.terms) {
                    errors.terms = false
                }
                return errors
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string().email().min(2).max(255).required(),
                name: Yup.string().max(30).required(),
                username: Yup.string().max(30).required(),
                password: Yup.string().min(6).max(255).required(),
                month: Yup.number().required(),
                day: Yup.string().min(2).max(2).required(),
                year: Yup.string().min(4).max(4).required(),
                terms: Yup.boolean().equals([true]).required(),
            })}
            onSubmit={(values) => {
                setRegisterState({
                    user: {
                        email: values.email,
                        password: values.password,
                        username: values.username,
                        name: values.name,
                        birth_date: `${values.year}-${values.month.toString().padStart(2, '0')}-${values.day}`,
                    },
                })
                setRegisterPage(1)
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                isValid,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box sx={{ width: '100%', paddingTop: '20px', mb: 2 }}>
                        <Box display="flex" alignItems="center">
                            <PrimaryTypography
                                sx={{ fontSize: 24, fontWeight: 'bold' }}
                            >
                                Create Account
                            </PrimaryTypography>
                            <Box sx={{ flexGrow: 1 }} />
                        </Box>
                        <Box display="flex" alignItems="center">
                            <SecondaryTypography sx={{ fontSize: 14 }}>
                                Enter your details below
                            </SecondaryTypography>
                            <Box sx={{ flexGrow: 1 }} />
                        </Box>
                    </Box>

                    <Grid container spacing={0}>
                        <Grid item xs={12} md={12}>
                            <StyledTextField
                                fullWidth
                                size="small"
                                label="Email Address"
                                margin="normal"
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="email"
                                value={values.email}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <StyledTextField
                                fullWidth
                                size="small"
                                label="Name"
                                margin="normal"
                                name="name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="name"
                                value={values.name}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <StyledTextField
                                fullWidth
                                size="small"
                                label="Username"
                                margin="normal"
                                name="username"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="username"
                                value={values.username}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <StyledTextField
                                fullWidth
                                size="small"
                                label="Password"
                                margin="normal"
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                variant="outlined"
                                sx={{ position: 'relative' }}
                                InputProps={{
                                    endAdornment: (
                                        <PasswordIconButton
                                            onClick={() =>
                                                setShowPassword(!showPassword)
                                            }
                                            sx={{
                                                position: 'absolute',
                                                right: 0,
                                            }}
                                        >
                                            {showPassword ? (
                                                <StyledVisibilityOff
                                                    sx={{ fontSize: 20 }}
                                                />
                                            ) : (
                                                <StyledVisibility
                                                    sx={{ fontSize: 20 }}
                                                />
                                            )}
                                        </PasswordIconButton>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <PrimaryTypography
                                sx={{
                                    fontSize: 14,
                                    textAlign: 'left',
                                    marginTop: '10px',
                                    marginBottom: '5px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Date of Birth
                            </PrimaryTypography>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                alignContent="center"
                            >
                                <FormControl
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                >
                                    <StyledInputLabel id="demo-select-small-label">
                                        Month
                                    </StyledInputLabel>
                                    <StyledSelect
                                        label="Month"
                                        value={values.month}
                                        onChange={handleChange}
                                        name="month"
                                    >
                                        {months.map((month, index) => (
                                            <StyledMenuItem
                                                key={index}
                                                value={index}
                                            >
                                                {month}
                                            </StyledMenuItem>
                                        ))}
                                    </StyledSelect>
                                </FormControl>
                                <StyledTextField
                                    fullWidth
                                    sx={{
                                        margin: '0 10px',
                                        '& .MuiInputBase-root': {
                                            height: 42,
                                        },
                                    }}
                                    inputProps={{
                                        inputComponent: DayMask as any,
                                    }}
                                    size="small"
                                    name="day"
                                    label="Day"
                                    margin="normal"
                                    onBlur={handleBlur}
                                    value={values.day}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                                <StyledTextField
                                    fullWidth
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            height: 42,
                                        },
                                    }}
                                    inputProps={{
                                        inputComponent: YearMask,
                                    }}
                                    size="small"
                                    name="year"
                                    label="Year"
                                    margin="normal"
                                    value={values.year}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box
                                display="flex"
                                alignItems="center"
                                alignContent="center"
                            >
                                <StyledCheckbox
                                    sx={{ fontSize: 14 }}
                                    name="terms"
                                    value={values.terms}
                                    onChange={handleChange}
                                />
                                <SecondaryTypography sx={{ fontSize: 14 }}>
                                    I agree to the{' '}
                                    <StyledLink
                                        component={RouterLink}
                                        fontSize={14}
                                    >
                                        Terms and Conditions
                                    </StyledLink>
                                </SecondaryTypography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ py: 2 }}>
                        <CapsuleButton
                            color="primary"
                            fullWidth
                            size="medium"
                            variant="contained"
                            isValid={isValid}
                            sx={{ mt: 2 }}
                            type="submit"
                        >
                            Continue
                        </CapsuleButton>
                    </Box>
                    <SecondaryTypography fontSize={14}>
                        Have an account already?{' '}
                        <StyledLink
                            component={RouterLink}
                            onClick={function () {
                                setShowLogin(true)
                                setShowRegister(false)
                            }}
                            fontSize={14}
                        >
                            Log in
                        </StyledLink>
                    </SecondaryTypography>
                </form>
            )}
        </Formik>
    )
}

export default CreateAccountDetailsView
