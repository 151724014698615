import {
    Box,
    AppBar,
    Toolbar,
    Divider,
    Typography,
    IconButton,
    Button,
} from '@mui/material'
import { styled } from '@mui/material'
import { FilterData } from '../../models/Filter'
import DatePickerView from './FilterItems/DatePickerView'
import RadiusPickerView from './FilterItems/RadiusPickerView'
import AttendeesPickerView from './FilterItems/AttendeesPickerView'
import EventTypePickerView from './FilterItems/EventTypePickerView'
import FollowersPickerView from './FilterItems/FollowersPickerView'
import CategoriesPickerView from './FilterItems/CategoriesPickerView'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import { FilterDateChoice } from '../../models/Filter'
import { alpha } from '@mui/system'

const StyledDivider = styled(Divider)(({ theme }) => ({
    color: theme.palette.border.primary,
    marginTop: 10,
    marginBottom: 20,
}))

const StyledClearRoundedIcon = styled(ClearRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const PrimaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

const ApplyButton = styled(Button)(({ theme }) => ({
    borderRadius: 50,
    backgroundColor: theme.palette.background.selected,
    color: theme.palette.text.primary,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '18px 20px',
    fontSize: 16,
    height: 18,
    width: '100%',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    marginTop: 5,
    '&:hover': {
        backgroundColor: theme.palette.background.selected,
    },
}))

const ApplyButtonDisabled = styled(Button)(({ theme }) => ({
    borderRadius: 50,
    backgroundColor: alpha(theme.palette.background.selected, 0.5),
    color: theme.palette.text.tertiary,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '18px 20px',
    fontSize: 16,
    height: 18,
    width: '100%',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    marginTop: 5,
    '&:hover': {
        backgroundColor: alpha(theme.palette.background.selected, 0.5),
    },
}))

const TopBar = styled(AppBar)(({ theme }) => ({
    background: theme.palette.background.secondary,
    width: 500,
    top: 0,
    right: 0,
    posistion: 'static',
    boxShadow: 'none',
    height: 55,
}))

const BottomBar = styled(AppBar)(({ theme }) => ({
    background: theme.palette.background.secondary,
    width: 500,
    bottom: 0,
    top: 'auto',
    right: 0,
    posistion: 'fixed',
    boxShadow: 'none',
    height: 55,
}))

interface AllFiltersViewProps {
    setFilterDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
    filterstate: FilterData
    setFilterState: React.Dispatch<React.SetStateAction<FilterData>>
    tempfilterstate: FilterData
    setTempFilterState: React.Dispatch<React.SetStateAction<FilterData>>
    maxDistance: number
    maxAttendees: number
}

const AllFiltersView = ({
    setFilterDrawerOpen,
    filterstate,
    setFilterState,
    tempfilterstate,
    setTempFilterState,
    maxDistance,
    maxAttendees,
}: AllFiltersViewProps) => {
    return (
        <Box>
            <TopBar>
                <Toolbar style={{ minHeight: 55, padding: '0px 20px' }}>
                    <PrimaryTypography
                        sx={{ fontSize: 18, fontWeight: 'bold' }}
                    >
                        Event filters
                    </PrimaryTypography>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton
                        sx={{ padding: 0 }}
                        onClick={() => setFilterDrawerOpen(false)}
                    >
                        <StyledClearRoundedIcon sx={{ fontSize: 24 }} />
                    </IconButton>
                </Toolbar>
                <StyledDivider
                    sx={{ marginTop: 0, marginBottom: 0, padding: 0 }}
                />
            </TopBar>
            <Box
                style={{
                    padding: '0px 20px',
                    marginTop: '65px',
                    marginBottom: '55px',
                }}
            >
                <DatePickerView
                    tempfilterstate={tempfilterstate}
                    setTempFilterState={setTempFilterState}
                    width="100%"
                />
                <StyledDivider />
                <RadiusPickerView
                    filterstate={tempfilterstate}
                    setFilterState={setTempFilterState}
                    maxDistance={maxDistance}
                    width="100%"
                />
                <StyledDivider />
                <AttendeesPickerView
                    tempfilterstate={tempfilterstate}
                    setTempFilterState={setTempFilterState}
                    maxAttendees={maxAttendees}
                    width="100%"
                />
                <StyledDivider />
                <EventTypePickerView
                    tempfilterstate={tempfilterstate}
                    setTempFilterState={setTempFilterState}
                    width="100%"
                />
                <StyledDivider />
                <FollowersPickerView
                    tempfilterstate={tempfilterstate}
                    setTempFilterState={setTempFilterState}
                    width="100%"
                />
                <StyledDivider />
                <CategoriesPickerView
                    tempfilterstate={tempfilterstate}
                    setTempFilterState={setTempFilterState}
                    width="100%"
                />
            </Box>
            <BottomBar>
                <StyledDivider sx={{ marginTop: 0, marginBottom: 0 }} />
                <Toolbar style={{ height: 55, padding: '0px 20px' }}>
                    {(tempfilterstate.date.choice === FilterDateChoice.Custom &&
                        tempfilterstate.date.dates.length < 2) ||
                    tempfilterstate === filterstate ? (
                        <ApplyButtonDisabled
                            sx={{ marginBottom: '20px', marginTop: '10px' }}
                        >
                            Apply
                        </ApplyButtonDisabled>
                    ) : (
                        <ApplyButton
                            onClick={function () {
                                setFilterState(tempfilterstate)
                                window.setTimeout(() => {
                                    setFilterDrawerOpen(false)
                                }, 10)
                            }}
                            sx={{ marginBottom: '20px', marginTop: '10px' }}
                        >
                            Apply
                        </ApplyButton>
                    )}
                </Toolbar>
            </BottomBar>
        </Box>
    )
}

export default AllFiltersView
