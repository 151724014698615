import { Box, styled } from '@mui/material'
import { navMobileBarHeight } from '../../../layouts/DefaultLayout/NavMobileBar'
import { StyledIconButton } from '../../Components/StyledComponents'
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded'

const BottomBar = styled(Box)(({ theme }) => ({
    background: theme.palette.background.secondary,
    position: 'fixed',
    width: `100%`,
    backdropFilter: 'blur(10px)',
    zIndex: theme.zIndex.drawer + 1,
    borderRadius: '10px 10px 0 0',
    boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.8)',
}))

interface CreatePostBottomBarMobileViewProps {
    setPostImage: React.Dispatch<React.SetStateAction<string | null>>
}

const CreatePostBottomBarMobileView = ({
    setPostImage,
}: CreatePostBottomBarMobileViewProps) => {
    const triggerFileSelect = () => {
        document.getElementById('fileInput')?.click()
    }
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]
        if (file) {
            const reader = new FileReader()

            reader.onloadend = () => {
                setPostImage(reader.result as string)
            }

            reader.readAsDataURL(file)
        }
    }
    return (
        <BottomBar
            sx={{
                overflow: 'hidden',
                bottom: `${navMobileBarHeight}px`,
            }}
        >
            <Box
                sx={{
                    p: '8px 16px',
                    display: 'flex',
                }}
            >
                <StyledIconButton
                    sx={{
                        padding: '8px',
                        float: 'left',
                    }}
                    onClick={triggerFileSelect}
                >
                    <input
                        id="fileInput"
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <CameraAltRoundedIcon
                        sx={{ fontSize: 20, color: 'text.primary' }}
                    />
                </StyledIconButton>
            </Box>
        </BottomBar>
    )
}

export default CreatePostBottomBarMobileView
