import { Box } from '@mui/material'
import { PrimaryTypography, SecondaryTypography } from '../../StyledComponents'

interface EmptyHostedEventsViewProps {
    name: string
}
const EmptyHostedEventsView = ({ name }: EmptyHostedEventsViewProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                padding: '20px 20px',
            }}
        >
            <PrimaryTypography
                sx={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}
            >
                No events created or hosted
            </PrimaryTypography>
            <SecondaryTypography
                sx={{
                    fontSize: '14px',
                    mt: '8px',
                }}
            >
                {name} hasn't made or hosted any events. Check back later for
                updates
            </SecondaryTypography>
        </Box>
    )
}

export default EmptyHostedEventsView
