import { Box, Typography } from '@mui/material'

const SideLinksTextDivider = () => {
    return (
        <Box
            component={'span'}
            sx={{
                color: (theme) => theme.palette.text.tertiary,
                fontSize: 12,
            }}
        >
            {'  \u{2022}  '}
        </Box>
    )
}
const SideLinksView = () => {
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                padding: '16px 8px',
            }}
        >
            <Typography
                component={'div'}
                sx={{
                    whiteSpace: 'pre-wrap',
                    color: (theme) => theme.palette.text.tertiary,
                    fontSize: '16px',
                }}
            >
                <Box
                    component="span"
                    sx={{
                        color: (theme) => theme.palette.text.tertiary,
                        fontSize: 12,
                        cursor: 'pointer',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }}
                >
                    {'About'}
                </Box>
                <SideLinksTextDivider />
                <Box
                    component="span"
                    sx={{
                        color: (theme) => theme.palette.text.tertiary,
                        fontSize: 12,
                        cursor: 'pointer',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }}
                >
                    {'Terms of Service'}
                </Box>
                <SideLinksTextDivider />
                <Box
                    component="span"
                    sx={{
                        color: (theme) => theme.palette.text.tertiary,
                        fontSize: 12,
                        cursor: 'pointer',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }}
                >
                    {'FAQ'}
                </Box>
                <SideLinksTextDivider />
                <Box
                    component="span"
                    sx={{
                        color: (theme) => theme.palette.text.tertiary,
                        fontSize: 12,
                        cursor: 'pointer',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }}
                >
                    {'Updates'}
                </Box>
                <SideLinksTextDivider />
                <Box
                    component="span"
                    sx={{
                        color: (theme) => theme.palette.text.tertiary,
                        fontSize: 12,
                        cursor: 'pointer',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }}
                >
                    {'Community Guidelines'}
                </Box>
                <SideLinksTextDivider />
                <Box
                    component="span"
                    sx={{
                        color: (theme) => theme.palette.text.tertiary,
                        fontSize: 12,
                        cursor: 'pointer',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }}
                >
                    {'Privacy Policy'}
                </Box>
                <SideLinksTextDivider />
                <Box
                    component="span"
                    sx={{
                        color: (theme) => theme.palette.text.tertiary,
                        fontSize: 12,
                        cursor: 'pointer',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }}
                >
                    {'Premium'}
                </Box>
                <SideLinksTextDivider />
                <Box
                    component="span"
                    sx={{
                        color: (theme) => theme.palette.text.tertiary,
                        fontSize: 12,
                        cursor: 'pointer',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }}
                >
                    {'Contact'}
                </Box>
            </Typography>
        </Box>
    )
}

export default SideLinksView
