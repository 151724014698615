import { useEffect, useState } from 'react'
import { Post } from '../../models/Post'
import { grabPostPhoto } from '../../api/RestPost'
import { useAppState } from '../../AppStateProvider'
import { DataStatus } from '../../models/Dataloading'
import { Box } from '@mui/material'
import { EmptyImageBox } from '../Components/StyledComponents'
import { navigateToPost } from '../../utils/NavigationFunctions'
import { useNavigate } from 'react-router-dom'

interface PostImageViewProps {
    post: Post
}

const PostImageView = ({ post }: PostImageViewProps) => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const [postImage, setPostImage] = useState<string | null>(null)
    const [postImageStatus, setPostImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [hovering, setHovering] = useState<boolean>(false)

    useEffect(() => {
        try {
            grabPostPhoto(post.image, state.usertoken)
                .then((response: any) => {
                    setPostImage(response)
                    setPostImageStatus(DataStatus.success)
                })
                .catch((error) => {
                    console.log(error)
                    setPostImageStatus(DataStatus.failure)
                })
        } catch (error) {
            console.log(error)
            setPostImageStatus(DataStatus.failure)
        }
    }, [])
    return (
        <Box>
            {postImageStatus === DataStatus.loading ? (
                <EmptyImageBox
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                />
            ) : (
                postImageStatus === DataStatus.success &&
                postImage && (
                    <>
                        <img
                            src={postImage}
                            style={{
                                width: '100%',
                                height: '100%',
                                aspectRatio: '1/1',
                                objectFit: 'cover',
                                cursor: 'pointer',
                                position: 'relative',
                            }}
                            onClick={() =>
                                navigateToPost(
                                    post,
                                    postImage,
                                    null,
                                    navigate,
                                    state,
                                    setState
                                )
                            }
                            onBlur={() => setHovering(false)}
                            onMouseEnter={() => setHovering(true)}
                        />
                    </>
                )
            )}
        </Box>
    )
}

export default PostImageView
