import React, { useRef, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { styled } from '@mui/material/styles'
import {
    Box,
    Button,
    Container,
    Grid,
    Link,
    TextField,
    Typography,
    Divider,
    IconButton,
    CircularProgress,
} from '@mui/material'
import { login, LoginRequestData } from '../../api/Auth'
import AppleLogin from 'react-apple-login'
import { useAppState } from '../../AppStateProvider'
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { grabUserPhoto } from '../../api/RestUser'
import LoginMobileView from './LoginMobileView'

const DivRoot = styled('div')(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(10px)',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    position: 'fixed',
}))

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    padding: '20px 50px',
    borderRadius: 10,
}))

const StyledClearRoundedIcon = styled(ClearRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const PrimaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const CapsuleButton = styled(Button)<{ isValid: boolean }>(
    ({ theme, isValid }) => ({
        borderRadius: '50px',
        padding: '10px 20px',
        backgroundColor: theme.palette.text.selected,
        opacity: isValid ? 1 : 0.5,
        color: theme.palette.text.primary,
        textTransform: 'none',
        fontWeight: 'bold',
        '&:hover': {
            opacity: isValid ? 0.9 : 0.5,
            backgroundColor: theme.palette.text.selected,
        },
    })
)

const StyledLink = styled((props: any) => <Link {...props} />)(({ theme }) => ({
    color: theme.palette.text.selected,
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
    margin: '8px 0',
    '& label.Mui-focused': {
        color: theme.palette.text.secondary,
    },
    '& .MuiInputBase-input': {
        fontSize: 14,
    },
    '& .MuiInputLabel-root': {
        fontSize: 14,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.border.primary,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.text.secondary,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.text.selected,
        },
    },
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
    color: theme.palette.border.primary,
}))

const OtherAccountButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
        backgroundColor: 'white',
    },
    width: '100%',
    borderRadius: 50,
    textTransform: 'none',
    padding: 10,
}))

const StyledVisibility = styled(Visibility)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

const StyledVisibilityOff = styled(VisibilityOff)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

const PasswordIconButton = styled((props: any) => <IconButton {...props} />)(
    ({ theme }) => ({
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: theme.palette.background.tertiary,
        },
    })
)

const SecondaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.text.selected,
}))

export interface FormValues {
    email: string
    password: string
}

interface LoginViewProps {
    setShowLogin: React.Dispatch<React.SetStateAction<boolean>>
    setShowRegister: React.Dispatch<React.SetStateAction<boolean>>
}

const LoginView = ({ setShowLogin, setShowRegister }: LoginViewProps) => {
    const { state, setState } = useAppState()
    return (
        <>
            {state.isMobile ? (
                <LoginMobileView
                    setShowLogin={setShowLogin}
                    setShowRegister={setShowRegister}
                />
            ) : (
                <LoginDesktopView
                    setShowLogin={setShowLogin}
                    setShowRegister={setShowRegister}
                />
            )}
        </>
    )
}

const LoginDesktopView = ({
    setShowLogin,
    setShowRegister,
}: LoginViewProps) => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const [showPassword, setShowPassword] = useState(false)
    const viewRef = useRef<HTMLDivElement>(null)
    const [authenticating, setAuthenticating] = useState(false)
    const googlelogin = useGoogleLogin({
        onSuccess: (codeResponse) => console.log(codeResponse),
        flow: 'auth-code',
    })

    return (
        <DivRoot
            onMouseDown={function (event) {
                if (event.target === viewRef.current) {
                    setShowLogin(false)
                }
            }}
        >
            <Box
                ref={viewRef}
                display="flex"
                width="100%"
                height="100%"
                justifyContent="center"
                alignContent={'center'}
                alignItems={'center'}
            >
                <StyledContainer maxWidth="sm">
                    <div
                        style={{
                            visibility: authenticating ? 'hidden' : 'visible',
                            position: 'relative',
                        }}
                    >
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            initialErrors={
                                {
                                    email: 'Email is required',
                                    password: 'Password is required',
                                } as FormValues
                            }
                            validate={(values) => {
                                const errors: Partial<FormValues> = {}
                                if (!values.email) {
                                    errors.email = 'Email is required'
                                }
                                if (!values.password) {
                                    errors.password = 'Password is required'
                                }
                                return errors
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string()
                                    .email('Must be a valid email')
                                    .max(255),
                                password: Yup.string().min(6).max(255),
                            })}
                            onSubmit={(values) => {
                                setAuthenticating(true)
                                const loginRequestData: LoginRequestData = {
                                    user: {
                                        email: values.email,
                                        password: values.password,
                                    },
                                }
                                login(loginRequestData)
                                    .then((response) => {
                                        setState((prevState) => ({
                                            ...prevState,
                                            name: response.user.name,
                                            username:
                                                response.user.username.replace(
                                                    /\s+/g,
                                                    ''
                                                ),
                                            useremail: response.user.email,
                                            usertoken: response.user.token,
                                            userpassword: values.password,
                                            userimagelink: response.user.image,
                                        }))
                                        localStorage.setItem(
                                            'usertoken',
                                            response.user.token
                                        )
                                        localStorage.setItem(
                                            'username',
                                            response.user.username
                                        )
                                        localStorage.setItem(
                                            'useremail',
                                            response.user.email
                                        )
                                        localStorage.setItem(
                                            'userpassword',
                                            values.password
                                        )
                                        localStorage.setItem(
                                            'name',
                                            response.user.name
                                        )
                                        localStorage.setItem(
                                            'userimagelink',
                                            response.user.image
                                        )
                                        if (
                                            response.user.image &&
                                            !state.userimage
                                        ) {
                                            grabUserPhoto(
                                                response.user.image,
                                                response.user.token
                                            )
                                                .then((response) => {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        userimage: response,
                                                    }))
                                                    localStorage.setItem(
                                                        'userimage',
                                                        response
                                                    )
                                                })
                                                .catch((error) => {
                                                    console.error(error)
                                                })
                                        }
                                        navigate('/app/feed/fyp', {
                                            replace: true,
                                        })
                                        setAuthenticating(false)
                                    })
                                    .catch((error) => {
                                        console.error(error)
                                        setAuthenticating(false)
                                    })
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                isValid,
                                touched,
                                values,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        sx={{ mb: 3, justifyContent: 'center' }}
                                        width="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Box
                                            display="flex"
                                            position="relative"
                                            width="100%"
                                        >
                                            <Box sx={{ flexGrow: 1 }} />
                                            <img
                                                src={`${process.env.PUBLIC_URL}/logotest03long.png`}
                                                height={30}
                                            />
                                            <Box sx={{ flexGrow: 1 }} />
                                            <IconButton
                                                onClick={() =>
                                                    setShowLogin(false)
                                                }
                                                sx={{
                                                    width: 35,
                                                    height: 35,
                                                    fontSize: 10,
                                                    right: 0,
                                                    position: 'absolute',
                                                }}
                                            >
                                                <StyledClearRoundedIcon
                                                    sx={{
                                                        fontSize: 10,
                                                        height: 25,
                                                        width: 25,
                                                    }}
                                                />
                                            </IconButton>
                                        </Box>

                                        <Box
                                            sx={{
                                                width: '100%',
                                                paddingTop: '20px',
                                            }}
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <PrimaryTypography
                                                    sx={{
                                                        fontSize: 24,
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Sign in
                                                </PrimaryTypography>
                                                <Box sx={{ flexGrow: 1 }} />
                                            </Box>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <SecondaryTypography
                                                    sx={{ fontSize: 14 }}
                                                >
                                                    Log into your account
                                                </SecondaryTypography>
                                                <Box sx={{ flexGrow: 1 }} />
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Grid container spacing={0}>
                                        <Grid item xs={12} md={12}>
                                            <StyledTextField
                                                fullWidth
                                                size="small"
                                                label="Email Address"
                                                margin="normal"
                                                name="email"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="email"
                                                value={values.email}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <StyledTextField
                                                fullWidth
                                                size="small"
                                                label="Password"
                                                margin="normal"
                                                name="password"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type={
                                                    showPassword
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                value={values.password}
                                                variant="outlined"
                                                InputProps={{
                                                    endAdornment: (
                                                        <PasswordIconButton
                                                            onClick={() =>
                                                                setShowPassword(
                                                                    !showPassword
                                                                )
                                                            }
                                                            sx={{
                                                                position:
                                                                    'absolute',
                                                                right: 0,
                                                            }}
                                                        >
                                                            {showPassword ? (
                                                                <StyledVisibilityOff
                                                                    sx={{
                                                                        fontSize: 20,
                                                                    }}
                                                                />
                                                            ) : (
                                                                <StyledVisibility
                                                                    sx={{
                                                                        fontSize: 20,
                                                                    }}
                                                                />
                                                            )}
                                                        </PasswordIconButton>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Box sx={{ py: 2 }}>
                                        <CapsuleButton
                                            color="primary"
                                            isValid={isValid}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            sx={{ mt: 2 }}
                                        >
                                            Sign in now
                                        </CapsuleButton>
                                    </Box>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 5,
                                        }}
                                    >
                                        <StyledDivider sx={{ flex: 1 }} />
                                        <SecondaryTypography
                                            sx={{
                                                margin: '0 10px',
                                                fontSize: 14,
                                            }}
                                        >
                                            or
                                        </SecondaryTypography>
                                        <StyledDivider sx={{ flex: 1 }} />
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        alignContent="center"
                                    >
                                        <OtherAccountButton
                                            onClick={() => googlelogin()}
                                            sx={{
                                                marginTop: '15px',
                                                marginBottom: '5px',
                                                marginRight: '5px',
                                            }}
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Box sx={{ flexGrow: 1 }} />
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20px"
                                                    height="20px"
                                                    viewBox="0 0 48 48"
                                                >
                                                    <path
                                                        fill="#FFC107"
                                                        d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                                                    />
                                                    <path
                                                        fill="#FF3D00"
                                                        d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                                                    />
                                                    <path
                                                        fill="#4CAF50"
                                                        d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                                                    />
                                                    <path
                                                        fill="#1976D2"
                                                        d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                                                    />
                                                </svg>
                                                <Typography
                                                    sx={{
                                                        color: 'black',
                                                        fontWeight: 'bold',
                                                        marginLeft: '6px',
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    Sign in with Google
                                                </Typography>
                                                <Box sx={{ flexGrow: 1 }} />
                                            </Box>
                                        </OtherAccountButton>
                                        <AppleLogin
                                            clientId="com.react.apple.login"
                                            redirectURI="https://redirectUrl.com"
                                            render={(renderProps) => (
                                                <OtherAccountButton
                                                    onClick={
                                                        renderProps.onClick
                                                    }
                                                    sx={{
                                                        marginTop: '15px',
                                                        marginBottom: '5px',
                                                        marginLeft: '5px',
                                                    }}
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <Box
                                                            sx={{ flexGrow: 1 }}
                                                        />
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20px"
                                                            height="20px"
                                                            viewBox="0 0 256 315"
                                                        >
                                                            <path d="M213.803 167.03c.442 47.58 41.74 63.413 42.197 63.615c-.35 1.116-6.599 22.563-21.757 44.716c-13.104 19.153-26.705 38.235-48.13 38.63c-21.05.388-27.82-12.483-51.888-12.483c-24.061 0-31.582 12.088-51.51 12.871c-20.68.783-36.428-20.71-49.64-39.793c-27-39.033-47.633-110.3-19.928-158.406c13.763-23.89 38.36-39.017 65.056-39.405c20.307-.387 39.475 13.662 51.889 13.662c12.406 0 35.699-16.895 60.186-14.414c10.25.427 39.026 4.14 57.503 31.186c-1.49.923-34.335 20.044-33.978 59.822M174.24 50.199c10.98-13.29 18.369-31.79 16.353-50.199c-15.826.636-34.962 10.546-46.314 23.828c-10.173 11.763-19.082 30.589-16.678 48.633c17.64 1.365 35.66-8.964 46.64-22.262" />
                                                        </svg>
                                                        <Typography
                                                            sx={{
                                                                color: 'black',
                                                                fontWeight:
                                                                    'bold',
                                                                marginLeft:
                                                                    '6px',
                                                                fontSize: 14,
                                                            }}
                                                        >
                                                            Sign in with Apple
                                                        </Typography>
                                                        <Box
                                                            sx={{ flexGrow: 1 }}
                                                        />
                                                    </Box>
                                                </OtherAccountButton>
                                            )}
                                        />
                                    </Box>
                                    <Typography
                                        color="textSecondary"
                                        sx={{
                                            paddingTop: '10px',
                                            fontSize: 14,
                                        }}
                                    >
                                        Don't have an account?{' '}
                                        <StyledLink
                                            component={RouterLink}
                                            onClick={function () {
                                                setShowRegister(true)
                                                setShowLogin(false)
                                            }}
                                            fontSize={14}
                                        >
                                            Sign up
                                        </StyledLink>
                                    </Typography>
                                </form>
                            )}
                        </Formik>
                    </div>
                    {authenticating && (
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                display: 'flex',
                                margin: 'auto',
                                left: 0,
                                top: 0,
                            }}
                        >
                            <StyledCircularProgress />
                        </Box>
                    )}
                </StyledContainer>
            </Box>
        </DivRoot>
    )
}

export default LoginView
