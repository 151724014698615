import { Box } from '@mui/material'
import { PrimaryTypography, SecondaryTypography } from '../../StyledComponents'

export enum EmptyPostsViewType {
    account = 'account',
    event = 'event',
}

interface EmptyPostsViewProps {
    name: string
    type: EmptyPostsViewType
}
const EmptyPostsView = ({ name, type }: EmptyPostsViewProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                padding: '20px 20px',
            }}
        >
            <PrimaryTypography
                sx={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}
            >
                No posts made
            </PrimaryTypography>
            {type === EmptyPostsViewType.account ? (
                <SecondaryTypography
                    sx={{
                        fontSize: '14px',
                        mt: '8px',
                    }}
                >
                    {name} has not made any posts. Check back later for updates
                </SecondaryTypography>
            ) : (
                type === EmptyPostsViewType.event && (
                    <SecondaryTypography
                        sx={{
                            fontSize: '14px',
                            mt: '8px',
                        }}
                    >
                        No posts have been made about {name}. Check back later
                        for updates
                    </SecondaryTypography>
                )
            )}
        </Box>
    )
}

export default EmptyPostsView
