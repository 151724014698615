import axios from 'axios'
import { mainUrl } from '../constants'
import { Event } from '../models/Event'
import { ErrorCode } from '../models/ErrorCode'
import {
    convertBase64toBlob,
    convertBlobToBase64,
} from '../utils/Base64ImageEncoding'
import { CreateEvent } from '../models/CreateEvent'
import { Address, Location } from '../models/Location'

export interface EventReply {
    event: Event
}

export interface getEventsReply {
    events: Event[]
    event_count: number
}

export interface createEventRequestData {
    title: string
    description: string
    body: string
    tagList: string[]
    address: Address
    location: Location
    private: boolean
    event_time: string
    end_time: string
    attendee_limit: number
}

export interface createEventRequest {
    event: createEventRequestData
}

export const getEvents = async (
    host: string | null,
    attending: string | null,
    tags: string | null,
    radius: number,
    limit: number,
    offset: number,
    userToken: string
): Promise<getEventsReply> => {
    try {
        const params: Record<string, any> = {
            radius,
            limit,
            offset,
        }
        if (host) {
            params.host = host
        }
        if (attending) {
            params.attending = attending
        }
        if (tags) {
            params.tags = tags
        }
        const response = await axios.get<getEventsReply>(`${mainUrl}events`, {
            params: params,
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        })
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const grabEventPhoto = async (
    imagelink: string,
    usertoken: string
): Promise<string> => {
    const url = `${mainUrl}event/image?image=${imagelink}`

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${usertoken}`,
        },
    }

    try {
        const response = await fetch(url, requestOptions)
        if (!response.ok) {
            throw new Error('Failed to fetch data')
        }

        const blob = await response.blob()
        const image = await convertBlobToBase64(blob)

        return image
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const favoriteEvent = async (
    slug: string,
    userToken: string
): Promise<EventReply> => {
    try {
        const response = await axios.post<EventReply>(
            `${mainUrl}event/${slug}/favorite`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const unfavoriteEvent = async (
    slug: string,
    userToken: string
): Promise<EventReply> => {
    try {
        const response = await axios.delete<EventReply>(
            `${mainUrl}event/${slug}/favorite`,
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }
        )
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.message)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}

export const createEvent = async (
    tempEventData: CreateEvent,
    userToken: string
): Promise<EventReply> => {
    try {
        const requestData: createEventRequest = {
            event: {
                title: tempEventData.title,
                description: tempEventData.description,
                body: '',
                tagList: tempEventData.tag_list,
                address: tempEventData.address!,
                location: tempEventData.location!,
                private: tempEventData.private,
                event_time: tempEventData.event_time,
                end_time: tempEventData.end_time,
                attendee_limit: tempEventData.attendee_limit,
            },
        }
        const blob = convertBase64toBlob(tempEventData.image)
        const formData = new FormData()
        formData.append('image', blob, 'image.jpeg')

        const response = await axios.post<EventReply>(
            `${mainUrl}events`,
            requestData,
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    'Content-Type': 'application/json',
                },
            }
        )
        if (response.status === 200) {
            const url = `${mainUrl}event/${response.data.event.slug}/image`
            const response_photo = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
                body: formData,
            })
        }
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data)
        } else {
            throw new Error('An unexpected error occurred')
        }
    }
}
