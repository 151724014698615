import { Box, ClickAwayListener, Container, styled } from '@mui/material'
import {
    BackgroundFullPopperView,
    PrimaryDivider,
    PrimaryTypography,
    SecondaryDivider,
    SelectButton,
    StyledIconButton,
    TertiaryTypography,
} from '../../Components/StyledComponents'
import ReactDOM from 'react-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    NavigateCreateEventLocationState,
    NavigateCreateEventState,
} from '../../../models/NavigateState'
import { useEffect, useState } from 'react'
import { StyledCloseRoundedIcon } from '../../Components/StyledComponents/StyledIcons'
import LocationSearchBox from '../../Components/LocationSearchBox'
import { set } from 'date-fns'
import { LocationSearch } from '../../../models/Location'
import { Terminal } from '@mui/icons-material'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { googleMapsApiKey } from '../../../constants'
import {
    convertFormattedAddressToDictionary,
    getInfoFromCoords,
} from '../../../utils/GoogleMapsFunc'

const ContainerBox = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.primary,
    borderRadius: 20,
    padding: '20px 20px',
    minHeight: '60%',
}))

const CreateEventEditLocationView = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const initialTempEventData =
        location.state as NavigateCreateEventLocationState
    const [tempEventData, setTempEventData] = useState(
        initialTempEventData.tempEventData
    )
    const [locationSearchState, setLocationSearchState] =
        useState<LocationSearch | null>(
            initialTempEventData.tempEventData.locationsearch
        )
    const parentElement = document.getElementById('root')
    const handleSubmit = () => {
        if (locationSearchState) {
            const address = convertFormattedAddressToDictionary(
                locationSearchState.description
            )
            console.log(tempEventData, 'hey')
            navigate('/app/createevent', {
                state: {
                    tempEventData: {
                        ...tempEventData,
                        location: {
                            id: 0,
                            x: locationSearchState.location.lat,
                            y: locationSearchState.location.lng,
                        },
                        address: address,
                        locationsearch: locationSearchState,
                    },
                },
            })
        }
    }
    const handleDragEnd = (e: any) => {
        getInfoFromCoords(e.latLng.lat(), e.latLng.lng())
            .then((data) => {
                console.log(data)
                setLocationSearchState(data)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    return ReactDOM.createPortal(
        <BackgroundFullPopperView
            id="create-event-edit-location-view"
            sx={{
                top: '0',
            }}
        >
            <ClickAwayListener
                onClickAway={() =>
                    navigate('/app/createevent', {
                        state: { tempEventData: tempEventData },
                    })
                }
            >
                <ContainerBox maxWidth="sm" disableGutters>
                    <Box>
                        <Box
                            sx={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <StyledIconButton
                                onClick={() =>
                                    navigate('/app/createevent', {
                                        state: { tempEventData: tempEventData },
                                    })
                                }
                            >
                                <StyledCloseRoundedIcon
                                    sx={{
                                        fontSize: 20,
                                    }}
                                />
                            </StyledIconButton>

                            <SelectButton
                                onClick={handleSubmit}
                                disabled={
                                    locationSearchState ===
                                    tempEventData.locationsearch
                                }
                            >
                                Done
                            </SelectButton>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    display: 'flex',
                                    pointerEvents: 'none',
                                }}
                            >
                                <PrimaryTypography
                                    sx={{
                                        justifyContent: 'center',
                                        alignText: 'center',
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Edit Location
                                </PrimaryTypography>
                            </Box>
                        </Box>
                        <SecondaryDivider sx={{ mt: '8px' }} />
                    </Box>
                    <Box
                        sx={{
                            mt: '8px',
                        }}
                    >
                        <TertiaryTypography
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                mb: '8px',
                            }}
                        >
                            SEARCH FOR A LOCATION
                        </TertiaryTypography>
                        <LocationSearchBox
                            locationsearchstate={locationSearchState}
                            setLocationSearchState={setLocationSearchState}
                        />
                        <Box sx={{ mt: '8px' }}>
                            <LoadScript
                                googleMapsApiKey={
                                    'AIzaSyCTm7sXwQNKtccUyry0RZty7qQFiFyCIZw'
                                }
                            >
                                <GoogleMap
                                    mapContainerStyle={{
                                        height: 400,
                                        width: '100%',
                                    }}
                                    center={
                                        locationSearchState
                                            ? {
                                                  lat: locationSearchState
                                                      .location.lat,
                                                  lng: locationSearchState
                                                      .location.lng,
                                              }
                                            : {
                                                  lat: 43.64,
                                                  lng: -79.41,
                                              }
                                    }
                                    zoom={14}
                                    options={{
                                        disableDefaultUI: true,
                                        mapTypeControl: false,
                                        mapId: '7a9e2ebecd32a903',
                                        clickableIcons: false,
                                    }}
                                >
                                    {locationSearchState && (
                                        <Marker
                                            position={{
                                                lat: locationSearchState
                                                    .location.lat,
                                                lng: locationSearchState
                                                    .location.lng,
                                            }}
                                            draggable={true}
                                            onDragEnd={handleDragEnd}
                                        />
                                    )}
                                </GoogleMap>
                            </LoadScript>
                        </Box>
                    </Box>
                </ContainerBox>
            </ClickAwayListener>
        </BackgroundFullPopperView>,
        parentElement as Element
    )
}

export default CreateEventEditLocationView
