import { Box, Chip, Typography, styled } from '@mui/material'
import {
    StyledCircularProgress,
    TertiaryTypography,
} from '../../Components/StyledComponents'
import { Event } from '../../../models/Event'
import { useEffect, useState } from 'react'
import { useAppState } from '../../../AppStateProvider'
import { Post } from '../../../models/Post'
import { DataStatus } from '../../../models/Dataloading'
import { getPosts } from '../../../api/RestPost'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import PostMobileView from '../../PostView/PostMobileView/PostMobileView'
import PostSubEventMobileView from '../../PostView/PostMobileView/PostSubEventMobieView'
import { navigateToEvent } from '../../../utils/NavigationFunctions'
import { useNavigate } from 'react-router-dom'

interface EventDetailsPostsMobileViewProps {
    event: Event
    eventImage: string | null
}

const PostBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    borderRadius: '10px',
    border: '1px solid ' + theme.palette.border.primary,
    minWidth: 'calc(100% - 16px)',
}))
const EventDetailsPostsMobileView = ({
    event,
    eventImage,
}: EventDetailsPostsMobileViewProps) => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const [postState, setPostState] = useState<Post[]>([])
    const [postloaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    useEffect(() => {
        getPosts(null, null, event.slug, 3, 0, state.usertoken)
            .then((response) => {
                setPostState(response.posts)
                setPostLoaded(DataStatus.success)
            })
            .catch((error) => {
                setPostLoaded(DataStatus.failure)
                console.log(error)
            })
    }, [])
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'text.tertiary',
                    ml: '16px',
                }}
            >
                POSTS
            </Typography>
            <Box
                sx={{
                    minHeight: '100px',
                }}
            >
                {postloaded === DataStatus.loading ? (
                    <Box
                        sx={{
                            pt: '8px',
                            justifyContent: 'center',
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <StyledCircularProgress />
                    </Box>
                ) : postloaded === DataStatus.failure ? (
                    <ErrorTabView />
                ) : (
                    postloaded === DataStatus.success && (
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    overflow: 'auto',
                                    flexDirection: 'row',
                                    gap: '8px',
                                    mt: '16px',
                                    p: '0px 16px',
                                }}
                            >
                                {postState.length > 0 ? (
                                    <>
                                        {postState.map((post) => (
                                            <PostBox>
                                                <PostSubEventMobileView
                                                    post={post}
                                                />
                                            </PostBox>
                                        ))}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Box>
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    color: 'text.selected',
                                    ml: '16px',
                                    mt: '8px',
                                }}
                                onClick={() =>
                                    navigateToEvent(
                                        event,
                                        eventImage,
                                        navigate,
                                        state,
                                        setState,
                                        'posts'
                                    )
                                }
                            >
                                Show all
                            </Typography>
                        </Box>
                    )
                )}
            </Box>
        </Box>
    )
}

export default EventDetailsPostsMobileView
