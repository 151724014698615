import { Box, InputBase, Typography, styled } from '@mui/material'
import { CreateEvent } from '../../../models/CreateEvent'

const InputContainer = styled(Box)(({ theme }) => ({
    border: '1px solid ' + theme.palette.border.primary,
    borderRadius: '10px',
    padding: '8px 16px',
}))
interface CreateEventDescriptionViewProps {
    tempEventData: CreateEvent
    setTempEventData: React.Dispatch<React.SetStateAction<CreateEvent>>
}

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    borderRadius: '10px',
    padding: '0px',
}))
const CreateEventDescriptionView = ({
    tempEventData,
    setTempEventData,
}: CreateEventDescriptionViewProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTempEventData({
            ...tempEventData,
            description: e.target.value,
        })
    }
    return (
        <Box>
            <Typography
                sx={{
                    paddingTop: '4px',
                    fontSize: 12,
                    fontWeight: 'bold',
                    mt: '4px',
                    mb: '8px',
                    color: 'text.secondary',
                }}
            >
                Event Description
            </Typography>
            <InputContainer>
                <StyledInputBase
                    sx={{
                        fontSize: 14,
                    }}
                    value={tempEventData.description}
                    onChange={handleChange}
                    placeholder="Description"
                    fullWidth
                    multiline
                    minRows={4}
                />
            </InputContainer>
            <Box
                sx={{
                    mt: '4px',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 12,
                        textAlign: 'right',
                        color: 'text.secondary',
                    }}
                >
                    {`${tempEventData.description.length}/300`}
                </Typography>
            </Box>
        </Box>
    )
}

export default CreateEventDescriptionView
