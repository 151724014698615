import { useEffect, useState } from 'react'
import {
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    Typography,
} from '@mui/material'
import { useAppState } from '../../AppStateProvider'
import { styled } from '@mui/material/styles'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import { FollowButton, PrimaryTypography } from '../Components/StyledComponents'
import { Event } from '../../models/Event'
import { useNavigate } from 'react-router-dom'
import { navigatetoEditEvent } from '../../utils/NavigationFunctions'
import { CreateEvent } from '../../models/CreateEvent'

interface EventPostsSecondaryViewProps {
    event_slug: string
    event: Event
    eventImage: string
}

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.text.selected,
    textTransform: 'none',
    borderRadius: 50,
    '&:hover': {
        backgroundColor: theme.palette.text.selected,
    },
}))

const PostContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    borderRadius: '10px',
    border: '1px solid ' + theme.palette.border.primary,
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.tertiary,
    '&:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
}))

const TopBar = styled('div')(({ theme }) => ({
    borderBottom: '1px solid ' + theme.palette.border.primary,
    zIndex: 3,
}))

const EventPostsSecondaryView = ({
    event_slug,
    eventImage,
    event,
}: EventPostsSecondaryViewProps) => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const handleEditEvent = () => {
        const createEvent: CreateEvent = {
            title: event.title,
            image: eventImage,
            description: event.description,
            tag_list: event.tags,
            category_list: [],
            private: event.private,
            host: [],
            location: event.location,
            locationsearch: null,
            address: event.address,
            event_time: event.event_time,
            end_time: event.end_time,
            attendee_limit: event.attendee_limit,
        }
        navigatetoEditEvent(createEvent, event_slug, navigate, state, setState)
    }
    return (
        <Box sx={{ width: '100%', marginTop: '15px' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <StyledButton sx={{ width: '100%' }}>
                    Create a post
                </StyledButton>
                {event.is_host && (
                    <FollowButton
                        sx={{ width: '100%', ml: '8px' }}
                        onClick={handleEditEvent}
                    >
                        Edit Event
                    </FollowButton>
                )}
                <StyledIconButton sx={{ marginLeft: '8px' }}>
                    <MoreHorizRoundedIcon />
                </StyledIconButton>
            </Box>
            <PostContainer
                sx={{ marginTop: '15px', width: '100%', height: 400 }}
            >
                <TopBar
                    style={{
                        height: 40,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 16,
                            marginLeft: '20px',
                            color: 'text.primary',
                        }}
                    >
                        Event Posts Filter
                    </Typography>
                </TopBar>
            </PostContainer>
        </Box>
    )
}

export default EventPostsSecondaryView
