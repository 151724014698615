import { useLocation, useNavigate } from 'react-router-dom'
import { useAppState } from '../../../AppStateProvider'
import { NavigateCreatePostState } from '../../../models/NavigateState'
import { useCallback, useEffect, useState } from 'react'
import { Box, Button, Fade, Typography, styled } from '@mui/material'
import {
    DefaultButton,
    FollowButton,
    PrimaryDivider,
    PrimaryTypography,
    SecondaryDivider,
    SecondaryTypography,
    SelectButton,
    SelectedHoverButton,
    StyledIconButton,
    StyledTextField,
    UnfollowButton,
    UserPhotoCircle,
} from '../../Components/StyledComponents'
import { getUser, grabUserPhoto } from '../../../api/RestUser'
import ErrorAccountAvatarView from '../../Components/StaticPages/ErrorView/ErrorAccountAvatarView'
import CreatePostEventSelectorView from './CreatePostEventSelectorView'
import { navigateToCreatePostSelectEvent } from '../../../utils/NavigationFunctions'
import EventSubPostView from '../../EventView/EventSubPostView'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded'
import { createPost } from '../../../api/RestPost'
import CreatePostMobileView from '../../CreateView/CreatePostMobileView'

const StyledCameraAltRoundedIcon = styled(CameraAltRoundedIcon)(
    ({ theme }) => ({
        color: theme.palette.text.secondary,
    })
)
const StyledCloseRoundedIcon = styled(CloseRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const ContainedBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    borderRadius: '20px',
    padding: '20px',
    marginTop: '20px',
    border: '1px solid ' + theme.palette.border.primary,
}))

const ContainedEventBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    borderRadius: '10px',
    padding: '8px',
    border: '1px solid ' + theme.palette.border.primary,
}))

const CreatePostView = () => {
    const { state, setState } = useAppState()
    return (
        <>
            {state.isMobile ? (
                <CreatePostMobileView />
            ) : (
                <CreatePostDesktopView />
            )}
        </>
    )
}
const CreatePostDesktopView = () => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const location = useLocation()
    const initialCreatePostState = location.state as NavigateCreatePostState
    const [event, setEvent] = useState(initialCreatePostState.event)
    const [eventImage, setEventImage] = useState(
        initialCreatePostState.eventImage
    )
    const [eventHostImage, setEventHostImage] = useState(
        initialCreatePostState.eventHostImage
    )
    const [showSelectEvent, setShowSelectEvent] = useState(false)
    const [caption, setCaption] = useState(initialCreatePostState.caption)
    useEffect(() => {
        console.log(initialCreatePostState)
        setEvent(initialCreatePostState.event)
    }, [initialCreatePostState.event])
    const [postImage, setPostImage] = useState<string | null>(null)
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]
        if (file) {
            const reader = new FileReader()

            reader.onloadend = () => {
                setPostImage(reader.result as string)
            }

            reader.readAsDataURL(file)
        }
    }
    const triggerFileSelect = () => {
        document.getElementById('fileInput')?.click()
    }
    const handleCaptionChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setCaption(event.target.value)
    }
    const handleCreatePost = () => {
        createPost(caption, event?.slug, [], postImage!, state.usertoken)
            .then((response) => {
                console.log('Response:', response)
            })
            .catch((error) => {
                console.log('Error:', error)
            })
    }
    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                position: 'relative',
                justifyContent: 'center',
                float: 'left',
                marginTop: '56px',
                marginBottom: '16px',
            }}
        >
            {window.location.pathname.includes('selectevent') && (
                <CreatePostEventSelectorView />
            )}
            <ContainedBox
                sx={{
                    width: '100%',
                    maxWidth: 800,
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <PrimaryTypography
                        sx={{
                            fontSize: 24,
                            fontWeight: 'bold',
                        }}
                    >
                        Create Post
                    </PrimaryTypography>
                    <SelectButton
                        disabled={caption.length === 0 && !event && !postImage}
                        onClick={handleCreatePost}
                    >
                        Post
                    </SelectButton>
                </Box>
                <Box
                    sx={{
                        marginTop: '12px',
                        display: 'flex',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            alignItems: 'stretch',
                            flex: 1,
                        }}
                    >
                        {state.userimage ? (
                            <UserPhotoCircle
                                sx={{
                                    width: 60,
                                    height: 60,
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                }}
                                includeBorder={false}
                            >
                                <img
                                    src={state.userimage}
                                    alt="User"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            </UserPhotoCircle>
                        ) : (
                            <ErrorAccountAvatarView
                                size={60}
                                includeBorder={false}
                            />
                        )}
                        <Box
                            sx={{
                                marginTop: '8px',
                                height: '100%',
                                justifyContent: 'center',
                                display: 'flex',
                            }}
                        >
                            {event && (
                                <PrimaryDivider
                                    orientation="vertical"
                                    flexItem
                                    sx={{
                                        height: '200px',
                                        borderRightWidth: '2px',
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            marginLeft: '12px',
                            height: '100%',
                            width: '100%',
                            float: 'left',
                            alignItems: 'top',
                        }}
                    >
                        <Box display="flex" alignItems={'center'}>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    color: 'text.primary',
                                }}
                            >
                                {state.name}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    marginLeft: '4px',
                                    color: 'text.secondary',
                                }}
                            >
                                @{state.username}
                            </Typography>
                        </Box>
                        <Button
                            sx={{
                                marginTop: '4px',
                                fontSize: 14,
                                display: 'flex',
                                padding: '3px 4px',
                                textTransform: 'none',
                                fontWeight: 'bold',
                                borderRadius: '50px',
                                backgroundColor: 'background.tertiary',
                            }}
                            onClick={() =>
                                navigateToCreatePostSelectEvent(
                                    event,
                                    eventImage,
                                    eventHostImage,
                                    navigate,
                                    'recent'
                                )
                            }
                        >
                            {event ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <img
                                        src={eventImage!}
                                        alt="Event"
                                        style={{
                                            height: '20px',
                                            width: '20px',
                                            objectFit: 'cover',
                                            borderRadius: '50px',
                                            marginRight: '8px',
                                        }}
                                    /> */}
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            ml: '8px',
                                            color: 'text.primary',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {event.title}
                                    </Typography>
                                    <StyledIconButton
                                        sx={{
                                            ml: '8px',
                                            backgroundColor:
                                                'background.quaternary',
                                            '&:hover': {
                                                backgroundColor:
                                                    'background.quaternary',
                                            },
                                            height: '20px',
                                            width: '20px',
                                        }}
                                    >
                                        <CloseRoundedIcon
                                            sx={{
                                                fontSize: 14,
                                                color: 'text.primary',
                                            }}
                                        />
                                    </StyledIconButton>
                                </Box>
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        ml: '8px',
                                        mr: '8px',
                                        color: 'text.primary',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Select an event
                                </Typography>
                            )}
                        </Button>
                        {postImage && (
                            <img
                                src={postImage}
                                alt="Event"
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '10px',
                                    marginTop: '12px',
                                    aspectRatio: '20/9',
                                }}
                            />
                        )}
                        <StyledTextField
                            variant="outlined"
                            label="Body"
                            fullWidth
                            multiline
                            size="small"
                            minRows={4}
                            maxRows={20}
                            sx={{
                                marginTop: '12px',
                                marginBottom: '4px',
                            }}
                            value={caption}
                            onChange={handleCaptionChange}
                        />
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                            }}
                        >
                            <StyledIconButton
                                sx={{
                                    padding: '8px',
                                    float: 'left',
                                }}
                                onClick={triggerFileSelect}
                            >
                                <input
                                    id="fileInput"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                                <StyledCameraAltRoundedIcon
                                    sx={{ fontSize: 20 }}
                                />
                            </StyledIconButton>
                            <SecondaryTypography
                                sx={{
                                    fontSize: 12,
                                    textAlign: 'right',
                                    float: 'right',
                                    paddingRight: '8px',
                                }}
                            >
                                {`${0}/1000`}
                            </SecondaryTypography>
                        </Box>
                        <SelectedHoverButton
                            sx={{
                                fontSize: 14,
                                marginTop: '8px',
                            }}
                        >
                            Public: Everyone can see this post
                        </SelectedHoverButton>
                    </Box>
                </Box>
                {event && (
                    <ContainedEventBox
                        sx={{
                            marginTop: '16px',
                            position: 'relative',
                            maxWidth: '600px',
                        }}
                    >
                        {/* <StyledIconButton
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            zIndex: 3,
                            backgroundColor: 'background.tertiary',
                            '&:hover': {
                                backgroundColor: 'background.tertiary',
                            },
                        }}
                        >
                        <StyledCloseRoundedIcon sx={{
                            fontSize: 20,
                        }}/>
                            
                    </StyledIconButton> */}
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                maxWidth: '600px',
                                height: '100%',
                                zIndex: 2,
                                pointerEvents: 'none',
                            }}
                        ></Box>
                        <Box
                            sx={{
                                pointerEvents: 'none',
                            }}
                        >
                            <EventSubPostView event={event} />
                        </Box>
                    </ContainedEventBox>
                )}
            </ContainedBox>
        </Box>
    )
}

export default CreatePostView
