import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Event } from '../../models/Event'
import AccountDetailsSubView from '../AccountView/AccountDesciptionSubView'
import { useState } from 'react'
import AccountAttendeeSubView from '../AccountView/AccountAttendeeSubView'

interface EventAttendeesViewProps {
    event: Event
}

const EventAttendeesView = ({ event }: EventAttendeesViewProps) => {
    const [formatState, setFormatState] = useState<number>(0)
    return (
        <>
            {formatState === 0 ? (
                <Grid
                    container
                    spacing={1}
                    sx={{
                        paddingTop: '5px',
                        paddingBottom: '10px',
                        width: '100%',
                    }}
                >
                    {event.attendees.map((attendee) => (
                        <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                            <AccountAttendeeSubView user={attendee} />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Box>
                    {event.attendees.map((attendee) => (
                        <AccountDetailsSubView user={attendee} />
                    ))}
                </Box>
            )}
        </>
    )
}

export default EventAttendeesView
