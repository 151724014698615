import React, { useEffect, useState } from 'react'
import { getLinkPreview } from 'link-preview-js'
import { getIframely } from '../../api/Iframely'
import { Box, BoxProps, Typography, styled } from '@mui/material'
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from 'react-router-dom'
import { DataStatus } from '../../models/Dataloading'
import { set } from 'date-fns'

interface LinkPreviewProps {
    url: string
}

interface LinkPreviewData {
    url?: string
    site?: string
    title?: string
    description?: string
    images?: string[]
    mediaType?: string
    favicons?: string[]
}

interface ContainerBoxProps extends BoxProps {
    to: string
}
const ContainerBox = styled((props: ContainerBoxProps) => <Box {...props} />)(
    ({ theme }) => ({
        display: 'flex',
        padding: '16px',
        borderRadius: '10px',
        height: '100%',
        backgroundColor: theme.palette.background.secondary,
        border: `1px solid ${theme.palette.border.primary}`,
        textDecoration: 'none',
    })
)

const LinkPreview = ({ url }: LinkPreviewProps) => {
    const [previewData, setPreviewData] = useState<LinkPreviewData | null>(null)
    const [urlLoaded, setUrlLoaded] = useState<DataStatus>(DataStatus.none)
    useEffect(() => {
        if (url) {
            setUrlLoaded(DataStatus.loading)
            getIframely(url)
                .then((data) => {
                    console.log(data, 'iframelyData')
                    setUrlLoaded(DataStatus.success)
                    setPreviewData({
                        url: data.url,
                        site: data.meta?.site,
                        title: data.meta?.title,
                        description: data.meta?.description,
                        images: data.links?.thumbnail?.map(
                            (thumbnail) => thumbnail.href
                        ),
                        mediaType: data.links?.thumbnail?.[0].type,
                        favicons: data.links?.icon?.map((icon) => icon.href),
                    })
                })
                .catch((error) => {
                    setUrlLoaded(DataStatus.failure)
                    console.log(error)
                })
        }
    }, [url])

    if (!previewData) return <p>Loading preview...</p>

    return (
        <>
            {urlLoaded === DataStatus.success && (
                <ContainerBox component={RouterLink} to={url}>
                    <Box>
                        {previewData.images &&
                            previewData.images.length > 0 && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        overflow: 'hidden',
                                        borderRadius: '10px',
                                        maxHeight: '200px',
                                    }}
                                >
                                    <img
                                        src={previewData.images[0]}
                                        alt={previewData.title}
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </Box>
                            )}

                        <Typography
                            sx={{
                                mt: '4px',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                color: 'text.primary',
                            }}
                        >
                            {previewData.site}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: 'bold',
                                color: 'text.primary',
                            }}
                        >
                            {previewData.title}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'text.secondary',
                            }}
                        >
                            {previewData.description}
                        </Typography>
                    </Box>
                </ContainerBox>
            )}
        </>
    )
}

export default LinkPreview
