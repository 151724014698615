import { Box, Typography } from '@mui/material'
import { CreateEvent } from '../../../models/CreateEvent'
import { PrimaryDivider } from '../../Components/StyledComponents'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import AccessAlarmRoundedIcon from '@mui/icons-material/AccessAlarmRounded'
import { timeDifferenceBetweenTimes } from '../../../utils/TimeDifference'
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded'
import CreateEventPreviewLocationView from './CreateEventPreviewLocationView'
import CreateEventPreviewCategoryView from './CreateEventPreviewCategoryView'
import CreateEventPreviewTagsView from './CreateEventPreviewTagsView'
import Markdown from 'markdown-to-jsx'
import CreateEventPreviewDateView from './CreateEventPreviewDateView'
import { locationDifference } from '../../../utils/LocationDifference'
import { useEffect, useState } from 'react'

interface CreateEventPreviewDetailsViewProps {
    tempEvent: CreateEvent
}

const CreateEventPreviewDetailsView = ({
    tempEvent,
}: CreateEventPreviewDetailsViewProps) => {
    return (
        <Box sx={{ marginTop: '12px' }}>
            <CreateEventPreviewDetailsStatisticsView tempEvent={tempEvent} />
            <PrimaryDivider sx={{ margin: '16px 0px' }} />
            <CreateEventPreviewDetailsAboutView tempEvent={tempEvent} />
            <PrimaryDivider sx={{ margin: '16px 0px' }} />
            <CreateEventPreviewDateView tempEvent={tempEvent} />
            <PrimaryDivider sx={{ margin: '16px 0px' }} />
            <CreateEventPreviewLocationView tempEvent={tempEvent} />
            <PrimaryDivider sx={{ margin: '16px 0px' }} />
            <CreateEventPreviewCategoryView tempEvent={tempEvent} />
            <PrimaryDivider sx={{ margin: '16px 0px' }} />
            <CreateEventPreviewTagsView tempEvent={tempEvent} />
        </Box>
    )
}

const CreateEventPreviewDetailsAboutView = ({
    tempEvent,
}: CreateEventPreviewDetailsViewProps) => {
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'text.tertiary',
                }}
            >
                ABOUT
            </Typography>
            <Typography
                sx={{ fontSize: 14, marginTop: '8px', color: 'text.primary' }}
            >
                {tempEvent.description === '' ? (
                    'Add Description'
                ) : (
                    <Markdown
                        fontSize={14}
                        options={{
                            overrides: {
                                // Override headings to render as body text
                                h1: {
                                    component: Typography,
                                    props: {
                                        style: { fontSize: 14 },
                                        gutterBottom: true,
                                    },
                                },
                                h2: {
                                    component: Typography,
                                    props: {
                                        style: { fontSize: 14 },
                                        gutterBottom: true,
                                    },
                                },
                                h3: {
                                    component: Typography,
                                    props: {
                                        style: { fontSize: 14 },
                                        gutterBottom: true,
                                    },
                                },
                                h4: {
                                    component: Typography,
                                    props: {
                                        style: { fontSize: 14 },
                                        gutterBottom: true,
                                    },
                                },
                                h5: {
                                    component: Typography,
                                    props: {
                                        style: { fontSize: 14 },
                                        gutterBottom: true,
                                    },
                                },
                                h6: {
                                    component: Typography,
                                    props: {
                                        style: { fontSize: 14 },
                                        gutterBottom: true,
                                    },
                                },
                                // Render paragraphs as normal body text
                                p: {
                                    component: Typography,
                                    props: {
                                        style: { fontSize: 14 },
                                        gutterBottom: true,
                                    },
                                },
                                // Handle lists normally
                                ul: {
                                    component: Typography,
                                    props: {
                                        component: 'ul',
                                        style: { fontSize: 14 },
                                    },
                                },
                                ol: {
                                    component: Typography,
                                    props: {
                                        component: 'ol',
                                        style: { fontSize: 14 },
                                    },
                                },
                            },
                        }}
                    >
                        {tempEvent.description}
                    </Markdown>
                )}
            </Typography>
        </Box>
    )
}

const CreateEventPreviewDetailsStatisticsView = ({
    tempEvent,
}: CreateEventPreviewDetailsViewProps) => {
    const [locationDifferenceString, setLocationDifferenceString] = useState('')
    useEffect(() => {
        if (tempEvent.location) {
            locationDifference(tempEvent.location).then((response) =>
                setLocationDifferenceString(response)
            )
        }
    }, [tempEvent])
    return (
        <Box>
            {tempEvent.private ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <LockRoundedIcon
                        sx={{
                            fontSize: 18,
                            color: (theme) => theme.palette.text.primary,
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: 14,
                            marginLeft: '8px',
                            color: 'text.primary',
                        }}
                    >
                        Private
                    </Typography>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <LockOpenRoundedIcon
                        sx={{
                            fontSize: 18,
                            color: (theme) => theme.palette.text.primary,
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: 14,
                            marginLeft: '8px',
                            color: 'text.primary',
                        }}
                    >
                        Public
                    </Typography>
                </Box>
            )}

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '8px',
                }}
            >
                <ShoppingCartRoundedIcon
                    sx={{
                        fontSize: 18,
                        color: (theme) => theme.palette.text.primary,
                    }}
                />
                <Typography
                    sx={{
                        fontSize: 14,
                        marginLeft: '8px',
                        color: 'text.primary',
                    }}
                >
                    Free
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '8px',
                }}
            >
                <LocationOnRoundedIcon
                    sx={{
                        fontSize: 18,
                        color: (theme) => theme.palette.text.primary,
                    }}
                />
                <Typography
                    sx={{
                        fontSize: 14,
                        marginLeft: '8px',
                        color: 'text.primary',
                    }}
                >
                    {locationDifferenceString}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '8px',
                }}
            >
                <AccessAlarmRoundedIcon
                    sx={{
                        fontSize: 18,
                        color: (theme) => theme.palette.text.primary,
                    }}
                />
                <Typography
                    sx={{
                        fontSize: 14,
                        marginLeft: '8px',
                    }}
                >
                    {tempEvent.event_time && tempEvent.end_time
                        ? timeDifferenceBetweenTimes(
                              tempEvent.event_time,
                              tempEvent.end_time
                          )
                        : 'Add Time'}
                </Typography>
            </Box>
        </Box>
    )
}
export default CreateEventPreviewDetailsView
