import { useNavigate } from 'react-router-dom'
import { useAppState } from '../../AppStateProvider'
import { useState } from 'react'
import { RegisterRequestData } from '../../api/Auth'
import { Box, styled } from '@mui/material'
import CreateAccountTopBarMobileView from './CreateAccountTopBarMobileView copy'
import CreateAccountEmailMobileView from './CreateAccountMobileItems/CreateAccountEmailMobileView'
import CreateAccountNameMobileView from './CreateAccountMobileItems/CreateAccountNameMobileView'
import CreateAccountPasswordMobileView from './CreateAccountMobileItems/CreateAccountPasswordMobileView'
import { CreateAccountFormValues } from './CreateAccountItems/CreateAccountDetailsView'
import CreateAccountDateMobileView from './CreateAccountMobileItems/CreateAccountDateMobileView'
import CreateAccountPhotoMobileView from './CreateAccountMobileItems/CreateAccountPhotoMobileView'

const DivRoot = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.primary,
    height: '100vh',
    width: '100vw',
    display: 'flex',
    zIndex: theme.zIndex.drawer + 10000000000000,
}))

interface CreateAccountMobileViewProps {
    setShowLogin: React.Dispatch<React.SetStateAction<boolean>>
    setShowRegister: React.Dispatch<React.SetStateAction<boolean>>
}
const CreateAccountMobileView = ({
    setShowLogin,
    setShowRegister,
}: CreateAccountMobileViewProps) => {
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    const [registerpage, setRegisterPage] = useState<number>(0)
    const [authenticating, setAuthenticating] = useState(false)
    const [registerstate, setRegisterState] = useState<CreateAccountFormValues>(
        {
            email: '',
            password: '',
            username: '',
            name: '',
            month: '',
            day: '',
            year: '',
            terms: true,
        }
    )
    return (
        <DivRoot>
            <CreateAccountTopBarMobileView
                registerpage={registerpage}
                setRegisterPage={setRegisterPage}
                setShowCreateAccount={setShowRegister}
            />
            <Box
                sx={{
                    mt: '55px',
                    p: '16px',
                    width: '100%',
                }}
            >
                {registerpage === 0 ? (
                    <CreateAccountEmailMobileView
                        setShowLogin={setShowLogin}
                        setShowRegister={setShowRegister}
                        registerstate={registerstate}
                        setRegisterState={setRegisterState}
                        setRegisterPage={setRegisterPage}
                    />
                ) : registerpage === 1 ? (
                    <CreateAccountNameMobileView
                        setShowLogin={setShowLogin}
                        setShowRegister={setShowRegister}
                        registerstate={registerstate}
                        setRegisterState={setRegisterState}
                        setRegisterPage={setRegisterPage}
                    />
                ) : registerpage === 2 ? (
                    <CreateAccountPasswordMobileView
                        setShowLogin={setShowLogin}
                        setShowRegister={setShowRegister}
                        registerstate={registerstate}
                        setRegisterState={setRegisterState}
                        setRegisterPage={setRegisterPage}
                    />
                ) : registerpage === 3 ? (
                    <CreateAccountDateMobileView
                        setShowLogin={setShowLogin}
                        setShowRegister={setShowRegister}
                        registerstate={registerstate}
                        setRegisterState={setRegisterState}
                        setRegisterPage={setRegisterPage}
                    />
                ) : (
                    registerpage === 4 && (
                        <CreateAccountPhotoMobileView
                            setShowLogin={setShowLogin}
                            setShowRegister={setShowRegister}
                            registerstate={registerstate}
                            setRegisterState={setRegisterState}
                            setRegisterPage={setRegisterPage}
                        />
                    )
                )}
            </Box>
        </DivRoot>
    )
}

export default CreateAccountMobileView
