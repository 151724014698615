import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppState } from '../../AppStateProvider'
import {
    Avatar,
    Box,
    Button,
    ClickAwayListener,
    Container,
    Grow,
    IconButton,
    List,
    Tab,
    Tabs,
    styled,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { User } from '../../models/User'
import { DataStatus } from '../../models/Dataloading'
import {
    FollowButton,
    PrimaryDivider,
    PrimaryTypography,
    StyledCircularProgress,
    StyledIconButton,
    StyledTextField,
    TertiaryTypography,
} from '../Components/StyledComponents'
import { hexToRGBA } from '../../utils/haxToRGBA'
import { navigateToAccount } from '../../utils/NavigationFunctions'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { NavigateAccountEditState } from '../../models/NavigateState'
import exp from 'constants'
import ErrorAccountAvatarView from '../Components/StaticPages/ErrorView/ErrorAccountAvatarView'
import { Formik } from 'formik'
import { useDropzone } from 'react-dropzone'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import { uploadPhoto } from '../../api/RestPhoto'

const StyledPersonRoundedIcon = styled(PersonRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.disabled,
}))

const StyledCloseRoundedIcon = styled(CloseRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const ClearIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.quaternary,
    '&:hover': {
        backgroundColor: theme.palette.background.quaternary,
    },
}))

const RootView = styled(Box)(({ theme }) => ({
    height: '100%',
    width: '100%',
    position: 'fixed',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
    zIndex: theme.zIndex.drawer + 1,
    // backdropFilter: 'blur(10px)',
    background: hexToRGBA('black', 0.7),
}))

interface EditButtonProps extends React.ComponentProps<typeof Button> {
    isValid: boolean
}
const EditButton = styled((props: EditButtonProps) => <Button {...props} />)(
    ({ theme, isValid }) => ({
        borderRadius: '50px',
        padding: '3px 20px',
        fontSize: 14,
        backgroundColor: 'white',
        opacity: isValid ? 1 : 0.5,
        color: theme.palette.background.secondary,
        textTransform: 'none',
        fontWeight: 'bold',
        '&:hover': {
            opacity: isValid ? 0.9 : 0.5,
            backgroundColor: 'white',
        },
    })
)

const ContainerBox = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.primary,
    borderRadius: 20,
    padding: '20px 20px',
    minHeight: '70%',
}))
const ImageBox = styled(Box)(({ theme }) => ({
    width: 150,
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.background.tertiary,
    cursor: 'pointer',
}))

const EditAccountView = () => {
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    const location = useLocation()
    const [pageLoaded, setPageLoaded] = useState(false)
    const { username } = useParams<{ username: string }>()
    const { tabIndex } = useParams<{ tabIndex: string }>()
    const initialAccountEditState = location.state as NavigateAccountEditState
    const parentElement = document.getElementById('root')
    useEffect(() => {
        if (!pageLoaded) {
            setPageLoaded(true)
        }
    }, [])
    const [tempUserState, setTempUserState] = useState<User>(
        initialAccountEditState.user
    )
    const [image, setImage] = useState<string | null>(
        initialAccountEditState.userImage
    )
    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0]
        const reader = new FileReader()

        reader.onloadend = () => {
            setImage(reader.result as string)
        }

        if (file) {
            reader.readAsDataURL(file)
        }
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })

    const removeImage = () => {
        setImage(null)
    }

    const handleEdit = () => {
        if (image) {
            uploadPhoto(image, state.usertoken)
                .then((response) => {
                    setState((state) => ({
                        ...state,
                        userimagelink: response,
                        userimage: null,
                    }))
                    navigate(`/app/account/${username}/${tabIndex}`, {
                        state: {
                            user: initialAccountEditState.user,
                            userImage: image,
                            eventImage: initialAccountEditState.eventImage,
                        },
                    })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
    return ReactDOM.createPortal(
        <RootView id="account-ff-view">
            <ClickAwayListener
                onClickAway={() => {
                    navigate(`/app/account/${username}/${tabIndex}`, {
                        state: {
                            user: initialAccountEditState.user,
                            userImage: initialAccountEditState.userImage,
                            eventImage: initialAccountEditState.eventImage,
                        },
                    })
                }}
            >
                <Grow in={pageLoaded}>
                    <ContainerBox maxWidth="sm" disableGutters>
                        <Formik
                            initialValues={{
                                name: tempUserState.name,
                                username: tempUserState.username,
                                bio: tempUserState.bio,
                                instagram: tempUserState.instagram,
                                twitter: tempUserState.twitter,
                            }}
                            initialErrors={{
                                name: 'Name is required',
                                username: 'Username is required',
                            }}
                            onSubmit={(values) => {
                                console.log(values)
                            }}
                            validate={(values) => {
                                const errors: Partial<typeof values> = {}
                                if (
                                    !values.name ||
                                    values.name === state.name
                                ) {
                                    errors.name = 'Name is required'
                                }
                                if (
                                    !values.username ||
                                    values.username === state.username
                                ) {
                                    errors.username = 'Username is required'
                                }
                                return errors
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                isValid,
                                touched,
                                values,
                            }) => (
                                <Box
                                    sx={{
                                        position: 'relative',
                                        height: '100%',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            width: '100%',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                width: '100%',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <StyledIconButton
                                                sx={{
                                                    alignSelf: 'left',
                                                }}
                                                onClick={() => {
                                                    navigate(
                                                        `/app/account/${username}/${tabIndex}`,
                                                        {
                                                            state: {
                                                                user: initialAccountEditState.user,
                                                                userImage:
                                                                    initialAccountEditState.userImage,
                                                                eventImage:
                                                                    initialAccountEditState.eventImage,
                                                            },
                                                        }
                                                    )
                                                }}
                                            >
                                                <StyledCloseRoundedIcon />
                                            </StyledIconButton>
                                            <PrimaryTypography
                                                sx={{
                                                    fontSize: 20,
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                    alignSelf: 'center',
                                                }}
                                            >
                                                Edit Profile
                                            </PrimaryTypography>
                                            <EditButton
                                                isValid={
                                                    image !==
                                                    initialAccountEditState.userImage
                                                }
                                                sx={{
                                                    marginLeft: 'auto',
                                                    alignSelf: 'right',
                                                }}
                                                onClick={handleEdit}
                                            >
                                                Save
                                            </EditButton>
                                        </Box>
                                        <PrimaryDivider
                                            sx={{
                                                marginTop: '8px',
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                justifyContent: 'center',
                                                paddingTop: '70px',
                                            }}
                                        >
                                            <Box
                                                display="flex"
                                                sx={{
                                                    width: '100%',
                                                    justifyContent: 'center',
                                                    position: 'relative',
                                                }}
                                            >
                                                <ImageBox
                                                    sx={{
                                                        position: 'relative',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        display: 'flex',
                                                    }}
                                                    {...getRootProps()}
                                                >
                                                    <input
                                                        {...getInputProps()}
                                                    />
                                                    {image ? (
                                                        <Avatar
                                                            src={image}
                                                            alt="Circular Image"
                                                            sx={{
                                                                width: '100%',
                                                                height: '100%',
                                                            }}
                                                        />
                                                    ) : (
                                                        <StyledPersonRoundedIcon
                                                            sx={{
                                                                fontSize: 30,
                                                                width: '75%',
                                                                height: '75%',
                                                            }}
                                                        />
                                                    )}
                                                    <ClearIconButton
                                                        onClick={removeImage}
                                                        sx={{
                                                            position:
                                                                'absolute',
                                                            top: 0,
                                                            right: -10,
                                                        }}
                                                    >
                                                        <StyledCloseRoundedIcon />
                                                    </ClearIconButton>
                                                </ImageBox>
                                            </Box>
                                        </Box>
                                        <TertiaryTypography
                                            sx={{
                                                fontSize: 16,
                                                textAlign: 'center',
                                                alignSelf: 'center',
                                                padding: '20px',
                                            }}
                                        >
                                            Edit Profile Picture
                                        </TertiaryTypography>
                                        <StyledTextField
                                            fullWidth
                                            size="small"
                                            label="Name"
                                            margin="normal"
                                            name="name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="name"
                                            value={values.name}
                                            variant="outlined"
                                        />
                                        <StyledTextField
                                            fullWidth
                                            size="small"
                                            label="Username"
                                            margin="normal"
                                            name="username"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="username"
                                            value={values.username}
                                            variant="outlined"
                                        />
                                        <StyledTextField
                                            fullWidth
                                            size="small"
                                            label="Bio"
                                            margin="normal"
                                            name="bio"
                                            minRows={4}
                                            maxRows={6}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="bio"
                                            value={values.bio}
                                            variant="outlined"
                                        />
                                        <StyledTextField
                                            fullWidth
                                            size="small"
                                            label="Instagram"
                                            margin="normal"
                                            name="instagram"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="instagram"
                                            value={values.instagram}
                                            variant="outlined"
                                        />
                                        <StyledTextField
                                            fullWidth
                                            size="small"
                                            label="Twitter"
                                            margin="normal"
                                            name="twitter"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="twitter"
                                            value={values.twitter}
                                            variant="outlined"
                                        />
                                    </Box>
                                </Box>
                            )}
                        </Formik>
                    </ContainerBox>
                </Grow>
            </ClickAwayListener>
        </RootView>,
        parentElement as Element
    )
}

export default EditAccountView
