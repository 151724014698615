import { Box, Typography } from '@mui/material'
import { CreateEvent } from '../../../models/CreateEvent'
import { StyledTextField } from '../../Components/StyledComponents'
import { IOSSwitch } from '../../DiscoveryView/FilterItems/FollowersPickerView'

interface CreateEventSettingsProps {
    tempEvent: CreateEvent
    setTempEvent: React.Dispatch<React.SetStateAction<CreateEvent>>
}

const CreateEventSettingsView = ({
    tempEvent,
    setTempEvent,
}: CreateEventSettingsProps) => {
    const handleMaxAttendeesChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setTempEvent({
            ...tempEvent,
            attendee_limit: parseInt(event.target.value),
        })
    }

    const handlePublicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempEvent({
            ...tempEvent,
            private: event.target.checked,
        })
    }
    return (
        <Box>
            <Typography
                sx={{
                    paddingTop: '4px',
                    fontSize: 12,
                    fontWeight: 'bold',
                    mt: '8px',
                    color: 'text.tertiary',
                }}
            >
                MAX ATTENDEES
            </Typography>
            <Box
                sx={{
                    mt: '4px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 14,
                        color: 'text.primary',
                    }}
                >
                    Max Attendees
                </Typography>
                <StyledTextField
                    label="Max Attendees"
                    sx={{
                        ml: 'auto',
                    }}
                    size="small"
                    type="number"
                    value={tempEvent.attendee_limit}
                    onChange={handleMaxAttendeesChange}
                />
            </Box>
            <Typography
                sx={{
                    paddingTop: '4px',
                    fontSize: 12,
                    fontWeight: 'bold',
                    mt: '16px',
                    color: 'text.tertiary',
                }}
            >
                PUBLIC
            </Typography>
            <Box
                sx={{
                    mt: '8px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 14,
                        color: 'text.primary',
                    }}
                >
                    Public
                </Typography>
                <IOSSwitch
                    sx={{
                        marginLeft: 'auto',
                    }}
                    checked={tempEvent.private}
                    onChange={handlePublicChange}
                />
            </Box>
        </Box>
    )
}

export default CreateEventSettingsView
