import { useEffect, useState } from 'react'
import { useAppState } from '../../../AppStateProvider'
import { DataStatus } from '../../../models/Dataloading'
import { Post } from '../../../models/Post'
import { getPosts } from '../../../api/RestPost'
import { User } from '../../../models/User'
import { Box, Grid, List } from '@mui/material'
import {
    PrimaryDivider,
    StyledCircularProgress,
} from '../../Components/StyledComponents'
import PostView from '../../PostView/PostView'
import PostImageView from '../../PostView/PostImageView'
import ErrorTabView from '../../Components/StaticPages/ErrorView/ErrorTabView'
import EmptyPostsView, {
    EmptyPostsViewType,
} from '../../Components/StaticPages/EmptyView/EmptyPostsView'
import EmptyMediaPostsView from '../../Components/StaticPages/EmptyView/EmptyMediaPostsView'

interface AccountPostsViewProps {
    user: User
}

const AccountMediaMobileView = ({ user }: AccountPostsViewProps) => {
    const { state } = useAppState()
    const [postState, setPostState] = useState<Post[]>([])
    const [postLoaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)

    useEffect(() => {
        try {
            getPosts(user.username, null, null, 20, 0, state.usertoken)
                .then((response) => {
                    setPostState(response.posts)
                    setPostLoaded(DataStatus.success)
                })
                .catch((error) => {
                    console.log(error)
                    setPostLoaded(DataStatus.failure)
                })
        } catch (error) {
            console.log(error)
            setPostLoaded(DataStatus.failure)
        }
    }, [])
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {postLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : postLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : postLoaded === DataStatus.success && postState.length === 0 ? (
                <EmptyMediaPostsView
                    name={user.name}
                    type={EmptyPostsViewType.account}
                />
            ) : (
                <Box
                    sx={{
                        width: '100%',
                        padding: '8px 16x',
                    }}
                >
                    <Grid container spacing={0}>
                        {postState.map((post) => (
                            <Grid item xs={4} md={4} lg={4}>
                                <PostImageView post={post} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
        </Box>
    )
}

export default AccountMediaMobileView
