import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppState } from '../../../AppStateProvider'
import { useEffect, useRef, useState } from 'react'
import { NavigateAccountState } from '../../../models/NavigateState'
import { DataStatus } from '../../../models/Dataloading'
import { followUser, grabUserPhoto, unfollowUser } from '../../../api/RestUser'
import { getEvents, grabEventPhoto } from '../../../api/RestEvent'
import { Box, Typography } from '@mui/material'
import { User } from '../../../models/User'
import {
    EmptyImageBox,
    FollowButton,
    UnfollowButton,
    UserPhotoCircle,
} from '../../Components/StyledComponents'
import ErrorAccountAvatarView from '../../Components/StaticPages/ErrorView/ErrorAccountAvatarView'
import {
    navigateToAccountEdit,
    navigateToFollowers,
    navigateToFollowing,
} from '../../../utils/NavigationFunctions'
import {
    StyledTab,
    StyledTabs,
    TabBox,
} from '../../Components/StyledComponents/StyledTabView'
import AccountAttendingView from '../AccountAttendingView'
import AccountAttendingMobileView from './AccountAttendingMobileView'
import AccountPostsMobileView from './AccountPostsMobileView'
import AccountMediaMobileView from './AccountMediaMobileView'
import AccountHostingMobileView from './AccountHostingMobileView'
import zIndex from '@mui/material/styles/zIndex'
import AccountTopBarMobileView from './AccountTopBarMobileView'

const eventphotoheight = 80
const userphotosize = 70
const topbarheight = 56
const eventphotoheightdifference = eventphotoheight - topbarheight

const AccountMobileView = () => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const location = useLocation()
    const { username } = useParams<{ username: string }>()
    const { tabIndex } = useParams<{ tabIndex: string }>()
    const usernameRef = useRef(username)
    const initialAccountStateData = location.state as NavigateAccountState
    const [userState, setUserState] = useState<User>(
        initialAccountStateData.user
    )
    const [userImage, setUserImage] = useState<string | null>(
        initialAccountStateData.userImage
    )
    const [userImageStatus, setUserImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [eventImage, setEventImage] = useState<string | null>(
        initialAccountStateData.eventImage
    )
    const [eventImageStatus, setEventImageStatus] = useState<DataStatus>(
        DataStatus.loading
    )
    const [isUser, setIsUser] = useState(state.username === username)
    const [value, setValue] = useState(tabIndex)
    const [scrollPosition, setScrollPosition] = useState(0)
    const tabRef = useRef<HTMLDivElement>(null)
    const [tabYPosition, setTabYPosition] = useState(0)

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
        navigate(`/app/account/${username}/${newValue}`, {
            state: {
                user: userState,
                userImage: userImage,
                eventImage: eventImage,
            },
        })
    }
    useEffect(() => {
        setValue(tabIndex)
        setUserState(initialAccountStateData.user)
        setUserImage(initialAccountStateData.userImage)
        setEventImage(initialAccountStateData.eventImage)
    }, [window.location.pathname])
    const handleFollow = () => {
        setUserState({
            ...userState,
            follower_size: userState.follower_size + 1,
            following: true,
        })
        try {
            followUser(userState.username, state.usertoken)
                .then((response) => {
                    return
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleUnfollow = () => {
        setUserState({
            ...userState,
            follower_size: userState.follower_size - 1,
            following: false,
        })
        try {
            unfollowUser(userState.username, state.usertoken)
                .then((response) => {
                    return
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (usernameRef.current !== username) {
            window.scrollTo(0, 0)
        }
        usernameRef.current = username
        if (state.username === username) {
            setIsUser(true)
        } else {
            setIsUser(false)
        }
        if (userImage) {
            setUserImageStatus(DataStatus.success)
        } else {
            try {
                grabUserPhoto(userState.image, state.usertoken)
                    .then((response) => {
                        setUserImage(response)
                        setUserImageStatus(DataStatus.success)
                    })
                    .catch((error) => {
                        console.log(error)
                        setUserImageStatus(DataStatus.failure)
                    })
            } catch (error) {
                console.log(error)
                setUserImageStatus(DataStatus.failure)
            }
        }
        if (eventImage) {
            setEventImageStatus(DataStatus.success)
        } else {
            try {
                getEvents(
                    null,
                    userState.username,
                    null,
                    0,
                    1,
                    0,
                    state.usertoken
                )
                    .then((response) => {
                        grabEventPhoto(
                            response.events[0].image,
                            state.usertoken
                        )
                            .then((response) => {
                                setEventImage(response)
                                setEventImageStatus(DataStatus.success)
                            })
                            .catch((error) => {
                                console.log(error)
                                setEventImageStatus(DataStatus.failure)
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                        setEventImageStatus(DataStatus.failure)
                    })
            } catch (error) {
                console.log(error)
                setEventImageStatus(DataStatus.failure)
            }
        }
    }, [window.location.pathname])

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY)
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            if (tabRef.current) {
                const rect = tabRef.current.getBoundingClientRect()
                setTabYPosition(rect.top)
            }
        }

        window.addEventListener('scroll', handleScroll)
        handleScroll()
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <Box>
            <AccountTopBarMobileView
                user={userState}
                opacity={Math.min(
                    scrollPosition / eventphotoheightdifference,
                    1
                )}
            />
            <Box
                id="accountview"
                sx={{
                    position: 'relative',
                    width: '100%',
                    mb: '80px',
                }}
            >
                <Box
                    sx={{
                        position: 'sticky',
                        width: '100%',
                        height: eventphotoheight,

                        top: -eventphotoheightdifference,
                        zIndex:
                            scrollPosition < eventphotoheightdifference ? 0 : 2,
                        backgroundColor: 'background.primary',
                        overflow: 'hidden',
                    }}
                >
                    {eventImageStatus === DataStatus.success && eventImage ? (
                        <img
                            src={eventImage}
                            alt="event"
                            style={{
                                width: '100%',
                                height: eventphotoheight,
                                filter: `blur(${Math.min((scrollPosition / eventphotoheightdifference) * 10, 10)}px)`,

                                objectFit: 'cover',
                            }}
                        />
                    ) : (
                        <EmptyImageBox
                            sx={{
                                width: '100%',
                                height: eventphotoheight,
                            }}
                        />
                    )}
                </Box>
                <Box
                    sx={{
                        padding: '12px 20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        position: 'absolute',

                        top: eventphotoheight,
                        right: 0,
                    }}
                >
                    {isUser ? (
                        <FollowButton
                            onClick={() =>
                                navigateToAccountEdit(
                                    userState,
                                    userImage,
                                    eventImage,
                                    navigate,
                                    state,
                                    setState,
                                    value!
                                )
                            }
                        >
                            Edit Profile
                        </FollowButton>
                    ) : (
                        <>
                            {userState.following ? (
                                <UnfollowButton onClick={handleUnfollow}>
                                    Following
                                </UnfollowButton>
                            ) : (
                                <FollowButton onClick={handleFollow}>
                                    Follow
                                </FollowButton>
                            )}
                        </>
                    )}
                </Box>
                <Box
                    sx={{
                        pt: `${userphotosize / 2 + 8}px`,
                    }}
                >
                    <Box
                        sx={{
                            padding: '0px 20px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 22,
                                fontWeight: 'bold',
                                color: 'text.primary',
                            }}
                        >
                            {userState.name}
                        </Typography>
                        <Typography
                            sx={{ fontSize: 14, color: 'text.secondary' }}
                        >
                            @{userState.username}
                        </Typography>
                        <Typography
                            sx={{ fontSize: 16, color: 'text.primary' }}
                        >
                            {userState.bio}
                        </Typography>
                        <Box
                            justifyContent={'left'}
                            alignItems={'center'}
                            display={'flex'}
                            marginTop={'8px'}
                        >
                            <Box
                                sx={{
                                    justifyItems: 'center',
                                    paddingRight: '8px',
                                    '&:hover .underline': {
                                        textDecoration: (theme) =>
                                            `underline ${theme.palette.text.primary}`,
                                    },
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                    navigateToFollowers(
                                        userState,
                                        userImage,
                                        eventImage,
                                        null,
                                        navigate,
                                        state,
                                        setState,
                                        value!
                                    )
                                }
                            >
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        color: 'text.primary',
                                    }}
                                    className="underline"
                                >
                                    {userState.follower_size}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        textAlign: 'center',
                                        color: 'text.tertiary',
                                        whiteSpace: 'pre',
                                    }}
                                    className="underline"

                                    // marginLeft={'4px'}
                                >
                                    {' Followers'}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    justifyItems: 'center',
                                    padding: '0px 8px',
                                    '&:hover .underline': {
                                        textDecoration: (theme) =>
                                            `underline ${theme.palette.text.primary}`,
                                    },
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                    navigateToFollowing(
                                        userState,
                                        userImage,
                                        eventImage,
                                        null,
                                        navigate,
                                        state,
                                        setState,
                                        value!
                                    )
                                }
                            >
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        color: 'text.primary',
                                    }}
                                    className="underline"
                                >
                                    {userState.following_size}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        textAlign: 'center',
                                        color: 'text.tertiary',
                                        whiteSpace: 'pre',
                                    }}
                                    className="underline"
                                >
                                    {' Following'}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    justifyItems: 'center',
                                    padding: '0px 8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        color: 'text.primary',
                                    }}
                                >
                                    {0}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        textAlign: 'center',
                                        color: 'text.tertiary',
                                        whiteSpace: 'pre',
                                    }}
                                >
                                    {' Posts'}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    justifyItems: 'center',
                                    padding: '0px 8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        color: 'text.primary',
                                    }}
                                >
                                    {0}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        textAlign: 'center',
                                        color: 'text.tertiary',
                                        whiteSpace: 'pre',
                                    }}
                                >
                                    {' Events'}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <TabBox
                    marginTop={'8px'}
                    ref={tabRef}
                    sx={{
                        position: 'sticky',
                        top: topbarheight,
                        zIndex: 2,
                        backgroundColor: 'background.primary',
                    }}
                >
                    <StyledTabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        variant="fullWidth"
                    >
                        <StyledTab label="Attending" value="attending" />
                        <StyledTab label="Posts" value="posts" />
                        <StyledTab label="Media" value="media" />
                        <StyledTab label="Events" value="events" />
                    </StyledTabs>
                </TabBox>
                {value === 'attending' ? (
                    <AccountAttendingMobileView user={userState} />
                ) : value == 'posts' ? (
                    <AccountPostsMobileView user={userState} />
                ) : value == 'media' ? (
                    <AccountMediaMobileView user={userState} />
                ) : (
                    value == 'events' && (
                        <AccountHostingMobileView user={userState} />
                    )
                )}
                <Box
                    style={{
                        position: 'absolute',
                        top:
                            eventphotoheight -
                            userphotosize / 2 +
                            Math.max(
                                Math.min(
                                    (scrollPosition * userphotosize) /
                                        (2 * eventphotoheightdifference),
                                    userphotosize / 2
                                ),
                                0
                            ),
                        left: '20px',
                        zIndex: 1,
                    }}
                >
                    {userImageStatus === DataStatus.success && userImage ? (
                        <UserPhotoCircle
                            includeBorder={true}
                            sx={{
                                height:
                                    userphotosize -
                                    Math.max(
                                        Math.min(
                                            (scrollPosition * userphotosize) /
                                                (2 *
                                                    eventphotoheightdifference),
                                            userphotosize / 2
                                        ),
                                        0
                                    ),
                                width:
                                    userphotosize -
                                    Math.max(
                                        Math.min(
                                            (scrollPosition * userphotosize) /
                                                (2 *
                                                    eventphotoheightdifference),
                                            userphotosize / 2
                                        ),
                                        0
                                    ),
                                borderRadius: '50%',
                                overflow: 'hidden',
                            }}
                        >
                            <img
                                src={userImage}
                                alt="userimage"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        </UserPhotoCircle>
                    ) : userImageStatus === DataStatus.loading ? (
                        <UserPhotoCircle
                            includeBorder={true}
                            sx={{
                                height:
                                    userphotosize -
                                    Math.min(
                                        (scrollPosition * userphotosize) /
                                            (2 * eventphotoheightdifference),
                                        userphotosize / 2
                                    ),
                                width:
                                    userphotosize -
                                    Math.min(
                                        (scrollPosition * userphotosize) /
                                            (2 * eventphotoheightdifference),
                                        userphotosize / 2
                                    ),
                                borderRadius: '50%',
                                overflow: 'hidden',
                            }}
                        />
                    ) : (
                        (userImageStatus === DataStatus.failure ||
                            !userImage) && (
                            <ErrorAccountAvatarView
                                size={userphotosize}
                                includeBorder={true}
                            />
                        )
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default AccountMobileView
