import { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles'
import {
    Box,
    Grid,
    Popper,
    ClickAwayListener,
    Button,
    InputBase,
    Typography,
} from '@mui/material'
import { useAppState } from '../../AppStateProvider'
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded'
import { LocationSearch } from '../../models/Location'
import { getGoogleMapsAutocomplete } from '../../api/GoogleMapsAutocomplete'

const StyledPlaceRoundedIcon = styled(PlaceRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.tertiary,
}))

const Search = styled(Box)(({ theme }) => ({
    borderRadius: 50,
    backgroundColor: theme.palette.background.tertiary,
    padding: '0px 10px',
    width: 200,

    alignItems: 'center',
    display: 'flex',
    height: 35,
    fontFamily: 'Roboto',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: 14,
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: 5,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 600,
        },
    },
}))

const SearchItem = styled(Box)(({ theme }) => ({
    borderRadius: 50,
    backgroundColor: theme.palette.background.quaternary,
    color: theme.palette.text.primary,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '8px 10px',
    fontSize: 14,
    height: 10,
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto',
}))

const StyledPopper = styled(Popper)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    borderRadius: 10,
    boxShadow: '0px 0px 10px 0px ' + 'black',
    zIndex: 100000000,
}))

const PrimaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const SecondaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

const StyledButtonBase = styled(Button)(({ theme }) => ({
    backgroundColor: 'transparent',
    borderRadius: 10,
    width: '100%',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: theme.palette.background.tertiary,
    },
}))

interface LocationSearchProps {
    locationsearchstate: LocationSearch | null
    setLocationSearchState: React.Dispatch<
        React.SetStateAction<LocationSearch | null>
    >
}

const LocationSearchBox = ({
    locationsearchstate,
    setLocationSearchState,
}: LocationSearchProps) => {
    const [searchinglocation, setSearchingLocation] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const { state, setState } = useAppState()
    const [locationsearchresultstate, setLocationSearchResultState] = useState<
        LocationSearch[]
    >([])
    const [openpopover, setOpenPopover] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const handleSearchLocationOpen = () => {
        setSearchingLocation(true)
        window.setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus()
            }
        }, 100)
    }
    const handleSearchLocationClose = () => {
        if (searchinglocation) {
            setSearchingLocation(false)
        }
    }
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        getGoogleMapsAutocomplete(event.target.value).then((response) => {
            setLocationSearchResultState(response)
        })
    }
    const handlePopoverOpen = (event: any) => {
        setAnchorEl(event.currentTarget)
        setOpenPopover(true)
    }
    const handlePopperClose = (event: any) => {
        setAnchorEl(null)
        setOpenPopover(false)
    }

    return (
        <ClickAwayListener onClickAway={(event) => handleSearchLocationClose()}>
            <>
                <Search
                    sx={{ minWidth: 250 }}
                    onClick={function (event) {
                        handleSearchLocationOpen()
                        handlePopoverOpen(event)
                    }}
                >
                    <StyledPlaceRoundedIcon
                        sx={{ fontSize: 20, paddingRight: '0px' }}
                    />
                    {searchinglocation || !locationsearchstate ? (
                        <StyledInputBase
                            placeholder="Search Location"
                            inputRef={inputRef}
                            onChange={handleInputChange}
                        />
                    ) : (
                        <>
                            <SearchItem>
                                {locationsearchstate.maintext}
                            </SearchItem>
                        </>
                    )}
                </Search>

                <StyledPopper
                    sx={{
                        padding: '10px 15px',
                    }}
                    placement="bottom-start"
                    open={openpopover}
                    anchorEl={anchorEl}
                >
                    <Box sx={{ width: 500 }}>
                        <Grid container spacing={1}>
                            {locationsearchresultstate.length === 0 ? (
                                <Grid item xs={12}>
                                    <StyledButtonBase
                                        onClick={(event) => {
                                            if (navigator.geolocation) {
                                                navigator.geolocation.getCurrentPosition(
                                                    function (position: any) {
                                                        const latitude =
                                                            position.coords
                                                                .latitude
                                                        const longitude =
                                                            position.coords
                                                                .longitude
                                                        setLocationSearchState({
                                                            description:
                                                                'Current Location',
                                                            maintext:
                                                                'Current Location',
                                                            secondarytext: '',
                                                            place_id: 'default',
                                                            location: {
                                                                lat: latitude,
                                                                lng: longitude,
                                                            },
                                                        })
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                location: {
                                                                    latitude,
                                                                    longitude,
                                                                },
                                                            })
                                                        )
                                                    },
                                                    () => console.log('error')
                                                )
                                            }
                                            handlePopperClose(event)
                                            handleSearchLocationClose()
                                        }}
                                    >
                                        <Grid item xs={12}>
                                            <PrimaryTypography
                                                align="left"
                                                fontSize={14}
                                            >
                                                Current Location
                                            </PrimaryTypography>
                                        </Grid>
                                    </StyledButtonBase>
                                </Grid>
                            ) : (
                                <>
                                    {locationsearchresultstate.map(
                                        (locationsearchresult) => (
                                            <Grid item xs={12}>
                                                <StyledButtonBase
                                                    onClick={(event) => {
                                                        setLocationSearchState({
                                                            description:
                                                                locationsearchresult.description,
                                                            maintext:
                                                                locationsearchresult.maintext,
                                                            secondarytext:
                                                                locationsearchresult.secondarytext,
                                                            place_id:
                                                                locationsearchresult.place_id,
                                                            location:
                                                                locationsearchresult.location,
                                                        })
                                                        handlePopperClose(event)
                                                        handleSearchLocationClose()
                                                        const latitude =
                                                            locationsearchresult
                                                                .location.lat
                                                        const longitude =
                                                            locationsearchresult
                                                                .location.lng
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                location: {
                                                                    latitude,
                                                                    longitude,
                                                                },
                                                            })
                                                        )
                                                        setLocationSearchResultState(
                                                            []
                                                        )
                                                    }}
                                                >
                                                    <Grid item xs={12}>
                                                        <PrimaryTypography
                                                            align="left"
                                                            fontSize={14}
                                                        >
                                                            {
                                                                locationsearchresult.maintext
                                                            }
                                                        </PrimaryTypography>
                                                        <SecondaryTypography
                                                            align="left"
                                                            fontSize={12}
                                                        >
                                                            {
                                                                locationsearchresult.secondarytext
                                                            }
                                                        </SecondaryTypography>
                                                    </Grid>
                                                </StyledButtonBase>
                                            </Grid>
                                        )
                                    )}
                                </>
                            )}
                        </Grid>
                    </Box>
                </StyledPopper>
            </>
        </ClickAwayListener>
    )
}

export default LocationSearchBox
