import { useEffect, useState } from 'react'
import { useAppState } from '../../AppStateProvider'
import { DataStatus } from '../../models/Dataloading'
import { Post } from '../../models/Post'
import { getPosts } from '../../api/RestPost'
import { User } from '../../models/User'
import { Box, ButtonBase, Grid, List } from '@mui/material'
import {
    PrimaryDivider,
    SecondaryTypography,
    StyledChip,
    StyledCircularProgress,
    TertiaryTypography,
} from '../Components/StyledComponents'
import PostView from '../PostView/PostView'
import SortPopper from '../Components/SortPopper'
import { StyledArrowForwardIosRoundedIcon } from '../Components/StyledComponents/StyledIcons'
import ErrorTabView from '../Components/StaticPages/ErrorView/ErrorTabView'
import EmptyPostsView, {
    EmptyPostsViewType,
} from '../Components/StaticPages/EmptyView/EmptyPostsView'
import EmptyAttendingView from '../Components/StaticPages/EmptyView/EmptyAttendingView'

interface AccountPostsViewProps {
    user: User
}

const AccountPostsView = ({ user }: AccountPostsViewProps) => {
    const { state } = useAppState()
    const [postState, setPostState] = useState<Post[]>([])
    const [postLoaded, setPostLoaded] = useState<DataStatus>(DataStatus.loading)
    const [sortbyShowState, setSortbyShowState] = useState(false)
    const [sortbyState, setSortByState] = useState('Best')
    const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null)

    const handleSortPopperOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (!sortbyShowState) {
            setSortAnchorEl(event.currentTarget)
            setSortbyShowState(true)
        } else {
            setSortAnchorEl(null)
            setSortbyShowState(false)
        }
    }

    useEffect(() => {
        try {
            getPosts(user.username, null, null, 20, 0, state.usertoken)
                .then((response) => {
                    setPostState(response.posts)
                    setPostLoaded(DataStatus.success)
                })
                .catch((error) => {
                    console.log(error)
                    setPostLoaded(DataStatus.failure)
                })
        } catch (error) {
            console.log(error)
            setPostLoaded(DataStatus.failure)
        }
    }, [])
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {postLoaded === DataStatus.loading ? (
                <Box
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    <StyledCircularProgress />
                </Box>
            ) : postLoaded === DataStatus.failure ? (
                <ErrorTabView />
            ) : postLoaded === DataStatus.success && postState.length === 0 ? (
                <EmptyPostsView
                    name={user.name}
                    type={EmptyPostsViewType.account}
                />
            ) : (
                <Box
                    sx={{
                        width: '100%',
                        marginTop: '4px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            margin: '8px 0px',
                            alignItems: 'center',
                            ml: '10px',
                        }}
                    >
                        <TertiaryTypography fontSize={13} fontWeight={'bold'}>
                            Sort by:
                        </TertiaryTypography>
                        <SortPopper
                            sortOptions={['Best', 'New', 'Top']}
                            sortbyState={sortbyState}
                            setSortByState={setSortByState}
                            openpopover={sortbyShowState}
                            setOpenPopover={setSortbyShowState}
                            anchorEl={sortAnchorEl}
                            setAnchorEl={setSortAnchorEl}
                        />
                        <StyledChip
                            sx={{
                                ml: '4px',
                                cursor: 'pointer',
                                background: (theme) =>
                                    theme.palette.background.tertiary,
                            }}
                            component={ButtonBase}
                            onClick={handleSortPopperOpen}
                        >
                            <SecondaryTypography
                                fontSize={13}
                                fontWeight={'bold'}
                            >
                                {sortbyState}
                            </SecondaryTypography>
                            <StyledArrowForwardIosRoundedIcon
                                sx={{ fontSize: 13, ml: '4px' }}
                                open={sortbyShowState}
                            />
                        </StyledChip>
                    </Box>
                    <List sx={{ padding: 0 }}>
                        {postState.map((post, index) => (
                            <Box key={post.uuid} sx={{ padding: 0 }}>
                                <PostView post={post} />

                                <PrimaryDivider
                                    sx={{
                                        marginTop: '4px',
                                        marginBottom: '4px',
                                    }}
                                />
                            </Box>
                        ))}
                    </List>
                </Box>
            )}
        </Box>
    )
}

export default AccountPostsView
