import { Box, Typography } from '@mui/material'
import {
    StyledTab,
    StyledTabs,
    TabBox,
} from '../Components/StyledComponents/StyledTabView'
import { useAppState } from '../../AppStateProvider'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import ActiveExpiredTicketView from './ActiveExpiredTicketView'
import TicketsMobileView from './TicketMobileView.tsx'

const TicketsView = () => {
    const { state, setState } = useAppState()
    return (
        <>{state.isMobile ? <TicketsMobileView /> : <TicketsDesktopView />}</>
    )
}

const TicketsDesktopView = () => {
    const navigate = useNavigate()
    const { state, setState } = useAppState()
    const location = useLocation()
    const { tabIndex } = useParams<{ tabIndex: string }>()
    const [value, setValue] = useState(tabIndex)
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
        navigate(`/app/tickets/${newValue}`)
    }
    useEffect(() => {
        setValue(tabIndex)
    }, [window.location.pathname])
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    maxWidth: 1300,
                    marginRight: '0',
                    height: '100%',
                    marginTop: '70px',
                    width: '100%',
                    marginBottom: '50px',
                    zIndex: 0,
                    position: 'relative',
                    padding: '0px 20px',
                }}
            >
                <Box
                    sx={{
                        width: 'calc(100% - 420px)',
                        height: '100%',
                        float: 'left',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 22,
                            fontWeight: 'bold',
                            color: 'text.primary',
                        }}
                    >
                        Ticket View
                    </Typography>
                    <TabBox marginTop={'16px'}>
                        <StyledTabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <StyledTab label="Inbox" value="inbox" />
                            <StyledTab label="Active" value="active" />
                            <StyledTab label="Expired" value="expired" />
                            <StyledTab label="Sent" value="sent" />
                        </StyledTabs>
                    </TabBox>
                    {value === 'inbox' ? (
                        <Box></Box>
                    ) : value === 'active' ? (
                        <ActiveExpiredTicketView tabIndex={'active'} />
                    ) : value === 'expired' ? (
                        <ActiveExpiredTicketView tabIndex={'expired'} />
                    ) : (
                        value === 'sent' && <Box></Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default TicketsView
