import {
    AppBar,
    Box,
    IconButton,
    IconButtonProps,
    LinearProgress,
    styled,
    Toolbar,
    Typography,
} from '@mui/material'
import { useAppState } from '../../AppStateProvider'

const TopBar = styled(AppBar)(({ theme }) => ({
    paddingLeft: 0,
    background: theme.palette.background.primary,
    position: 'fixed',
    width: `calc(100%)`,
    borderBottom: '1px solid ' + theme.palette.border.primary,
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    height: 55,
    // boxShadow: 'none',
    // marginRight: 6,
}))

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 5,
    color: theme.palette.text.selected,
    backgroundColor: 'transparent',
    height: 2,
    '& .MuiLinearProgress-bar': {
        color: theme.palette.text.selected,
        backgroundColor: theme.palette.text.selected,
    },
}))

interface CreateTopBarMobileViewProps {
    showCreateMobileView: boolean
    setShowCreateMobileView: React.Dispatch<React.SetStateAction<boolean>>
}

const CreateTopBarMobileView = ({
    showCreateMobileView,
    setShowCreateMobileView,
}: CreateTopBarMobileViewProps) => {
    const { state, setState } = useAppState()
    return (
        <TopBar>
            {state.showprogress && (
                <StyledLinearProgress
                    variant="determinate"
                    value={state.progress}
                    sx={{
                        '& .MuiLinearProgress-bar': {
                            transition: state.progresstransition
                                ? 'transform 0.2s linear'
                                : 'none',
                        },
                    }}
                />
            )}
            <Toolbar
                style={{ minHeight: 55, paddingLeft: 16, paddingRight: 16 }}
            >
                <Box sx={{ flexGrow: 1 }} />
                <Typography
                    sx={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: 'text.primary',
                    }}
                >
                    Create
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
            </Toolbar>
            <Toolbar
                style={{
                    minHeight: 55,
                    paddingLeft: 16,
                    paddingRight: 16,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: 'text.secondary',
                    }}
                    onClick={() => {
                        setShowCreateMobileView(false)
                    }}
                >
                    Cancel
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
            </Toolbar>
        </TopBar>
    )
}

export default CreateTopBarMobileView
