import {
    Box,
    BoxProps,
    Button,
    InputBase,
    TextField,
    Typography,
    styled,
} from '@mui/material'
import { RegisterRequestData, register } from '../../../api/Auth'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { CreateAccountFormValues } from '../CreateAccountItems/CreateAccountDetailsView'
import {
    OutlineContainer,
    OutlineInputBase,
    SelectButton,
} from '../../Components/StyledComponents'
import { useState } from 'react'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'

interface CreateAccountNameMobileViewProps {
    setShowLogin: React.Dispatch<React.SetStateAction<boolean>>
    setShowRegister: React.Dispatch<React.SetStateAction<boolean>>
    registerstate: CreateAccountFormValues
    setRegisterState: React.Dispatch<
        React.SetStateAction<CreateAccountFormValues>
    >
    setRegisterPage: React.Dispatch<React.SetStateAction<number>>
}

const StyledUnderlineTextFields = styled(TextField)(({ theme }) => ({
    '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.text.selected,
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: 'transparent',
    },
}))
const CreateAccountNameMobileView = ({
    setShowLogin,
    setShowRegister,
    registerstate,
    setRegisterState,
    setRegisterPage,
}: CreateAccountNameMobileViewProps) => {
    const [focusStates, setFocusStates] = useState<boolean[]>(
        Array(2).fill(false)
    )
    const handleFocus = (index: number) => {
        const newFocusStates = [...focusStates]
        newFocusStates[index] = true
        setFocusStates(newFocusStates)
    }

    const handleBlur = (index: number) => {
        const newFocusStates = [...focusStates]
        newFocusStates[index] = false
        setFocusStates(newFocusStates)
    }
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    color: 'text.primary',
                }}
            >
                Add your username and display name
            </Typography>
            <Typography
                sx={{
                    fontSize: 14,
                    color: 'text.secondary',
                }}
            >
                Your username is unique to you and your display name is what
                others will see
            </Typography>
            <Formik
                initialValues={{
                    name: registerstate.name,
                    username: registerstate.username,
                }}
                initialErrors={
                    {
                        name: 'Name is required',
                        username: 'Username is required',
                    } as CreateAccountFormValues
                }
                validate={(values) => {
                    const errors: Partial<CreateAccountFormValues> = {}
                    if (!values.name) {
                        errors.name = 'Name is required'
                    }
                    if (!values.username) {
                        errors.username = 'Username is required'
                    }
                    return errors
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(30).required(),
                    username: Yup.string().max(30).required(),
                })}
                onSubmit={(values) => {
                    setRegisterState((prev) => ({
                        ...prev,
                        name: values.name,
                        username: values.username,
                    }))
                    setRegisterPage(2)
                }}
            >
                {({
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <OutlineContainer
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mt: '16px',
                            }}
                            focused={focusStates[0]}
                        >
                            <OutlineInputBase
                                name="name"
                                type="name"
                                fullWidth
                                placeholder="Enter your name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={() => handleBlur(0)}
                                onFocus={() => handleFocus(0)}
                            />
                            {values.name !== '' && (
                                <CancelRoundedIcon
                                    sx={{
                                        color: 'text.primary',
                                        fontSize: 20,
                                    }}
                                    onClick={() =>
                                        handleChange({
                                            target: { name: 'name', value: '' },
                                        })
                                    }
                                />
                            )}
                        </OutlineContainer>

                        <OutlineContainer
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mt: '16px',
                            }}
                            focused={focusStates[1]}
                        >
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    color: 'text.primary',
                                }}
                            >
                                @
                            </Typography>
                            <OutlineInputBase
                                name="username"
                                type="username"
                                fullWidth
                                placeholder="Enter your username"
                                value={values.username}
                                onChange={handleChange}
                                onBlur={() => handleBlur(1)}
                                onFocus={() => handleFocus(1)}
                            />
                            {values.username !== '' && (
                                <CancelRoundedIcon
                                    sx={{
                                        color: 'text.primary',
                                        fontSize: 20,
                                    }}
                                    onClick={() =>
                                        handleChange({
                                            target: {
                                                name: 'username',
                                                value: '',
                                            },
                                        })
                                    }
                                />
                            )}
                        </OutlineContainer>

                        <SelectButton
                            fullWidth
                            type="submit"
                            sx={{
                                mt: '32px',
                                height: '40px',
                                fontSize: 16,
                            }}
                            disabled={!isValid}
                        >
                            Continue
                        </SelectButton>
                        <Box
                            sx={{
                                justifyContent: 'center',
                                display: 'flex',
                                width: '100%',
                            }}
                        ></Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default CreateAccountNameMobileView
