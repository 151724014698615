import { Box, ButtonBase, Typography } from '@mui/material'
import { CreateEvent } from '../../../models/CreateEvent'
import { StyledChip } from '../../Components/StyledComponents'
import { useNavigate } from 'react-router-dom'
import AddRoundedIcon from '@mui/icons-material/AddRounded'

interface CreateEventHostsViewProps {
    tempEventData: CreateEvent
    setTempEventData: React.Dispatch<React.SetStateAction<CreateEvent>>
}

const CreateEventHostsView = ({
    tempEventData,
    setTempEventData,
}: CreateEventHostsViewProps) => {
    const navigate = useNavigate()
    return (
        <Box>
            <Typography
                sx={{
                    paddingTop: '4px',
                    fontSize: 12,
                    fontWeight: 'bold',
                    mt: '8px',
                    mb: '4px',
                    color: 'text.tertiary',
                }}
            >
                HOSTS
            </Typography>
            <StyledChip
                component={ButtonBase}
                sx={{
                    cursor: 'pointer',
                    mt: '8px',
                }}
                // onClick={()=>
                //     navigate('/app/createevent/tags',
                //         {state: { tempEventData: tempEventData }}
                //     )
                // }
            >
                <AddRoundedIcon
                    sx={{
                        fontSize: 16,
                        color: (theme) => theme.palette.text.primary,
                    }}
                />
                <Typography
                    sx={{
                        fontSize: 14,
                        ml: '4px',
                        fontWeight: 'bold',
                        color: 'text.primary',
                    }}
                >
                    Add Hosts
                </Typography>
            </StyledChip>
        </Box>
    )
}

export default CreateEventHostsView
