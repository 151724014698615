import {
    Box,
    ClickAwayListener,
    Container,
    Grid,
    Grow,
    Typography,
    styled,
} from '@mui/material'
import { hexToRGBA } from '../../utils/haxToRGBA'
import ReactDOM from 'react-dom'
import { useEffect, useState } from 'react'
import {
    PrimaryDivider,
    PrimaryTypography,
    StyledIconButton,
} from '../Components/StyledComponents'
import { useAppState } from '../../AppStateProvider'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { NavigateEventPhotosState } from '../../models/NavigateState'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

const StyledCloseRoundedIcon = styled(CloseRoundedIcon)(({ theme }) => ({
    color: theme.palette.text.primary,
}))

const RootView = styled(Box)(({ theme }) => ({
    height: '100%',
    width: '100%',
    position: 'fixed',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
    zIndex: theme.zIndex.drawer + 10,
    background: hexToRGBA('black', 0.7),
}))

const ContainerBox = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.primary,
    borderRadius: 20,
    padding: '20px 20px',
    height: '80%',
}))

interface EventPhotosViewProps {}
const EventPhotosView = () => {
    const { state, setState } = useAppState()
    const navigate = useNavigate()
    const { slug } = useParams<{ slug: string }>()
    const { tabIndex } = useParams<{ tabIndex: string }>()
    const location = useLocation()
    const initialEventPhotosState = location.state as NavigateEventPhotosState
    const [pageLoaded, setPageLoaded] = useState(false)
    const parentElement = document.getElementById('root')
    console.log(parentElement)
    useEffect(() => {
        if (!pageLoaded) {
            setPageLoaded(true)
        }
    }, [])
    return ReactDOM.createPortal(
        <RootView
            id="event-photos-view"
            sx={{
                top: '0',
            }}
        >
            <ClickAwayListener
                onClickAway={() => {
                    navigate(`/app/event/${slug}/${tabIndex}`, {
                        state: {
                            event: initialEventPhotosState.event,
                            eventImage: initialEventPhotosState.eventImage,
                        },
                    })
                }}
            >
                <Grow in={pageLoaded}>
                    <ContainerBox maxWidth="md" disableGutters>
                        <Box sx={{ position: 'relative' }}>
                            <StyledIconButton
                                sx={{
                                    float: 'right',
                                    position: 'absolute',
                                    right: '0px',
                                    top: '-4px',
                                }}
                                onClick={() => {
                                    navigate(`/app/event/${slug}/${tabIndex}`, {
                                        state: {
                                            event: initialEventPhotosState.event,
                                            eventImage:
                                                initialEventPhotosState.eventImage,
                                        },
                                    })
                                }}
                            >
                                <StyledCloseRoundedIcon sx={{ fontSize: 26 }} />
                            </StyledIconButton>
                            <Typography
                                sx={{
                                    textAlign: 'left',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    color: 'text.primary',
                                }}
                            >
                                {`${initialEventPhotosState.event.title} Photos`}
                            </Typography>
                            <PrimaryDivider sx={{ margin: '16px 0px' }} />
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    marginTop: '8px',
                                    overflowY: 'scroll',
                                }}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={4} lg={4}>
                                        {initialEventPhotosState.eventImage && (
                                            <img
                                                src={
                                                    initialEventPhotosState.eventImage
                                                }
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    aspectRatio: '1/1',
                                                    objectFit: 'cover',
                                                    cursor: 'pointer',
                                                    position: 'relative',
                                                    borderRadius: '10px',
                                                }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </ContainerBox>
                </Grow>
            </ClickAwayListener>
        </RootView>,
        parentElement as Element
    )
}

export default EventPhotosView
